export default function ArrowBottom({ className, size, color }) {
    return (
        <svg
            className={className}
            width={size? size : "20"}
            height={size? size : "20"}
            viewBox="0 0 20 20"
            color={color? color : "#9AA2B1"}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99994 12.5001L6.46411 8.96422L7.64328 7.78589L9.99994 10.1434L12.3566 7.78589L13.5358 8.96422L9.99994 12.5001Z" fill="currentColor" />
        </svg>

    );
}


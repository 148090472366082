export default function Notification({ className }) {
    return (
        <svg
            className={className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M16.6667 14.1667H18.3334V15.8334H1.66675V14.1667H3.33341V8.33341C3.33341 6.5653 4.03579 4.86961 5.28604 3.61937C6.53628 2.36913 8.23197 1.66675 10.0001 1.66675C11.7682 1.66675 13.4639 2.36913 14.7141 3.61937C15.9644 4.86961 16.6667 6.5653 16.6667 8.33341V14.1667ZM7.50008 17.5001H12.5001V19.1667H7.50008V17.5001Z" fill="#687083" />
        </svg>

    );
}

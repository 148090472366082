import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const InfoSignUp = () => {
    const isLogged = useSelector((state) => state.authReducer.isLogged);
    const { push } = useHistory();
    return (
        <section>
            <div className="w-100 py-4 gradient-primary" >
                <div className="container">
                    <div className="row align-items-center px-3">
                        <Col className="col-md-10 col-12">
                            <div className="text-light fw-bold fs-3 mb-2 text-footer" >Dapatkan Mobil Terbaik Hanya di Caready!</div>
                        </Col>
                        <Col className="col-md-2 col-12">
                            {
                                !isLogged ? <div className="px-4 py-2 text-center cursor-pointer rounded bg-light font-sm fw-semibold mb-2" onClick={() => push("/daftar")}>Daftar sekarang</div> : ''
                            }
                        </Col>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoSignUp;
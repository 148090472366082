import { createSlice } from '@reduxjs/toolkit'
// Slice
const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        nipl: [],
        nipl_invoice: [],
        unit:[],
        unit_invoice: [],
    },
    reducers: {
        setNIPL: (state, action) => {
            state.nipl = action.payload.nipl;
        },
        setNIPLPaymentMethod: (state, action) => {
            state.nipl.payment_method = action.payload.payment_method;
        },
        setNIPLInvoice: (state, action) => {
            state.nipl_invoice = action.payload.data;
        },
        setUnit: (state, action) => {
            state.unit = action.payload.data;
        }
    },
});

export const transactionNIPLSelector = state => state.transactionReducer.nipl;
export const invoiceNIPLSelector = state => state.transactionReducer.nipl_invoice;

export const transactionUnitSelector = state => state.transactionReducer.unit;

export const { actions: actionTransaction, reducer } = transactionSlice;
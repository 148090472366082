import { PrimaryButton } from "../../PrimaryButton/PrimaryButton";
import { SelectInput } from "../../SelectInput/SelectInput";
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionTransaction, transactionNIPLSelector } from "../../../redux/transaction.slice";
import { banks } from "../../../helpers/constans";

const PembayaranBeliNIPL = ({
    handleClick
}) => {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const transaction = useSelector(transactionNIPLSelector);
    const grand_total = parseInt(transaction.subtotal) + parseInt(transaction?.service_fee);

    return (
        <div className="font-sm">
            <div className="card p-2 mb-3 rounded-custom">
                <div className="fw-semibold font-sm mb-1">NIPL - {transaction?.unit_type_name || ""}</div>
                <div className="mb-1">{transaction?.location_name || ""} - {transaction?.auction_detail} WIB</div>
                <div className="d-flex justify-content-between">
                    <div>{transaction.quantity || 1} x {transaction?.price ? transaction.price.toLocaleString() : "-"}</div>
                    <div>Rp {transaction?.subtotal ? transaction.subtotal.toLocaleString() : "-"}</div>
                </div>
            </div>
            <div className="card p-2 mb-3 rounded-custom">
                <div className="d-flex justify-content-between mb-1">
                    <div>Subtotal</div>
                    <div>Rp {transaction?.subtotal ? transaction.subtotal.toLocaleString() : "-"}</div>
                </div>
                <div className="d-flex pb-2 border-bottom justify-content-between mb-1">
                    <div>Biaya Layanan</div>
                    <div>Rp {transaction?.service_fee ? parseInt(transaction.service_fee).toLocaleString() : "-"}</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>Total Bayar</div>
                    <div>Rp {grand_total ? grand_total.toLocaleString() : "-"}</div>
                </div>
            </div>
            <div className="fw-semibold pb-2">
                <div className="font-sm mb-1">Total Tagihan</div>
                <div className="fs-5">Rp {grand_total ? grand_total.toLocaleString() : "-"}</div>
            </div>
            <div className="py-2 mb-2">
                <div className="fw-semibold mb-2">Metode Pembayaran</div>
                <SelectInput
                    options={banks}
                    optionValue="id"
                    optionLabel="name"
                    onChange={(value) => { dispatch(actionTransaction.setNIPLPaymentMethod({ payment_method: value })); }}
                />
            </div>
            <div className="mb-3 font-xs text-secondary text-center">
                Dapatkan kode pembayaran setelah "Bayar"
            </div>
            <div className="d-flex">
                <PrimaryButton
                    className='w-100'
                    onClick={handleClick}
                >
                    Bayar
                </PrimaryButton>
            </div>
        </div>
    )
}

export default PembayaranBeliNIPL;
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import Facebook from '../../assets/icons/Facebook';
// import Google from '../../assets/icons/Google';
import InputField from '../../components/InputField/InpurField';
import { PasswordField } from '../../components/PasswordField/PasswordField';
import withoutAuth from '../../services/withoutAuthProvider';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Spinner } from "react-bootstrap";
import toast from 'react-hot-toast';
import decodeJwtResponse from "jwt-decode";
import {validatePassword} from "../../utils/string";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const SignUp = () => {
    const { push } = useHistory();
    const google = window.google;
    // const [loadingFacebook, setLoadingFacebook] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [statusValidation, setStatusValidation] = useState(null);

    // form validation rules
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .concat(Yup.string().required('Silahkan mengisi nama lengkap')),
        email: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .concat(Yup.string().required('Silahkan mengisi email')),
        password: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .concat(Yup.string().required('Silahkan mengisi kata sandi'))
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Kata Sandi harus memiliki minimal 8 karakter, terdapat huruf besar dan kecil serta mengandung angka')
            .min(8, 'Kata sandi harus minimal 8 karakter'),
        password_confirmation: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .when('password', (password, schema) => {
                if (password) return schema.required('Silahkan mengisi konfirmasi kata sandi');
            })
            .oneOf([Yup.ref('password')], 'Kata sandi harus sama'),
        is_agree: Yup.bool().oneOf([true], 'Wajib menyetujui syarat dan ketentuan'),
    });

    // functions to build form returned by useForm() hook
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: { is_agree: false },
    });

    const handleClick = (path) => {
        push(path)
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();

        const dataUser = new FormData();
        dataUser.append("name", data.name);
        dataUser.append("email", data.email);
        dataUser.append("password", data.password);
        dataUser.append("password_confirmation", data.password_confirmation);

        setLoadingRegister(true);
        const loading = toast.loading('Mengirimkan...');
        const response = await withoutAuth.postDataWithoutAuth('/api/register', dataUser)
        if (response?.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
            setLoadingRegister(false);
            reset({
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
            })
            push("/masuk")
        } else {
            toast.dismiss(loading);
            if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {

                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key]
                    }
                }

                toast.error(data_message, {
                    id: 'error-message',
                    duration: 3000
                });
            } else {
                toast.error(response.data.message, {
                    id: 'error-message',
                    duration: 3000
                });
            }

            setLoadingRegister(false);


        }

        e.target.reset();
    }

    // const responseFacebook = async (response) => {
    //     const dataUser = {
    //         id: response.id,
    //         email: response.email,
    //         full_name: response.name
    //
    //     }
    //
    //     setLoadingFacebook(true);
    //     const loading = toast.loading('Mengirimkan...');
    //     const responseAPI = await withoutAuth.postDataWithoutAuth('/api/register/social/facebook', dataUser)
    //     if (responseAPI.status === 200) {
    //         toast.dismiss(loading);
    //         toast.success(responseAPI.data.message, {
    //             id: 'success-message',
    //             duration: 3000
    //         });
    //         setLoadingFacebook(false);
    //         push("/masuk")
    //     } else {
    //         toast.dismiss(loading);
    //         toast.error(responseAPI.data.message, {
    //             id: 'error-message',
    //             duration: 3000
    //         });
    //         setLoadingFacebook(false);
    //     }
    // }

    const responseGoogle = async (response) => {
        const responsePayload = decodeJwtResponse(response.credential);

        const dataUser = {
            id: responsePayload.sub,
            email: responsePayload.email,
            full_name: responsePayload.name,
        }

        setLoadingGoogle(true);
        const loading = toast.loading('Mengirimkan...');
        const responseAPI = await withoutAuth.postDataWithoutAuth('/api/register/social/google', dataUser)
        if (responseAPI.status === 200) {
            toast.dismiss(loading);
            toast.success(responseAPI.data.message, {
                id: 'success-message',
                duration: 3000
            });
            setLoadingGoogle(false);
            push("/masuk")
        } else {
            toast.dismiss(loading);
            toast.error(responseAPI?.data?.message?.email, {
                id: 'error-message',
                duration: 3000
            });
            setLoadingGoogle(false);
        }
        google.accounts.id.disableAutoSelect();
    }

    useEffect(() => {
        google?.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: responseGoogle
        });
        google?.accounts.id.renderButton(
            document.getElementById("buttonGoogle"),
            { theme: "outline", size: "medium", text: "signup_with" }   // customization attributes
        );
    }, [])

    return (
        <div className='w-100'>
            <p className="fw-bold fs-4 mb-2">Daftar</p>
            <div className='font-sm d-flex mb-3'>
                <div className='me-2 text-secondary'>Sudah punya akun?</div>
                <div className='text-primary cursor-pointer' onClick={() => handleClick('/masuk')}>Masuk</div>
            </div>
            <div >

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3'>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field: { onChange, name, value } }) => (
                                <InputField
                                    id='fullName'
                                    placeholder='Masukan Nama Lengkap Anda'
                                    onChange={onChange}
                                    label='Nama Lengkap'
                                    type="text"
                                    name={name}
                                    value={value}
                                />
                            )}
                        />
                        {errors.name?.message && <span className='error-message'>{errors.name?.message}</span>}
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, name, value } }) => (
                                <InputField
                                    id='email'
                                    placeholder='Masukan Email Anda'
                                    onChange={onChange}
                                    label='Email'
                                    type="email"
                                    autoComplete='off'
                                    name={name}
                                    value={value}
                                />
                            )}
                        />
                        {errors.email?.message && <span className='error-message'>{errors.email?.message}</span>}
                        <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, name, value } }) => (
                                <PasswordField
                                    validation={statusValidation}
                                    id='password'
                                    placeholder='Masukan Kata Sandi'
                                    onChange={(val) => {
                                        setStatusValidation(validatePassword(val?.target?.value))
                                        onChange(val)
                                    }}
                                    label='Kata Sandi'
                                    name={name}
                                    value={value}
                                    extended
                                />
                            )}
                        />
                        {errors.password?.message && <span className='error-message'>{errors.password?.message}</span>}
                        <Controller
                            control={control}
                            name="password_confirmation"
                            render={({ field: { onChange, name, value } }) => (
                                <PasswordField
                                    id='password2'
                                    placeholder='Masukan Konfirmasi Kata Sandi'
                                    onChange={onChange}
                                    label='Konfirmasi Kata Sandi'
                                    name={name}
                                    value={value}
                                />
                            )}
                        />
                        {errors.password_confirmation?.message && <span className='error-message'>{errors.password_confirmation?.message}</span>}
                        <Controller
                            control={control}
                            name="is_agree"
                            render={({ field: { onChange, name, value } }) => (
                                <div className="d-flex mt-3 font-sm justify-content-between">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="disabledFieldsetCheck"
                                            name={name}
                                            value={value}
                                            onChange={onChange}
                                        />
                                        <label className='ms-2' htmlFor="disabledFieldsetCheck">
                                            Saya Menyetujui <a href="/syarat-dan-ketentuan" className='fw-bold text-primary'>Syarat & Ketentuan</a> dari Caready
                                        </label>
                                    </div>
                                </div>
                            )}
                        />
                        {errors.is_agree?.message && <span className='error-message'>{errors.is_agree?.message}</span>}
                    </div>
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary font-sm" type="submit" disabled={loadingRegister}>
                            {loadingRegister ? (<Spinner animation="border" size="sm" />) : ""}
                            Daftar
                        </button>
                    </div>
                </Form>
                <div className='text-center'>
                    <div className='font-sm text-secondary my-3 has-line'>atau daftar dengan</div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="d-grid gap-2">
                                <div id="buttonGoogle"></div>
                                {/* <button type="button" className="btn d-flex align-items-center justify-content-center btn-block btn-outline-secondary text-dark font-sm" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
                                    <Google className="me-2" />
                                    Google
                                </button> */}
                            </div>
                        </div>
                        {/* <div className='col-6'>
                            <div className="d-grid gap-2">
                                <FacebookLogin
                                    appId="1172350439997325"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    render={renderProps => (
                                        <button className="btn d-flex align-items-center justify-content-center btn-block btn-outline-secondary font-sm custom-facebook" style={{ background: '#FFF', fontWeight: 600, color: "#3c4043", height: "32px", border: "1px solid #dadce0" }} type="button" onClick={renderProps.onClick} disabled={loadingFacebook}>
                                            {loadingFacebook ? (<Spinner animation="border" size="sm" />) : ""}
                                            <Facebook className="me-2 logo-facebook" />
                                            Facebook
                                        </button>
                                    )}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>

            </div >
        </div >
    )
}

export default SignUp;

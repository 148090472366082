import React, { useState, useEffect, useCallback } from 'react'
import { Col, Container, Row, Form, FormControl, Button, Spinner } from 'react-bootstrap'
import IconYoutube from '../../assets/icons/IconYoutube';
import IconInstagram from '../../assets/icons/IconInstagram';
import IconFacebook from '../../assets/icons/IconFacebook';
import IconLinkedin from '../../assets/icons/IconLinkedin';
import Location from '../../assets/icons/contactIcon/Location';
import Mail from '../../assets/icons/contactIcon/Mail';
import Phone from '../../assets/icons/contactIcon/Phone';
import Whatsapp from '../../assets/icons/contactIcon/Whatsapp';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import withoutAuth from '../../services/withoutAuthProvider';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha
} from "react-google-recaptcha-v3"


export const socialMedias = [
    {
        image: <IconFacebook />,
        path: 'https://www.facebook.com/BalaiLelangCaready',
    },
    {
        image: <IconYoutube />,
        path: 'https://www.youtube.com/channel/UCxpoOwfOExeRrpGh9o_R4kg',
    },
    {
        image: <IconInstagram />,
        path: 'https://www.instagram.com/balailelangcaready/',
    },
    // {
    //     image: <IconLinkedin />,
    //     path: '/',
    // }
]

export default function HubungiKami() {
    const { push } = useHistory();
    const [loadingForm, setLoadingForm] = useState(false);
    const [token, setToken] = useState();

    const handleClick = (path) => {
        push(path)
    }

    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Silahkan mengisi nama lengkap"),
        email: Yup.string().required("Silahkan mengisi e-mail").email("E-mail tidak valid"),
        phone: Yup.string().required("Silahkan mengisi nomor telepon"),
        message: Yup.string().required("Silahkan mengisi pesan"),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onloadCallback = function () {

    };

    const onSubmit = async (data, e) => {
        e.preventDefault();

        const dataKontak = new FormData();

        dataKontak.append("name", data.name);
        dataKontak.append("email", data.email);
        dataKontak.append("phone", data.phone);
        dataKontak.append("context", data.context);
        dataKontak.append("message", data.message);
        dataKontak.append("g-recaptcha-response", token);


        setLoadingForm(true);
        const loading = toast.loading('Mengirimkan...');
        const response = await withoutAuth.postDataWithoutAuth('/api/contact', dataKontak)
        if (response?.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
            reset()
            setLoadingForm(false);
        } else {
            toast.dismiss(loading);
            if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {
                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key]
                    }
                }
                toast.error(data_message, {
                    id: 'error-message',
                    duration: 3000
                });
            } else {
                toast.error(response.data.message, {
                    id: 'error-message',
                    duration: 3000
                });
            }
            setLoadingForm(false);
        }
        e.target.reset();
    }


    return (
        <>
            <div className='page h-100' style={{ padding: "40px 0" }}>
                <section>
                    <Container>
                        <Row>
                            <Col md={{ span: 6 }}>
                                <div className="contact-us">
                                    <div className="title" style={{ marginBottom: "40px" }}>Hubungi Kami</div>
                                    <div className='section d-flex'>
                                        <div className='icon'><Mail /></div>
                                        <div>
                                            <span className="title">E-mail</span><br />
                                            <span>Kirim pesan anda kapan saja dan tim kami akan membantu</span><br />
                                            <span className="text-primary"><a href="mailto: notification@caready.co.id" target="_blank" className="no-underline"> notification@caready.co.id</a></span>
                                        </div>
                                    </div>
                                    <div className='section d-flex'>
                                        <div className='icon'><Phone /></div>
                                        <div>
                                            <span className="title">Telepon</span><br />
                                            <span>Senin - Sabtu, pukul 09.00 - 18.00 WIB</span><br />
                                            <span className="text-primary">021-8262-1611</span>
                                        </div>
                                    </div>
                                    <div className='section d-flex'>
                                        <div className='icon'><Whatsapp /></div>
                                        <div>
                                            <span className="title">Whatsapp</span><br />
                                            <span>Hubungi tim kami melalui whatsapp</span><br />
                                            <span className="text-primary"><a href="https://wa.me/6285891101162" target="_blank" className="no-underline">+62 858-9110-1162</a></span>
                                        </div>
                                    </div>
                                    <div className='section d-flex'>
                                        <div className='icon'><Location /></div>
                                        <div>
                                            <span className="title">Kantor Pusat</span><br />
                                            <span>Head Office Bekasi</span><br />
                                            <span>Jl. Raya Narogong No.37, RT.002/RW.007, <br />Bojong Menteng, Kec. Rawalumbu, Kota Bekasi, Jawa Barat <br /> 17117</span>
                                        </div>
                                    </div>
                                    <div className='social-media d-flex'>
                                        <span>Temukan kami di</span>
                                        <div className="d-flex mx-3">
                                            {
                                                socialMedias.map((socialmedia, idx) => (
                                                    <a href={socialmedia.path} target="_blank" className="me-3" key={idx}>
                                                        {socialmedia.image}
                                                    </a>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={{ span: 6 }}>
                                <div className='box-border d-none d-md-block'>

                                    <Form className='form-caready' onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="form-group" controlId="name">
                                            <Form.Label className="required">Nama Lengkap</Form.Label>
                                            <FormControl
                                                placeholder="Nama Lengkap"
                                                {...register('name')}
                                            />
                                            {errors.name?.message && <span className='error-message'>{errors.name?.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="form-group" controlId="email">
                                            <Form.Label className="required">E-mail</Form.Label>
                                            <FormControl
                                                placeholder="E-mail"
                                                {...register('email')}
                                            />
                                            {errors.email?.message && <span className='error-message'>{errors.email?.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="form-group" controlId="phone">
                                            <Form.Label className="required">Telepon</Form.Label>
                                            <FormControl
                                                placeholder="Telepon"
                                                {...register('phone')}
                                            />
                                            {errors.phone?.message && <span className='error-message'>{errors.phone?.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="form-group" controlId="context">
                                            <Form.Label className="required">Apa yang bisa kami bantu?</Form.Label>
                                            <Row>
                                                <Col md={{ span: 6 }}>
                                                    <Form.Check
                                                        type={"radio"}
                                                        id={"titiplelang"}
                                                        label={"Titip Lelang"}
                                                        value={"Titip Lelang"}
                                                        {...register('context')}
                                                    />
                                                    <Form.Check
                                                        type={"radio"}
                                                        id={"belinipl"}
                                                        label={"Beli NIPL"}
                                                        value={"Beli NIPL"}
                                                        {...register('context')}
                                                    />
                                                </Col>
                                                <Col md={{ span: 6 }}>
                                                    <Form.Check
                                                        type={"radio"}
                                                        id={"pembelianunitlelang"}
                                                        label={"Pembelian Unit Lelang"}
                                                        value={"Pembelian Unit Lelang"}
                                                        {...register('context')}
                                                    />
                                                    <Form.Check
                                                        type={"radio"}
                                                        id={"lainnya"}
                                                        label={"Lainnya"}
                                                        value={"Lainnya"}
                                                        {...register('context')}
                                                        checked={true}
                                                    />
                                                </Col>
                                            </Row>
                                            {errors.context?.message && <span className='error-message'>{errors.context?.message}</span>}
                                        </Form.Group>

                                        <Form.Group className="form-group" controlId="message">
                                            <Form.Label className="required">Pesan</Form.Label>
                                            <FormControl
                                                placeholder="Tulis Pesan"
                                                aria-label="Tulis Pesan"
                                                aria-describedby="basic-addon1"
                                                as="textarea"
                                                rows={3}
                                                {...register('message')}
                                            />
                                            {errors.message?.message && <span className='error-message'>{errors.message?.message}</span>}
                                        </Form.Group>

                                        <Button className="button-login mb-3" style={{ display: "block !important", width: "100%" }} variant="primary" type="submit" disabled={loadingForm}>
                                            {loadingForm ? <><Spinner animation="border" size="sm" />Kirim..</> : "Kirim"}

                                        </Button>

                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex" style={{ marginTop: '42px' }}>
                            <div style={{ marginRight: "auto" }}>
                                <div className="btn-pink cursor-pointer" onClick={() => handleClick('/lokasi-kami')}><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.52301 6.16667H13.6663V7.83334H3.52301L7.99301 12.3033L6.81467 13.4817L0.333008 7.00001L6.81467 0.518341L7.99301 1.69667L3.52301 6.16667Z" fill="#CF1701" />
                                </svg> Kantor Cabang</div>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <div className="btn-pink cursor-pointer" onClick={() => handleClick('/faq')}>FAQ <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4773 6.16667L6.00732 1.69667L7.18565 0.518341L13.6673 7.00001L7.18565 13.4817L6.00732 12.3033L10.4773 7.83334H0.333984V6.16667H10.4773Z" fill="#CF1701" />
                                </svg>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
}


import { format } from 'date-fns'
import React from 'react'

const PaymentHistory = ({ data }) => {
    function renderStatus(param) {
        switch (param) {
            case 'pending':
                return "<span class='badge-status warning'>Menunggu Pembayaran</span>";
            case 'PAID':
                return "<span class='badge-status success'>Lunas</span>";
            case 'deny':
                return "<span class='badge-status danger'>Wanprestasi</span>";
            case 'settlement':
                return "<span class='badge-status success'>Dibayar</span>";
            default:
                return '';
        }
    }
    return (
        <table className="table table-caready table-responsive">
            <thead>
                <tr>
                    <th>Pembayaran Ke</th>
                    <th>Jumlah</th>
                    <th>Tanggal Pembayaran</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    data?.payments.length > 0 ? data?.payments.map((el, idx) => (
                        <tr>
                            <td>{(idx + 1)}</td>
                            <td>{el?.gross_amount ? Number(el?.gross_amount).toLocaleString() : "-"}</td>
                            <td>{el?.created_at ? format(new Date(`${el.created_at}`), 'dd MMM yyyy, HH:mm') : "-"}</td>
                            <td dangerouslySetInnerHTML={{ __html: renderStatus(el?.transaction_status || "") }}></td>
                        </tr>
                    ))
                        : ""
                }
            </tbody>
        </table>
    )
}

export default PaymentHistory

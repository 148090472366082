import React, { useEffect, useState } from 'react';
import {
    Col, Container, Row, Card
} from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { Link } from 'react-router-dom';
import '../../assets/css/component.css';
import ArrowBottom from "../../assets/icons/ArrowBottom";
import withoutAuthProvider from '../../services/withoutAuthProvider';
import parse from 'html-react-parser';

const initialTabs = [
    {
        title: 'Cari Objek Lelang',
        status: true,
        path: 'cari-objek-lelang',
    },
    {
        title: 'Unit dan Dokumen Lelang',
        status: false,
        path: 'unit-dan-dokumen-lelang',
    },
    {
        title: 'Registrasi',
        status: false,
        path: 'registrasi',
    },
    {
        title: 'NIPL',
        status: false,
        path: 'nipl',
    },
]

const FAQ = () => {
    const [tabs, setTabs] = useState([...initialTabs]);
    const [faqs, setFaqs] = useState([]);
    const handleClickTab = (idx, path) => {
        setTabs(prev => prev.map((tab, i) => ({
            ...tab,
            status: i === idx,
        })))
        window.location.replace(`/faq/#${path}`);
    }
    const AccordionTitle = ({ title }) => {
        return (
            <div style={{ marginBottom: 5, borderBottom: '1px solid #DCDDE0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontWeight: 600 }}>{title}</div>
                <ArrowBottom className="la la-arrow-down" size="30" color="#09121F" />
            </div>
        );
    };

    const getFAQ = async () => {
        const response = await withoutAuthProvider.getDataWithoutAuth(
          "/api/faq"
        );
        if (response?.status === 200) {
            setFaqs(response?.data.data.data);
        }
      };
    
      useEffect(() => {
        getFAQ().finally(() => {});
      }, []);

    return (
        <div className="FAQ">
            <div style={{ marginBottom: '40px' }}>
                <Row>
                    <Col>
                        <div className="faq-title text-center">
                            <h2 className='w-50 mx-auto' style={{ color: 'white', lineHeight: '36px', fontWeight: 700 }}>Frequently Asked Question</h2>
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Card style={{ marginBottom: '40px' }}>
                        <div>
                            <div className='h-100 d-block d-md-flex justify-content-center' style={{ overflowX: 'auto' }}>
                                <div style={{ display: 'flex' }}>
                                    {
                                        tabs.map((tab, idx) => (
                                            <div
                                                className={`faq-tab ${tab.status && 'faq-tab-active'}`}
                                                key={idx}
                                                onClick={() => handleClickTab(idx, tab.path)}
                                            >
                                                {tab.title}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </Card>
                </Row> */}
            </div>
            <Container>
                {faqs?.map(faq => (
                    <Row key={`faq-${faq?.id?.toString()}`} style={{ marginBottom: '20px' }}>
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card style={{ padding: '20px' }} id="cari-objek-lelang">
                                <h3 style={{ fontWeight: 600, marginBottom: 20 }}>{faq?.title}</h3>
                                {faq?.faq?.map(itemFaq => (
                                    <Collapsible key={`faqItem-${itemFaq?.id?.toString()}`} trigger={<AccordionTitle title={itemFaq?.title} />} transitionTime={200}>
                                        <p>{itemFaq?.description? parse(itemFaq?.description) : null}</p>
                                    </Collapsible>
                                ))}
                            </Card>
                        </Col>
                    </Row>
                ))}
                {/* <Row style={{ marginBottom: '20px' }}>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Card style={{ padding: '20px' }} id="cari-objek-lelang">
                            <h3 style={{ fontWeight: 600, marginBottom: 20 }}>Cari Objek Lelang</h3>
                            <Collapsible trigger={<AccordionTitle title="Bagaimana Cara mendapatkan informasi unit Lelang terkini ?" />} transitionTime={200}>
                                <p>Untuk mendapatkan informasi daftar Lelang terkini secara rutin dengan mengecek website caready di <Link to="/">www.caready.co.id</Link> lihat tanggal yang mau dilelangkan atau bisa menanyakan langsung ke admin caready kami untuk mendapatkan katalog Lelang terupdate.</p>
                            </Collapsible>
                            <Collapsible trigger={<AccordionTitle title="Bagaimana jika unit yang kita cari tidak ada di website caready?" />} transitionTime={200}>
                                <p>Website kami selalu update secara berkala, terutama untuk jadwal Lelang terdekat. Apabila unit yang anda cari tidak tersedia, anda bisa mengecek jadwal Lelang terdekat secara berkala untuk penambahan dan perubahan unit yang di lelangkan.</p>
                            </Collapsible>
                            <Collapsible trigger={<AccordionTitle title="Jika sudah mendapatkan unit yang kita cari, apa Langkah selanjutnya?" />} transitionTime={200}>
                                <p>Jika anda sudah menemukan unit yang di cari, anda dapat melakukan registrasi di website kami <b>(jika belum mempunyai akun)</b> dan membeli NIPL <b>(Nomor Induk Peserta Lelang)</b>. Lalu mengikuti Lelang pada jadwal Lelang yang sudah tertera dan melakukan bidding atau penawaran menggunakan NIPL yang sudah dimiliki.</p>
                            </Collapsible>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Card style={{ padding: '20px' }} id="unit-dokumen-lelang">
                            <h3 style={{ fontWeight: 600, marginBottom: 20 }}>Unit Dokumen Lelang</h3>
                            <Collapsible trigger={<AccordionTitle title="Bagaimana kondisi unit yang dilelangkan?" />} transitionTime={200}>
                                <p>Anda dapat datang langsung ke lokasi untuk melakukan pengecekan unit secara detail 2 hari sebelum hari Lelang dimulai atau disebut dengan <b>open house</b>, jika belum sempat datang ke lokasi anda juga bisa melihat unit secara detail hasil laporan inpeksi terkini beserta foto-foto unit yang dilelangkan.</p>
                            </Collapsible>
                            <Collapsible trigger={<AccordionTitle title="Bagaimana dengan kelengkapan dokumen unit yang di lelangkan?" />} transitionTime={200}>
                                <p>Untuk mengetahui kelengkapan dokumen unit yang dilelangkan, anda bisa datang langsung ke lokasi untuk melihat Salinan data ke bagian informasi atau menanyakan ke admin caready.</p>
                            </Collapsible>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Card style={{ padding: '20px' }} id="registrasi">
                            <h3 style={{ fontWeight: 600, marginBottom: 20 }}>Registrasi</h3>
                            <Collapsible trigger={<AccordionTitle title="Bagaimana cara melakukan registrasi?" />} transitionTime={200}>
                                <p>Pertama anda harus memiliki alamat email aktif untuk membuat akun agar bisa mengikuti Lelang di Caready. Kedua anda wajib melengkapi beberapa data akun anda seperti Nama Lengkap, Alamat Rumah, KTP atau Passpor, Data Bank, dll. Setelah itu anda baru bisa melakukan pembelian NIPL untuk mengikuti Lelang di Caready.</p>
                            </Collapsible>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Card style={{ padding: '20px' }} id="nipl">
                            <h3 style={{ fontWeight: 600, marginBottom: 20 }}>NIPL</h3>
                            <Collapsible trigger={<AccordionTitle title="Apa itu NIPL?" />} transitionTime={200}>
                                <p>NIPL adalah Nomor Induk Peserta Lelang yang akan anda gunakan untuk melakukan bidding (penawaran) baik itu kendaraan mobil, motor dan barang yang dipilih.</p>
                            </Collapsible>
                            <Collapsible trigger={<AccordionTitle title="Berapa Harga NIPL?" />} transitionTime={200}>
                                <div>
                                    <p className='mb-0'>Daftar Harga NIPL berdasarkan unit yang di lelangkan :</p>
                                    <ul>
                                        <li>Rp. 5.000.000,- (Lima Juta Rupiah)/ NIPL Mobil</li>
                                        <li>Rp. 1.000.000,- (Satu  Juta Rupiah)/ NIPL Motor dan Barang</li>
                                    </ul>
                                </div>
                            </Collapsible>
                            <Collapsible trigger={<AccordionTitle title="Cara pembayaran NIPL?" />} transitionTime={200}>
                                <p>Peserta dapat melakukan pembayaran pembelian NIPL menggunakan nomor Virtual Account (VA) yang akan muncul setelah anda membeli NIPL. Peserta dapat memilih VA BANK PERMATA yang dapat digunakan semua bank dan VA BANK BCA hanya dapat digunakan untuk pengguna BCA. Batas Maksimal pembayaran NIPL adalah 1x24 jam setelah transaksi selesai. Setelah 1x 24 jam selesai nomor Virtual Account akan Hangus dan tidak dapat digunakan.</p>
                            </Collapsible>
                            <Collapsible trigger={<AccordionTitle title="Prosedur deposit jika menang atau kalah dalam Lelang??" />} transitionTime={200}>
                                <p>Jika anda memenangkan unit kendaraan atau unit Lelang lain, uang pembelian NIPL akan terakumulasi dalam pelunasan atas total tagihan pemenang. Jika anda belum berhasil mendapatkan unit kendaraan atau unit Lelang lain, uang pembelian NIPL bakal di kembalikan 100% tanpa potongan apapun.</p>
                            </Collapsible>
                        </Card>
                    </Col>
                </Row> */}
                <div style={{ display: 'flex', paddingTop: '20px', paddingBottom: '40px' }}>
                    <div style={{ marginRight: "auto" }}>
                        <a href="/hubungi-kami" className="btn-pink">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.52301 6.16667H13.6663V7.83334H3.52301L7.99301 12.3033L6.81467 13.4817L0.333008 7.00001L6.81467 0.518341L7.99301 1.69667L3.52301 6.16667Z" fill="#CF1701" />
                            </svg>
                            {"  Hubungi Kami"}
                        </a>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        <a href="/lokasi-kami" className="btn-pink">
                            {"Kantor cabang "}
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.4773 6.16667L6.00732 1.69667L7.18565 0.518341L13.6673 7.00001L7.18565 13.4817L6.00732 12.3033L10.4773 7.83334H0.333984V6.16667H10.4773Z" fill="#CF1701" />
                            </svg>
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default FAQ;
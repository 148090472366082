import { useRef } from 'react';
// import useOutsideClick from '../../utils/useOutsideClick';

export const CustomSelect = ({ isOpen, setIsOpen, tab, onClick }) => {
  const ref = useRef();

  // useOutsideClick(ref, () => isOpen && setIsOpen(false));

  const handleClick = (id, idx) => {
    onClick(id, idx);
    setIsOpen(false);
  };

  return (
    <div
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      className={`${
        isOpen ? 'd-block' : 'd-none'
      } w-100 bg-white position-absolute`}
      style={{ zIndex: '7' }}
    >
      <div className='card' style={{ maxHeight: '200px', overflowY: 'auto' }}>
        {tab.map(({ id, title, icon }, idx) => (
          <div
            className='px-2 py-1'
            key={`unit-ty${idx}`}
            onClick={() => handleClick(id, idx)}
          >
            <span className={`mdi ${icon} me-2`} />
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};

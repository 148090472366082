// import { Link } from "react-router-dom";
import { footerContent, footerImages } from "./footerContent";
import careadyFooter from '../../assets/images/caready-footer.png';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionsPage } from "../../redux/page.slice";

export default function Footers() {
    const { push } = useHistory()
    const address = 'Jl. Raya Narogong No.37, RT.002/RW.007, Bojong Menteng, Kec. Rawalumbu, Kota Bekasi, Jawa Barat 17117';
    const workHour = 'Senin - Sabtu : 09.00 - 18.00 WIB';
    const dispatch = useDispatch()

    const onCLickPath = (path) => {
        dispatch(actionsPage.setPage({ page: 0 }));
        push(path)
    }


    return (
        <footer className="text-light font-sm" style={{ backgroundColor: '#333333' }}>
            <div className="container">
                <div className="py-5 row">
                    <div className="col-md-4 mb-3">
                        <div className="pe-5 mb-3">
                            <img src={careadyFooter} alt="caready footer" className="mb-3" />
                            <p className="mb-2">{address}</p>
                            <p className="mb-2">{workHour}</p>
                        </div>
                        <div className="d-flex mx-3 mb-3">
                            {
                                footerImages.map((el, idx) => (
                                    <a href={el.path} target="_blank" className="me-3" key={idx}>
                                        <img src={el.image} alt="" />
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            {
                                footerContent.map((footer, idx) => (
                                    <div className="col-6 col-md-4 mb-3" key={idx}>
                                        <p className="fw-bold mb-2" style={{ color: '#D1D5DC', fontSize: '16px' }}>{footer.title}</p>
                                        {
                                            footer.child.map((el, indx) => (
                                                <div className="mb-0 cursor-pointer" key={indx + 1} onClick={() => onCLickPath(el.path)}>
                                                    <p className="mb-2">{el.label}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="border-top text-center py-3">
                    CAREADY © 2023 all right reserved
                </div>
            </div>
        </footer>
    )
}
import React from "react";
import parse from "html-react-parser";

export default function ProcedeBidding({ title, procedure }) {
  const BottomArrow = ({ label, children }) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9997 16.172L18.3637 10.808L19.7777 12.222L11.9997 20L4.22168 12.222L5.63568 10.808L10.9997 16.172V4H12.9997V16.172Z"
          fill="#8C8D8F"
        />
      </svg>
    );
  };

  return (
    <>
      <h3 className="title-procedure mb-3">{title}</h3>

      {procedure &&
        procedure.map(({ title, description, file_url }, idx) => (
          <div className="d-flex mb-3" style={{ gap: "20px" }}>
            <div className="justify-content-start justify-content-md-center align-items-center flex-direction-column">
              <div className="image-item-procedure mb-3 d-flex flex-center">
                <img src={file_url} alt="icon" />
              </div>
              <div className="mb-3 prosedur-arrow">
                {!(idx === procedure.length - 1) && <BottomArrow />}
              </div>
            </div>
            <div>
              <div className="title-item-procedure mb-2">{title}</div>
              <div className="desc-item-procedure">
                {description? parse(description) : null}
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

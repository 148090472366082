import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import backdrop from '../../assets/images/page-not-found.png'

function PageNotFound() {
    const { push } = useHistory();
    return (
        <section className="cover-background position-relative page-not-found" style={{ background: `url(${backdrop})` }}>
            <div className='overlay-notfound'></div>
            <Container className='center-vertical position-relative' style={{ height: "calc(100vh - 2rem)" }}>
                <div className='d-flex flex-column'>
                    <div className='mb-3'>
                        <svg width="72" height="68" viewBox="0 0 72 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M64 0.5H8C3.58 0.5 0 4.08 0 8.5V19.42C0 19.98 0.44 20.42 1 20.42H71C71.56 20.42 72 19.98 72 19.42V8.5C72 4.08 68.42 0.5 64 0.5ZM12 14.5C9.8 14.5 8 12.7 8 10.5C8 8.3 9.8 6.5 12 6.5C14.2 6.5 16 8.3 16 10.5C16 12.7 14.2 14.5 12 14.5ZM25 14.5C22.8 14.5 21 12.7 21 10.5C21 8.3 22.8 6.5 25 6.5C27.2 6.5 29 8.3 29 10.5C29 12.7 27.2 14.5 25 14.5Z" fill="white" />
                            <path d="M71 25.4199H1C0.44 25.4199 0 25.8599 0 26.4199V59.4999C0 63.9199 3.58 67.4999 8 67.4999H64C68.42 67.4999 72 63.9199 72 59.4999V26.4199C72 25.8599 71.56 25.4199 71 25.4199ZM26.1 54.5199C26.1 55.6199 25.2 56.5199 24.1 56.5199C23 56.5199 22.1 55.6199 22.1 54.5199V50.8399H14.44C13.34 50.8399 12.44 49.9399 12.44 48.8399V38.4599C12.44 37.3599 13.34 36.4599 14.44 36.4599C15.54 36.4599 16.44 37.3599 16.44 38.4599V46.8399H22.1V38.4599C22.1 37.3599 23 36.4599 24.1 36.4599C25.2 36.4599 26.1 37.3599 26.1 38.4599V54.5199ZM42.7 50.4799C42.7 53.7799 40 56.4799 36.7 56.4799H35.68C32.38 56.4799 29.68 53.7799 29.68 50.4799V42.4199C29.68 39.1199 32.38 36.4199 35.68 36.4199H36.7C40 36.4199 42.7 39.1199 42.7 42.4199V50.4799ZM59.54 54.5199C59.54 55.6199 58.64 56.5199 57.54 56.5199C56.44 56.5199 55.54 55.6199 55.54 54.5199V50.8399H47.88C46.78 50.8399 45.88 49.9399 45.88 48.8399V38.4599C45.88 37.3599 46.78 36.4599 47.88 36.4599C48.98 36.4599 49.88 37.3599 49.88 38.4599V46.8399H55.54V38.4599C55.54 37.3599 56.44 36.4599 57.54 36.4599C58.64 36.4599 59.54 37.3599 59.54 38.4599V54.5199ZM36.7 40.4199H35.68C34.58 40.4199 33.68 41.3199 33.68 42.4199V50.4799C33.68 51.5799 34.58 52.4799 35.68 52.4799H36.7C37.8 52.4799 38.7 51.5799 38.7 50.4799V42.4199C38.7 41.3199 37.8 40.4199 36.7 40.4199Z" fill="white" />
                        </svg>
                    </div>
                    <div className='title mb-2'>Maaf, halaman yang Anda cari tidak ditemukan</div>
                    <div className='description mb-3'>Halaman ini mungkin tidak ada atau sudah dihapus!<br />
                        Kami sarankan Anda untuk mencoba opsi di bawah ini :</div>
                    <div className='group'>
                        <Button className="btn-pink mb-3" variant="primary" type="button" onClick={() => push("/jadwal-lelang-hari-ini")}>
                            Lihat Jadwal Lelang Hari Ini
                        </Button>
                        <Button className="btn-primary-caready mb-3" variant="primary" type="button" onClick={() => push("/")}>
                            Kembali ke Beranda
                        </Button>
                    </div>
                </div>
            </Container>
        </section >

    )
}

export default PageNotFound
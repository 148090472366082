export default function LockOff({ active }) {
  return (
    <svg
      width='12'
      height='14'
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.66667 5.66705H11.3333C11.5101 5.66705 11.6797 5.73729 11.8047 5.86232C11.9298 5.98734 12 6.15691 12 6.33372V13.0004C12 13.1772 11.9298 13.3468 11.8047 13.4718C11.6797 13.5968 11.5101 13.6671 11.3333 13.6671H0.666667C0.489856 13.6671 0.320286 13.5968 0.195262 13.4718C0.0702379 13.3468 0 13.1772 0 13.0004V6.33372C0 6.15691 0.0702379 5.98734 0.195262 5.86232C0.320286 5.73729 0.489856 5.66705 0.666667 5.66705H1.33333V5.00039C1.33295 3.94885 1.68772 2.92801 2.34013 2.10334C2.99253 1.27867 3.90434 0.698506 4.92773 0.456894C5.95113 0.215282 7.02613 0.326385 7.97848 0.772193C8.93084 1.218 9.70471 1.97238 10.1747 2.91305L8.982 3.50905C8.64633 2.83706 8.09353 2.29815 7.41322 1.97968C6.73291 1.66122 5.96497 1.58187 5.23392 1.7545C4.50287 1.92714 3.85155 2.34163 3.38557 2.93079C2.91959 3.51995 2.66627 4.24923 2.66667 5.00039V5.66705ZM4.66667 9.00039V10.3337H7.33333V9.00039H4.66667Z'
        fill={active === 'off' ? '#8C8D8F' : '#2AB95E'}
      />
    </svg>
  );
}

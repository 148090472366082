const snk4 = [
    `Mencabut izin penggunaan Data Pribadi;`,
    `Meminta perubahan dan/atau pengkinian atas Data Pribadi;`,
    `Menanyakan hal-hal sehubungan penggunaan Data Pribadi-nya dengan menghubungi Pengelola di: info@caready.co.id`,
];

const kp1 = `Syarat & ketentuan yang ditetapkan di bawah ini mengatur pemakaian jasa yang ditawarkan oleh PT Balai Lelang Caready terkait transaksi dan penggunaan layanan di Caready serta situs www.caready.co.id. Pengguna disarankan membaca dengan seksama karena dapat berdampak kepada hak dan kewajiban Pengguna di bawah hukum.`;

const kp2 = `Dengan mendaftar dan/atau menggunakan situs www.caready.co.id, maka pengguna dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam syarat & ketentuan. Syarat & ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Pengguna dengan PT Balai Lelang Caready. Jika pengguna tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat & ketentuan, maka pengguna tidak diperkenankan menggunakan layanan di Caready melalui situs www.caready.co.id.`;

const kp3 = [
    {
        label: 'Informasi',
        caption2: '',
        caption: '',
        child: [
            {
                label: `PT Balai Lelang Caready adalah Badan Hukum Indonesia berbentuk Perseroan Terbatas yang khusus didirikan untuk melakukan kegiatan usaha di bidang Lelang dengan merek Caready melalui situs/website dengan nama www.caready.co.id dan melalui Aplikasi dengan nama Caready sebagai media promosi dan penawaran jasa lelang (untuk selanjutnya disebut “Caready”).`,
                points: [],
            },
            {
                label: `Caready telah mendapat izin operasional Balai Lelang dari Direktorat Jenderal Kekayaan Negara Kementerian Keuangan Republik Indonesia Nomor 113/KM.6/2019 tanggal 7 Mei 2019.`,
                points: [],
            },
            {
                label: `Dalam hal meningkatkan pelayanan lelang yang lebih efisien, efektif, transparan, akuntabel, sederhana, modern dan menjamin kepastian hukum serta merespons perkembangan model transaksi perdagangan melalui sistem elektronik, Caready telah memenuhi unsur kepatuhan terhadap Peraturan Menteri Keuangan Republik Indonesia Nomor 122 Tahun 2023 tentang Petunjuk Pelaksanaan Lelang.`,
                points: [],
            },
            {
                label: `Syarat & ketentuan adalah perjanjian antara Pengguna dan Caready yang berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung jawab pengguna dan Caready, serta tata cara penggunaan sistem layanan Caready.`,
                points: [],
            },
            {
                label: `Pengguna / Calon Peserta Lelang adalah Orang, Korporasi, instansi atau lembaga yang dibentuk dengan peraturan perundang-undangan yang menggunakan layanan Caready, termasuk namun tetapi tidak terbatas pada kunjungan lokasi kantor perwakilan Caready maupun kunjungan situs Caready.`,
                points: [],
            },
            {
                label: `Peserta Lelang adalah Orang, Korporasi, instansi atau lembaga yang dibentuk dengan peraturan perundang-undangan yang menggunakan layanan Caready dengan status terdaftar dan telah melakukan pelunasan pembayaran deposit / Uang Jaminan Penawaran Lelang serta dapat mengikuti dan melakukan penawaran terhadap Objek Lelang pada penyelenggaraan lelang oleh Caready baik hadir secara langsung di lokasi kantor perwakilan Caready atau hadir secara daring (online) melalui layanan Caready atau hadir secara langsung dalam bentuk lelang langsung / live auction`,
                points: [],
            },
            {
                label: `Pembeli Lelang / Pemenang Lelang adalah Peserta Lelang yang mengajukan penawaran tertinggi dan memenangkan Objek Lelang dalam penyelenggaraan Lelang oleh Caready serta mendapatkan Konfirmasi Pemenang Lelang (KPL) resmi dari Caready.`,
                points: [],
            },
            {
                label: `Pemilik Barang / Penitip adalah Pengguna dengan status terdaftar yang memiliki hak kepemilikan atas suatu Barang yang dilelang berdasarkan Perjanjian Kerjasama antara Caready dengan Pemilik Barang / Penitip.`,
                points: [],
            },
            {
                label: `Objek Lelang adalah Barang yang dilelang meliputi mobil, motor, alat berat, dan barang elektronik yang telah memenuhi Legalitas Objek Lelang serta dapat diikutsertakan dalam penyelenggaraan Lelang.`,
                points: [],
            },
            {
                label: `Daftar lot adalah daftar yang berisi objek lelang yang ditawarkan dalam lelang.`,
                points: [],
            },
            {
                label: `Objek lelang tidak dapat ditukar sebagian atau keseluruhan dengan objek lelang manapun.`,
                points: [],
            },
            {
                label: `Dalam hal Caready mengalami keadaan kahar, seperti bencana alam, kerusuhan massa, atau tindakan pemerintah dalam bidang moneter, segala akibat dan/atau kerugian yang timbul menjadi tanggung jawab Pemenang Lelang.`,
                points: [],
            },
            {
                label: `Setiap informasi terkini sehubungan dengan penyelenggaraan Lelang akan diberitahukan melalui situs atau Aplikasi Caready. Pengguna diharapkan selalu memeriksa informasi terkini pada situs dan Aplikasi Caready.`,
                points: [],
            },
            {
                label: `Barang adalah tiap benda atau hak yang dapat dijual secara Lelang.`,
                points: [],
            },
            {
                label: `Aplikasi adalah program komputer berbasis internet yang digunakan untuk menyelenggarakan dan/atau memfasilitasi Lelang yang dikembangkan/disediakan oleh Caready.`,
                points: [],
            },
            {
                label: `Uang Jaminan Penawaran Lelang / Uang Jaminan adalah sejumlah uang yang disetor kepada Balai Lelang oleh Pengguna / Calon Peserta Lelang sebelum pelaksanaan Lelang sebagai syarat menjadi Peserta Lelang.`,
                points: [],
            },
            {
                label: `Harga Terbentuk / Harga Lelang adalah harga penawaran tertinggi yang diajukan Peserta Lelang yang telah disahkan sebagai Pemenang Lelang oleh Pejabat Lelang`,
                points: [],
            },
            {
                label: `Dalam menggunakan layanan Caready, Pengguna dilarang menyelenggarakan kegiatan di luar ketentuan yang diberikan dalam layanan Caready meliputi:`,
                points: [
                  'Dilarang melakukan tindakan berupa salinan atas bagian apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa perubahan atas bagian apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa terjemahan atas bagian apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa ciptaan tiruan atas bagian apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa penjualan atas kesediaan barang apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa pemindahan atas kesediaan barang atau data dan informasi apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa duplikasi atas bagian apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa penyebaran informasi yang keliru atas bagian apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau pada saat aktivitas layanan Caready berupa open house atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online maupun luring/offline untuk kepentingan pribadi;',
                  'Dilarang melakukan tindakan berupa peretasan dalam bentuk apapun dan dengan alat pendukung apapun pada layanan Caready baik secara daring/online dalam situs maupun aplikasi Caready atau aktivitas selama proses penyelenggaraan Lelang baik secara daring/online untuk kepentingan pribadi;',
                ],
            },
        ],
    },
    {
        label: 'Akun, Password, dan Keamanan Data',
        caption2: '',
        caption: '',
        child: [
            {
                label: `Peserta lelang dengan ini menyatakan bahwa peserta lelang adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum.`,
                points: [],
            },
            {
                label: `Caready tidak memungut biaya pendaftaran akun kepada Peserta Lelang. Pemenuhan pembayaran Uang Jaminan Penawaran Lelang dinyatakan dalam bagian pembelian Nomor Induk Peserta Lelang (NIPL).`,
                points: [],
            },
            {
                label: `Peserta Lelang yang bermaksud mengikuti Lelang terlebih dahulu melakukan registrasi akun di situs Caready atau Aplikasi Caready dengan memasukkan data yang telah ditentukan atau memberikan tautan akun salah satu media sosial atau akun surat elektronik (Fecebook, Twitter, Linkedin, Google).`,
                points: [],
            },
            {
                label: `Peserta lelang yang telah memiliki akun diwajibkan terlebih dahulu masuk ke akun yang telah terdaftar sebelum membeli NIPL.`,
                points: [],
            },
            {
                label: `Peserta Lelang wajib memperbaharui dan melengkapi data pada akun Peserta Lelang untuk memudahkan Peserta Lelang dalam segala transaksi sehubungan dengan penyelenggaraan Lelang.`,
                points: [],
            },
            {
                label: `Peserta Lelang wajib memberikan informasi nomor Kartu Tanda Penduduk (KTP) dan Nomor Pokok Wajib Pajak (NPWP) pada akun Peserta Lelang sesuai dengan ketentuan Peraturan Direktorat Jenderal Pajak Nomor 26/PJ/2017 dari Direktorat Jenderal Pajak Kementerian Keuangan Republik Indonesia.`,
                points: [],
            },
            {
                label: `Caready berhak dan berwenang melakukan tindakan yang diperlukan atas setiap dugaan pelanggaran terhadap Syarat & Ketentuan Caready dan/atau pelanggaran terhadap hukum yang berlaku kepada Peserta Lelang berupa penanggguhan sampai penghapusan akun Pengguna`,
                points: [],
            },
            {
                label: `Peserta lelang bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun dan password untuk semua aktivitas yang terjadi dalam akun peserta lelang.`,
                points: [],
            },
            {
                label: `Peserta lelang maupun pengguna dilarang untuk menciptakan dan/atau menggunakan perangkat, software, fitur dan/atau alat lainnya yang bertujuan untuk melakukan manipulasi pada sistem Caready. Tindakan manipulasi sistem Caready akan kami tindak sesuai dengan syarat dan ketentuan serta hukum perundangan yang berlaku di Republik Indonesia.`,
                points: [],
            },
            {
                label: `Caready tidak akan meminta username, password maupun kode SMS verifikasi atau kode OTP milik akun Peserta lelang untuk alasan apapun, oleh karena itu Caready menghimbau Peserta lelang agar tidak memberikan data tersebut maupun data penting lainnya kepada pihak yang mengatasnamakan Caready atau pihak lain yang tidak dapat dijamin keamanannya.`,
                points: [],
            },
            {
                label: `Peserta lelang dengan ini menyatakan bahwa Caready tidak bertanggung jawab atas kerugian ataupun kendala yang timbul atas penyalahgunaan akun Peserta lelang yang diakibatkan oleh kelalaian Peserta Lelang, termasuk namun tidak terbatas pada meminjamkan atau memberikan akses akun kepada pihak lain, mengakses link atau tautan yang diberikan oleh pihak lain, memberikan atau memperlihatkan kode verifikasi (OTP), password atau email kepada pihak lain, maupun kelalaian Peserta lelang lainnya yang mengakibatkan kerugian ataupun kendala pada akun Peserta lelang.`,
                points: [],
            },
            {
                label: `Data dan informasi pribadi semua pemilik akun di situs serta aplikasi mobile Caready dilindungi dalam Kebijakan Privasi.`,
                points: [],
            },
        ],
    },
    {
        label: 'Open House',
        caption2: '',
        caption: '',
        child: [
            {
                label: `Open House adalah kesempatan dengan waktu tertentu yang diberikan oleh Caready kepada para Peserta lelang untuk memeriksa kondisi objek lelang sebelum pelaksanaan lelang dimulai.`,
                points: [],
            },
            {
                label: `Peserta lelang wajib melakukan cek fisik kendaraan dan dokumen serta lokasi unit display dengan sebaik-baiknya karena kami melelang objek lelang dengan kondisi apa adanya (as is).`,
                points: [],
            },
            {
                label: `Peserta lelang hanya diperkenankan melakukan cek fisik dan kelengkapan objek lelang, tapi tidak diperbolehkan melakukan bongkar pasang serta kegiatan lain yang dapat merusak objek lelang.`,
                points: [],
            },
            {
                label: `Peserta lelang dilarang mengambil/merusak/memindahkan atribut/nomor/tulisan/tanda-tanda yang menempel pada kendaraan atau objek lelang lain.`,
                points: [],
            },
            {
                label: `Peserta lelang wajib tunduk pada kebijakan serta aturan keamanan, keselamatan, dan kesehatan yang berlaku di area open house.`,
                points: [],
            },
            {
                label: `Pihak Caready tanpa pemberitahuan terlebih dahulu kepada peserta lelang, memiliki kewenangan untuk melakukan tindakan yang perlu, seperti meminta identitas peserta, melarang peserta masuk, atau mengusir peserta dari open house. Hal tersebut dilakukan atas setiap dugaan pelanggaran yang dilakukan oleh peserta lelang terhadap syarat dan ketentuan serta hukum yang berlaku.`,
                points: [],
            },
            {
                label: `Pihak Caready memiliki kewenangan untuk membatasi dan/atau menghentikan kegiatan open house jika dirasa perlu karena alasan-alasan tertentu, seperti bencana alam, kerusuhan massa, serta hal lain yang dapat mengganggu dan/atau membahayakan kegiatan open house.`,
                points: [],
            },
        ],
    },
    {
        label: 'Kondisi Objek Lelang',
        caption2: '',
        caption: '',
        child: [
            {
                label: `Caready telah membuat daftar lot yang dapat diakses melalui situs dan Aplikasi Caready maupun daftar lot dalam bentuk berkas yang berisi Objek Lelang.`,
                points: [],
            },
            {
                label: `Seluruh data dalam daftar lot berfungsi sebagai panduan. Apabila terdapat ketidaksesuaian data pada daftar lot dengan Objek Lelang, maka daftar lot tidak dapat digunakan sebagai dasar atau alasan mengajukan keberatan, klaim atau protes dalam bentuk apapun.`,
                points: [],
            },
            {
                label: `Pengguna setuju dan sepakat atas ketidaksesuaian data dan informasi pada daftar lot dengan Objek Lelang dalam hal Caready beritikad baik dan mengusahakan yang terbaik dalam menyesuaikan daftar lot dengan Objek Lelang, baik dalam bentuk foto, data detail kendaraan, dokumen, grading serta keterangan lainnya serta membebaskan Caready dalam segala bentuk pertanggungjawaban.`,
                points: [],
            },
            {
                label: `Peserta lelang memahami dan menyetujui bahwa objek yang dilelang adalah “sebagaimana adanya" atau “As Is”.`,
                points: [],
            },
            {
                label: `Caready menyarankan kepada Peserta Lelang untuk memeriksa dan mengetahui kondisi fisik dan kelengkapan dokumen Objek Lelang sebelum penawaran Lelang. Peserta Lelang dapat melakukan pemeriksaan secara sendiri atau diwakilkan orang lain. Apabila Caready melakukan penyelenggaraan Lelang dalam bentuk Flash Auction, Peserta Lelang dapat melakukan partisipasi dalam pemeriksaan kondisi fisik Objek Lelang melalui daring dengan layanan siaran langsung / live streaming yang disediakan Caready. Caready tidak bertanggung jawab atas kekurangan, perbedaan atau cacat, baik yang terlihat maupun tidak terlihat pada saat penyelenggaraan Lelang.`,
                points: [],
            },
            {
                label: `Caready tidak bertanggung jawab atas kendala perangkat/jejaring/ISP yang digunakan Peserta Lelang pada saat melakukan akses melalui situs atau Aplikasi Caready yang berakibat tidak sesuainya seluruh foto dan informasi lain pada halaman situs atau Aplikasi Caready.`,
                points: [],
            },
        ],
    },
    {
        label: 'Pembelian Nomor Induk Peserta Lelang (NIPL)',
        caption2: '',
        caption: '',
        child: [
            {
                label: `Semua pembayaran harus dilakukan dengan mata uang Rupiah.`,
                points: [],
            },
            {
                label: `Nomor Induk Peserta Lelang yang selanjutnya disebut NIPL, adalah nomor yang dapat digunakan Peserta Lelang untuk melakukan penawaran atau bidding.`,
                points: [],
            },
            {
                label: `Pengguna wajib terlebih dahulu melakukan pemenuhan pembayaran Uang Jaminan Penawaran Lelang melalui Virtual Account sesuai kode yang diterima masing-masing Pengguna untuk mendapatkan NIPL dan dinyatakan secara sah sebagai Peserta Lelang. Adapun nominal Uang Jaminan Penawaran Lelang meliputi:`,
                points: [
                    `Rp. 5.000.000 / NIPL untuk Objek Lelang kategori mobil;`,
                    `Rp. 1.000.000 / NIPL untuk Objek Lelang kategori motor;`,
                    `Rp. 5.000.000 / NIPL untuk Objek Lelang kategori alat berat; dan`,
                    `Rp. 500.000 / NIPL untuk Objek Lelang kategori elektronik.`,
                ],
            },
            {
                label: `Peserta dapat menggunakan NIPL yang telah dibeli untuk melakukan penawaran atau bidding dari hanya melalui situs atau Aplikasi.`,
                points: [],
            },
            {
                label: `Peserta juga dapat memilih membeli NIPL Unlimited agar bisa menawar lebih dari satu kendaraan di semua opsi dan jadwal lelang yang tersedia tanpa batasan maksimal.`,
                points: [],
            },
            {
                label: `Caready berhak menentukan nominal Uang Jaminan Penawaran Lelang di luar ketentuan yang sudah dinyatakan terlebih dahulu pada poin 3 (tiga) di atas berdasarkan ketentuan penilaian Caready dengan persetujuan manajemen Caready.`,
                points: [],
            },
        ],
    },
    {
        label: 'NIPL Unlimited',
        caption2: 'Setiap pelanggan wajib untuk melengkapi identitas pribadi pada Pengaturan Profil Pelanggan di situs atau Aplikasi Caready dengan syarat sebagai berikut:',
        caption: 'NIPL Unlimited merupakan program loyalty khusus yang diberikan kepada pelanggan Membership Gold dan Platinum, dimana pelanggan mendapatkan hak istimewa mengikuti lelang dan menang maksimal 4 mobil per Event Lelang hanya menggunakan 1 NIPL saja dengan transfer deposit sebesar Rp.20.000.000,-yang akan mengendap dan dapat digunakan untuk mengikuti semua Event Lelang Caready minimal 6 bulan.',
        child: [
            {
                label: `Peserta lelang telah membaca dan menyetujui syarat dan ketentuan lelang Caready.`,
                points: [],
            },
            {
                label: `Wajib memiliki KTP dan melakukan scan e-KTP.`,
                points: [],
            },
            {
                label: `Memiliki NPWP.`,
                points: [],
            },
            {
                label: `Wajib verifikasi nomor Handphone yang terdaftar melalui kode OTP (One Time Password).`,
                points: [],
            },
            {
                label: `Memastikan nomor rekening pelanggan yang terdaftar sesuai dengan nama pelanggan yang terdaftar.`,
                points: [],
            },
        ],
    },
    {
        label: 'Masa Berlaku NIPL Unlimited',
        caption2: '',
        caption: 'Setiap pelanggan yang mengikuti program NIPL Unlimited telah dianggap setuju pada syarat dan ketentuan sebagai berikut :',
        child: [
            {
                label: `Pelanggan melakukan deposit Uang Jaminan Penawaran Lelang sebesar Rp.20.000.000,-dengan maksimal memenangkan 5 mobil per Event lelang mobil.`,
                points: [],
            },
            {
                label: `Pembelian NIPL Unlimited hanya untuk 1 (satu) kali untuk 1 (satu) Akun.`,
                points: [],
            },
            {
                label: `Metode pembayaran NIPL Unlimited hanya melalui Virtual Account BCA dan Mandiri.`,
                points: [],
            },
            {
                label: `Deposit Uang Jaminan Penawaran Lelang mengendap minimal 6 bulan sejak pelanggan membeli NIPL Unlimited.`,
                points: [],
            },
            {
                label: `Pelanggan dapat menghentikan program NIPL Unlimited setelah melewati batas minimal deposit mengendap yaitu minimal 6 bulan.`,
                points: [],
            },
            {
                label: `Apabila masa berlaku NIPL Unlimited sudah masuk tempo 6 bulan atau lebih. Caready akan memberikan notifikasi kepada pelanggan melalui email yang terdaftar.`,
                points: [],
            },
            {
                label: `Apabila pelanggan tidak ingin melanjutkan atau berhenti dari program NIPL Unlimited setelah masuk tempo 6 bulan. Pelanggan dapat mengajukan pemberhentian program melalui link yang dikirimkan Caready melalui email notifikasi.`,
                points: [],
            },
            {
                label: `Apabila pelanggan tidak mengajukan pemberhentian program melalui link email yang Caready kirimkan, maka pelanggan akan secara otomatis melanjutkan program NIPL Unlimited.`,
                points: [],
            },
            {
                label: `Pelangggan yang memenangkan mobil menggunakan NIPL Unlimited wajib melunasi mobil maksimal H+4 Hari kerja sejak lelang berakhir.`,
                points: [],
            },
            {
                label: `Pelunasan mobil yang dimenangkan melalui NIPL Unlimited hanya dapat dilakukan melalui Virtual Account BCA dan Mandiri.`,
                points: [],
            },
            {
                label: `Setiap pelanggan yang tidak melunasi mobil atau melebihi waktu pelunasan yang telah ditentukan yaitu H+4 Hari kerja sejak lelang berakhir, maka akan dianggap sebagai wanprestasi dan wajib membayar denda wanprestasi sebesar Rp. 5.000.000 per mobil.`,
                points: [],
            },
            {
                label: `Setiap pelanggan yang melakukan wanprestasi, NIPL Unlimited nya akan di non-aktifkan hingga pelanggan membayar denda wanprestasi sebesar Rp.5.000.000,- untuk 1 mobil-nya.`,
                points: [],
            },
            {
                label: `Apabila pelanggan tidak membayarkan biaya wanprestasi dan masih ada sisa uang deposit NIPL Unlimited, maka sisa uang jaminan tetap akan mengendap minimal 6 bulan sejak pelanggan mengikuti program NIPL Unlimited.`,
                points: [],
            },
            {
                label: `Maksimal mobil yang wanprestasi adalah 4 mobil dalam periode 1 minggu.`,
                points: [],
            },
            {
                label: `Apabila mobil wanprestasi lebih dari 4 unit, pelanggan wajib untuk membayarkan denda wanprestasi sebesar jumlah mobil yang wanprestasi dengan batas waktu 2 hari kerja sejak mobil dinyatakan wanprestasi.`,
                points: [],
            },
            {
                label: `Apabila pelanggan tidak membayar denda wanprestasi sesuai dengan poin 15, maka program NIPL Unlimited pelanggan akan dinonaktifkan dan akun pelanggan akan dinonaktifkan sehingga pelanggan tidak bisa mengikuti lelang di Caready untuk sementara waktu.`,
                points: [],
            },
            {
                label: `Pelanggan yang ingin mengaktifkan kembali akun Caready yang dimiliki, pelanggan harus membayar denda wanprestasi sesuai poin 16.`,
                points: [],
            },
            {
                label: `Pelanggan yang terindikasi melakukan Tindakan kecurangan dengan sengaja, maka akan dikenakan sanksi sesuai Peraturan Menteri Keuangan nomor 213 tahun 2020 poin 13E Bagian Tata Cara Pelaksanaan Penawaran Lelang.`,
                points: [],
            },
        ],
    },
    {
        label: 'Saat Mengikuti Lelang',
        caption2: '',
        caption: 'Peserta lelang yang dinyatakan memenangkan lelang adalah yang diputuskan oleh Pejabat Lelang dan akan diberikan konfirmasi pemenang lelang. lelang. Untuk peserta lelang yang melakukan penawaran di tempat atau akan diberikan surat konfirmasi pemenang lelang yang harus ditandatangani oleh pemenang lelang. Untuk peserta lelang yang melakukan penawaran melalui situs atau Aplikasi Caready akan menerima notifikasi serta email yang menginformasikan peserta sebagai pemenang lelang. Apabila terjadi kegagalan sistem dalam live auction yang menimbulkan ketidakjelasan informasi antara pemenang floor dan pemenang online, yang berhak menentukan pemenang lelang adalah keputusan Pejabat Lelang dan akan diberikan konfirmasi pemenang.',
        child: [],
    },
]

const kp4 = [
    {
        label: 'Lelang Mobil',
        points: [
            {
                value1: 'Harga Terbentuk',
                value2: 'Admin Fee',
            },
            {
                value1: 'Rp. 0 - Rp. 400.000.000,00',
                value2: 'Rp. 3.000.000,00 (tiga juta Rupiah)',
            },
            {
                value1: 'lebih dari Rp. 400.000.000,00 - Rp. 600.000.000,00',
                value2: 'Rp. 5.000.000,00 (lima juta Rupiah)',
            },
            {
                value1: 'lebih dari Rp. 600.000.000,00 - Rp. 800.000.000,00',
                value2: 'Rp. 8.000.000,00 (delapan juta Rupiah)',
            },
            {
                value1: 'Ketika harga terbentuk diatas 800 Juta, setiap kelipatan 200 Jt',
                value2: 'Maka biaya admin ditambah Rp 2.000.000 (dua juta rupiah)',
            },
        ]
    },
    {
        label: 'Lelang Motor',
        points: [
            {
                value1: 'Harga Terbentuk',
                value2: 'Admin Fee',
            },
            {
                value1: 'Rp. 0 - Rp. 29.900.000,00',
                value2: 'Rp. 500.000,00 (lima ratus ribu Rupiah)',
            },
            {
                value1: 'Rp. 30.000.000,00 - Rp. 99.900.000,00',
                value2: 'Rp. 1.000.000 (satu juta Rupiah)',
            },
            {
                value1: 'Rp. 100.000.000,00 - Rp. 199.900.000,00',
                value2: 'Rp. 2.000.000,00 (dua juta Rupiah)',
            },
        ]
    },
    {
        label: 'Lelang Elektronik',
        points: [
            {
                value1: 'Harga Terbentuk',
                value2: 'Admin Fee',
            },
            {
                value1: 'Rp. 0 - Rp. 29.900.000,00',
                value2: 'Rp. 300.000,00 (tiga ratus ribu Rupiah)',
            },
            {
                value1: 'Rp. 30.000.000,00 - Rp. 99.900.000,00',
                value2: 'Rp. 1.000.000 (satu juta Rupiah)',
            },
            {
                value1: 'Rp. 100.000.000,00 - Rp. 199.900.000,00',
                value2: 'Rp. 2.000.000,00 (dua juta Rupiah)',
            },
        ]
    },
]

const kp42 = [
    {
        label: `Pemenang lelang wajib melunasi total harga objek lelang selambatnya 4 hari kerja setelah tanggal pelaksanaan lelang. Harga akhir dikalkulasikan dari total harga objek lelang dikurangi uang jaminan atau deposit dari pembelian NIPL, lalu ditambah biaya administrasi. (detail tagihan akan dikirim lewat email atau dapat dilihat pada akun peserta di website maupun aplikasi mobile).`,
        points: [],
    },
    {
        label: `Pemenang lelang harus melakukan pembayaran atas objek lelang yang dimenangkan menggunakan nomor Virtual Account yang akan diinformasikan bersamaan dengan detail informasi tagihan pelunasan melalui email atau dapat dilihat pada akun peserta di website maupun aplikasi mobile.`,
        points: [],
    },
    {
        label: `Pemenang lelang yang sudah melunasi tagihan dan menerima konfirmasi pembayaran dari Caready dapat melakukan serah terima objek lelang beserta dokumen sesuai jadwal yang disampaikan sebelumnya.`,
        points: [],
    },
    {
        label: `Pemenang lelang yang mengundurkan diri dari objek lelang yang dimenangkan atau tidak melunasi pembayaran sesuai tenggat waktu yang telah ditetapkan akan dinyatakan wanprestasi (kemenangannya batal). Uang jaminan pun otomatis hangus.`,
        points: [],
    },
    {
        label: `Jika tidak memenangkan lelang, pengembalian uang jaminan dari pembelian NIPL akan ditransfer kembali ke nomor rekening terdaftar maksimal 3 hari kerja setelah penyelenggaraan lelang.`,
        points: [],
    },
    {
        label: `Jika sampai batas 2 hari kerja setelah tagihan dinyatakan lunas namun pemenang belum juga mengambil objek lelang yang dimenangkan, pemenang akan dikenakan biaya penitipan sebesar Rp 100.000/ objek lelang per hari keterlambatan. Segala kerusakan dan atau kehilangan sepenuhnya menjadi tanggung jawab pemenang.`,
        points: [],
    },
]

const kp5 = {
    label: 'Keluhan, Klaim, dan Sengketa',
    caption: ``,
    child: [
        {
            label: `Caready menyediakan layanan penanganan keluhan, klaim, dan sengketa yang terjadi setelah penyelenggaraan lelang.`,
            points: [],
        },
        {
            label: `Penyampaian keluhan, klaim dan penyelesaian sengketa yang dilakukan ke Caready harus melalui Customer Center Caready (Call center, WA Center, Email CS, serta webchat Caready) dan disertai dengan bukti-bukti transaksi berupa NIPL, KPL, Bukti Serah Terima Kendaraan atau Dokumen, dan bukti-bukti penunjang lainnya yang dapat menjadi dasar pembenar atas setiap argumen yang dikeluarkan oleh pihak penyampai keluhan, klaim dan penyelesaian sengketa.`,
            points: [],
        },
        {
            label: `Keluhan, klaim dan penyelesaian sengketa yang diajukan ke pihak Caready hanya dapat dilakukan oleh Pemenang Lelang atau Peserta Lelang yang secara sah tercatat di Caready.`,
            points: [],
        },
        {
            label: `Dalam penanganan keluhan, klaim dan penyelesaian sengketa setelah lelang, pihak Caready hanya akan berhubungan dengan Pemenang Lelang atau Peserta lelang yang secara sah tercatat di Caready. Pihak Caready berhak untuk tidak menanggapi keluhan, klaim dan sengketa setelah lelang dari pihak selain yang disebutkan sebelumnya.`,
            points: [
                `Keluhan, klaim dan penyelesaian sengketa yang diajukan memiliki batas waktu dari hari serah terima kendaraan dan dokumen.`,
                `Keluhan dan klaim terkait kondisi fisik kendaraan yang dimenangkan lelang paling lambat diajukan pada hari saat kendaraan diserahterimakan. Keluhan dan klaim terkait kondisi fisik kendaraan setelah kendaraan diserahterimakan tidak menjadi hal yang dapat dikeluhkan atau diklaim.`,
                `Keluhan dan klaim terkait dokumen kendaraan (STNK, Pajak, BPKB) hanya dapat dilakukan maksimal 25 Hari Kerja setelah dokumen diserahterimakan.`,
                `Khusus komplain nomor rangka Dan nomor mesin paling lambat 1 (satu) bulan terhitung sejak tanggal Lelang`,
                `Pengajuan komplain BPKB blokir, BPKB duplikat dan BPKB belum di BBN (Balik Nama) paling lambat 3 (tiga) bulan terhitung sejak tanggal lelang.`
            ],
        },
        {
            label: `Keluhan dan klaim terkait pelaksanaan lelang baik itu onsite maupun melalui internet hanya dapat dilakukan pada saat hari lelang.`,
            points: [],
        },
    ],
}


export {
    snk4,
    kp1,
    kp2,
    kp3,
    kp4,
    kp42,
    kp5
}

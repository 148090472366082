const InputField = (props) => {
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="font-sm fw-bold">{props.label} <span className='text-primary'>*</span></label>
            <input
                type={props.type}
                className="form-control font-sm"
                autoComplete={props.autoComplete}
                name={props.name}
                id={props.id}
                onChange={props.onChange}
                placeholder={props.placeholder}
                value={props.value} />
        </div>
    )
}

export default InputField;
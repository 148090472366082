import React from 'react'

export default function CardReview(props) {
  return (
    <>
      <div className="cards-reviews h-100">
        <div className='image'>
          <img src={props.image} alt='' className='w-100 h-100' />
        </div>
        <div className='content'>
          <div className='title'>{props.name}</div>
          <div className='desc'>{props.review}</div>
        </div>
        {/* <div className='footer'><a href="!#">Baca Selengkapnya</a></div> */}
      </div>
    </>
  )
}

import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { reducer as pageReducer } from '../redux/page.slice';
import { reducer as authReducer } from '../redux/auth.slice';
import { reducer as unitReducer } from '../redux/unit.slice';
import { reducer as transactionReducer } from '../redux/transaction.slice';
import { reducer as liveActionReducer } from '../redux/liveAuction.slice';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import thunk from 'redux-thunk';

const reducer = combineReducers({
    pageReducer,
    authReducer,
    unitReducer,
    transactionReducer,
    liveActionReducer,
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authReducer']
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)
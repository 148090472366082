import { useState, useEffect, useRef } from 'react';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import SearchField from '../SearchField/SearchField';
import { StampLabel } from '../StampLabel/StampLabel';
import toast from 'react-hot-toast';
import withAuthProvider from '../../services/withAuthProvider';
import MoreButton from '../MoreButton/MoreButton';
import { defaultImageCar, devImage } from '../../utils/defaultValue';
import { locDateFormatter } from '../../utils/string';
import { SkeletonFavorite, SkeletonSearch } from '../Skeleton/Skeleton';
import { useHistory } from 'react-router-dom';
import ModalLayout from '../../containers/Layouts/ModalLayout/ModalLayout';
import Share from '../Modals/Share';

const ChildFavorit = ({
  data,
  handleDelete,
  handleLiveAuction,
  handleShare,
}) => {
  const lot_number = data?.stock_unit?.lot_number;
  const lane_name = data?.stock_unit?.auction?.auction_lane_name;
  return (
    <div className='card rounded-custom overflow-hidden'>
      <div className='row'>
        <div className='col-md-3 pe-md-0 position-relative fav-img-container'>
          <span className='position-absolute top-0 end-0 py-1 px-2 fw-semibold mt-2 me-4 me-md-2 font-sm text-primary bg-light rounded'>
            Grade {data?.stock_unit?.unit_inspection?.grade || '-'}
          </span>
          <img
            src={data?.stock_unit?.pictures[0]?.image_url || defaultImageCar}
            style={{ objectFit: 'cover' }}
            className='w-100 h-100'
            alt=''
          />
        </div>
        <div className='col-md-6 ps-md-0'>
          <StampLabel
            text={
              lot_number && lane_name ? `LOT-${lane_name}${lot_number}` : '-'
            }
          />
          <div className='p-2'>
            <div className='border-bottom py-2'>
              <div className='text-secondary fw-bold mb-3'>
                {data?.stock_unit?.unit_name || '-'}
              </div>
              <div>
                {data?.stock_unit?.tags?.map((el, idx) => (
                  <span className='value me-1' key={idx}>
                    <span className='badge-status secondary'>
                      {el?.name || '-'}
                    </span>
                  </span>
                ))}
              </div>
              <div className='fw-bold'>
                Rp.{' '}
                {data?.stock_unit?.base_price
                  ? data?.stock_unit?.base_price?.toLocaleString()
                  : '-'}
              </div>
            </div>
            <div className='d-flex font-xs align-items-center my-2 justify-content-between'>
              <div className='text-secondary fw-semibold'>
                {data?.stock_unit?.location?.name &&
                data?.stock_unit?.auction?.date_start &&
                data?.stock_unit?.auction?.time_start
                  ? locDateFormatter(
                      data?.stock_unit?.location?.name,
                      new Date(data?.stock_unit?.auction?.date_start),
                      data?.stock_unit?.auction?.time_start
                    )
                  : ''}
              </div>
              {/* <div className="border rounded px-2 border-primary text-primary">
                                Live
                            </div> */}
            </div>
          </div>
        </div>
        <div className='col-md-3 py-3 px-4'>
          <div className='d-flex flex-column align-items-start fw-semibold font-sm'>
            <PrimaryButton
              className='w-100 fw-semibold'
              onClick={handleLiveAuction}
            >
              Live Auction
            </PrimaryButton>
            <div
              className='pt-2 text-center w-100 cursor-pointer hover-primary disable-copy'
              onClick={handleDelete}
            >
              Hapus Wishlist
            </div>
            <div
              className='pt-2 text-center w-100 cursor-pointer hover-primary disable-copy'
              onClick={handleShare}
            >
              Bagikan
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const data = [1, 1, 1];

const initialPaginate = {
  page: 1,
  total: 1,
};

const FavoritContent = () => {
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [process, setProcess] = useState(true);
  const [search, setSearch] = useState('');
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const ref = useRef(true);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const { push } = useHistory();
  const [detail, setDetail] = useState([]);
  const [favorite, setFavorite] = useState();

  const openModalShare = () => {
    setIsOpenShare(true);
  };

  const closeModalShare = () => {
    setIsOpenShare(false);
  };

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const getFavorites = async (params) => {
    const response = await withAuthProvider.getDataWithAuth(
      `api/favorite`,
      params
    );
    if (response?.status === 200) {
      const res = response.data.data;
      setFavorites((prev) => [...prev, ...res.data]);
      setPaginate((prev) => ({
        ...prev,
        total: res.last_page,
      }));
    } else {
      // console.log(response.response);
    }
  };

  const removeFavorite = async (id) => {
    const loading = toast.loading('Mengirimkan...');
    const response = await withAuthProvider.deleteDataWithAuth(
      `api/favorite/${id}/delete`
    );
    if (response?.status === 200) {
      toast.dismiss(loading);
      toast.success(response.data.message, {
        id: 'success-message',
        duration: 3000,
      });
      //setProcess(prev => !prev);
      setFavorites((current) =>
        current.filter((data) => {
          // 👇️ remove object
          return data.stock_unit_id !== id;
        })
      );
    } else {
      toast.dismiss(loading);
      toast.error('Data gagal dihapus', {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  const getDataByPaginate = () => {
    const params = {
      page: paginate.page,
      page_size: 5,
    };
    getFavorites(params).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (ref.current === false) {
      if (search.length >= 3) {
        const params = {
          search: search,
        };
        getFavorites(params).finally(() => setIsLoading(false));
      }
      if (search.length === 0) {
        setPaginate((prev) => ({
          ...prev,
          page: 1,
        }));
      }
    }
    //eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    getDataByPaginate();
    ref.current = false;
    //eslint-disable-next-line
  }, [paginate.page, process]);

  useEffect(() => {
    const data = favorites.find(
      (data) => data.stock_unit_id === parseInt(favorite)
    );
    if (data) {
      setDetail(data);
    }
  }, [favorite]);

  return (
    <>
      <div className='tab-content-caready border-bottom'>
        <h3 className='title mb-0'>Favorit</h3>
      </div>
      <div className='tab-content-caready'>
        {isLoading ? (
          <SkeletonSearch />
        ) : (
          <SearchField
            placeholder='Cari di Favorit'
            value={search}
            name='search'
            onChange={handleChangeSearch}
          />
        )}
        <div
          className='mt-3 d-flex overflow-auto flex-column'
          style={{ gap: '1rem', maxHeight: '600px' }}
        >
          {isLoading
            ? data.map((el, idx) => (
                <div className='card rounded-custom ' key={`fav-${idx}`}>
                  <SkeletonFavorite />
                </div>
              ))
            : favorites.map((favorite, idx) => (
                <div key={idx}>
                  <ChildFavorit
                    data={favorite}
                    handleDelete={() => removeFavorite(favorite?.stock_unit_id)}
                    handleLiveAuction={() =>
                      push(`/live-auction/${favorite?.stock_unit?.auction?.id}`)
                    }
                    handleShare={() => {
                      setFavorite(favorite?.stock_unit_id);
                      setIsOpenShare(true);
                    }}
                  />
                </div>
              ))}
          {favorites.length === 0 && isLoading === false && (
            <div className='px-3 py-2 font-sm text-center text-secondary w-100'>
              Tidak ada Favorit
            </div>
          )}
        </div>
        {paginate.page !== paginate.total && <MoreButton onClick={loadMore} />}
      </div>
      <ModalLayout
        show={isOpenShare}
        handleClose={closeModalShare}
        title={'Bagikan'}
        className='modal-detail-unit'
      >
        <Share
          shareUrl={
            window.location.origin + '/unit-lelang/' + detail?.stock_unit?.id
          }
        />
      </ModalLayout>
    </>
  );
};

export default FavoritContent;

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { SkeletonCustom, SkeletonImage } from '../../../components/Skeleton/Skeleton'

export const SkeletonInfoCareadyDetail = ({ data }) => (
    <Container>
        <Row>
            <Col md={{ span: 8, offset: 2 }}>
                <div className="article">
                    <SkeletonCustom width="30%" />
                    <SkeletonCustom height="120%" />
                    <SkeletonCustom width="10%" />
                    <div className='image'>
                        <SkeletonImage />
                    </div>
                    <div>
                        <SkeletonCustom count={5} />
                        <br />
                        <SkeletonCustom count={5} />
                        <br />
                        <SkeletonCustom count={5} />
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
)
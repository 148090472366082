import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export const InfoCareadyNotFound = ({ data }) => (
    <Container>
        <Row>
            <Col md={{ span: 8, offset: 2 }}>
                <div className="article">
                    <h1>Artikel tidak ditemukan</h1>
                </div>
            </Col>
        </Row>
    </Container>
)
const banks = [
  { id: "bca", name: 'BCA Virtual Account' },
  // { id: "bni", name: 'BNI Virtual Account' },
  // { id: "bri", name: 'BRI Virtual Account' },
  // { id: "mandiri", name: 'Mandiri Virtual Account' },
  { id: "permata", name: 'Permata Virtual Account' },
]

export {
  banks
}
export default function WarningInv() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.00033 17.3327C4.39783 17.3327 0.666992 13.6018 0.666992 8.99935C0.666992 4.39685 4.39783 0.666016 9.00033 0.666016C13.6028 0.666016 17.3337 4.39685 17.3337 8.99935C17.3337 13.6018 13.6028 17.3327 9.00033 17.3327ZM9.00033 15.666C10.7684 15.666 12.4641 14.9636 13.7144 13.7134C14.9646 12.4632 15.667 10.7675 15.667 8.99935C15.667 7.23124 14.9646 5.53555 13.7144 4.2853C12.4641 3.03506 10.7684 2.33268 9.00033 2.33268C7.23222 2.33268 5.53652 3.03506 4.28628 4.2853C3.03604 5.53555 2.33366 7.23124 2.33366 8.99935C2.33366 10.7675 3.03604 12.4632 4.28628 13.7134C5.53652 14.9636 7.23222 15.666 9.00033 15.666ZM8.16699 4.83268H9.83366V6.49935H8.16699V4.83268ZM8.16699 8.16602H9.83366V13.166H8.16699V8.16602Z'
        fill='#8C8D8F'
      />
    </svg>
  );
}

import React from 'react';
import { Button } from 'react-bootstrap';
import headerImage from '../../assets/images/winner-auction.png';
import carUnit from '../../assets/images/car-unit.webp';
import { StampLabel } from '../StampLabel/StampLabel';
import { useSelector } from 'react-redux';
import { currentdataUnitSelector } from '../../redux/liveAuction.slice';

const KonfirmasiMenangLelang = (props) => {
  const currentDataUnit = useSelector(currentdataUnitSelector);
  return (
    <>
      {' '}
      <div className='com-modal-bg'></div>
      <div className='com-modal d-flex flex-center'>
        <div className='com-modal-dialog'>
          <div className='com-modal-content'>
            <div className='winner-auction'>
              <div
                className='winner-auction-header'
                style={{ background: `url(${headerImage})` }}
              >
                SELAMAT
                <br />
                ANDA MENANG
              </div>
              <div className='winner-auction-body'>
                <div className='auction-unit mb-2'>
                  <div
                    className='auction-unit-image h-100'
                    style={{
                      background: `url("${
                        props?.data?.pictures?.length > 0
                          ? props?.data?.pictures[0]?.image_url
                          : carUnit
                      }")`,
                      minHeight: '160px',
                    }}
                  ></div>
                  <div className='auction-unit-content'>
                    <div className='d-inline-block position-relative'>
                      <div className='bg-primary ps-2 pe-3 skew-label'>
                        Lot-{props?.data?.lot_number}
                      </div>
                    </div>
                    <div className='auction-unit-info'>
                      <div className='auction-unit-name mb-3'>
                        {props?.data?.unit_name}
                      </div>
                      <div className=''>Harga Final</div>
                      <div className='auction-unit-price mb-3'>
                        Rp {props?.data?.final_price?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-2'>
                  Anda bisa menyelesaikan pembayaran setelah klik tombol
                  "Checkout"
                </div>
                <div className='group'>
                  <Button
                    className='cancel'
                    variant='default'
                    type='button'
                    onClick={props.continue}
                  >
                    Lanjut Lelang
                  </Button>
                  <Button
                    variant='primary'
                    type='button'
                    onClick={props.checkout}
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KonfirmasiMenangLelang;

import TitipLelang from "../../components/Section/TitipLelang";
import InfoCaready from "../../components/Section/InfoCaready";
// import TentangCaready from "../../components/Section/TentangCaready";
import { SearchSection } from "../../components/Section/SearchSection";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import withoutAuthProvider from "../../services/withoutAuthProvider";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/auth.slice";
import InfoSignUp from "../../components/InfoSignUp/InfoSignUp";
import ModalLayout from "../../containers/Layouts/ModalLayout/ModalLayout";
import HomeBanner from "../../assets/images/popup-banner.png";

const Home = () => {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [popup, setPopUp] = useState({});
  const isLogged = useSelector((state) => state.authReducer.isLogged);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector(userSelector);

  const getBlogs = async () => {
    const params = {
      page: 1,
      page_size: 3,
    };
    const response = await withoutAuthProvider.getDataWithoutAuth(
      "/api/blog",
      params
    );
    if (response?.status === 200) {
      setBlogs(response?.data.data.data);
    } else {
      // console.log(response?.response)
      // toast.error(response.data.message, {
      //     id: 'error-message',
      //     duration: 3000
      // });
    }
  };

  const getPopUp = async () => {
    const response = await withoutAuthProvider.getDataWithoutAuth(
      "/api/popup"
    );
    console.log("response", response)
    if (response?.status === 200) {
      setPopUp(response?.data.data[0]);
      setShowModal(true);
    }
  };

  useEffect(() => {
    getBlogs().finally(() => setIsLoading(false));
    getPopUp().finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#F9FAFB" }}>
        <SearchSection />
        {/* <TentangCaready /> */}
        <TitipLelang isLogged={isLogged} push={push} />
        <InfoCaready data={blogs} loading={isLoading} push={push} />
        <InfoSignUp />
      </div>
      {/* <Pop Up  /> */}
      {popup?.is_active? (<ModalLayout
        show={showModal}
        handleClose={() => setShowModal(false)}
        className="w-75"
        type="home-banner"
      >
        <img
          src={popup?.file_url}
          alt=""
          className="w-100 cursor-pointer homeBanner"
          onClick={() => window.open(popup?.url, "_blank")}
          // onClick={() => window.open("https://wa.me/62081278968822", "_blank")}
        />
      </ModalLayout>): null}
      
    </>
  );
};

export default Home;

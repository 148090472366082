export default function Headphone({ className }) {
    return (
        <svg
            className={className}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 6H3.5C3.76522 6 4.01957 6.10536 4.20711 6.29289C4.39464 6.48043 4.5 6.73478 4.5 7V9.5C4.5 9.76522 4.39464 10.0196 4.20711 10.2071C4.01957 10.3946 3.76522 10.5 3.5 10.5H2C1.73478 10.5 1.48043 10.3946 1.29289 10.2071C1.10536 10.0196 1 9.76522 1 9.5V6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6V9.5C11 9.76522 10.8946 10.0196 10.7071 10.2071C10.5196 10.3946 10.2652 10.5 10 10.5H8.5C8.23478 10.5 7.98043 10.3946 7.79289 10.2071C7.60536 10.0196 7.5 9.76522 7.5 9.5V7C7.5 6.73478 7.60536 6.48043 7.79289 6.29289C7.98043 6.10536 8.23478 6 8.5 6H10C10 4.93913 9.57857 3.92172 8.82843 3.17157C8.07828 2.42143 7.06087 2 6 2C4.93913 2 3.92172 2.42143 3.17157 3.17157C2.42143 3.92172 2 4.93913 2 6Z"
                fill="currentColor" />
        </svg>
    );
}

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const isSupport = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window

const firebaseConfig = {
  apiKey: "AIzaSyB9oBhc_QJpswT5nHLxuYg-3dKvZSpueKI",
  authDomain: "caready-mobile-88248.firebaseapp.com",
  projectId: "caready-mobile-88248",
  storageBucket: "caready-mobile-88248.appspot.com",
  messagingSenderId: "738493044320",
  appId: "1:738493044320:web:2c24dc9303a78c195ad505",
  measurementId: "G-9CLRB9QLGJ"
};

const app = initializeApp(firebaseConfig);

let messaging = isSupport() ? getMessaging(app) : null;

export const requestForToken = async () => {
  let currentToken = '';
  try {
    currentToken = await getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY});
    console.log('[LOG] => currentToken', JSON.stringify(currentToken, null, 4));
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};

export const requestPermission = async () => {
  if(isSupport()) {
    return await Notification.requestPermission();
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

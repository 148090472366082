import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <>
            <div className="slider-arrow next reviews" style={{ ...style }} onClick={onClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <>
            <div className="slider-arrow previous reviews" style={{ ...style }} onClick={onClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </>
    );
}

export default class CarouselMultipleLayout extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const settings = {
            dots: false,
            infinite: ((this.props.totalItem ? this.props.totalItem : 0) > (this.props.slideToShow ? this.props.slideToShow : 3)),
            // infinite: true,
            centerMode: this.props.center || false,
            slidesToShow: this.props.slideToShow || 3,
            slidesToScroll: this.props.slidesToScroll || 1,
            nextArrow: <SampleNextArrow className={`${this.props.arrowClass ? this.props.arrowClass : "slider-arrow"}`} />,
            prevArrow: <SamplePrevArrow className={`${this.props.arrowClass ? this.props.arrowClass : "slider-arrow"}`} />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <>
                <Slider {...settings} className={this.props.className}>
                    {this.props.children}
                </Slider>
            </>
        );
    }
}
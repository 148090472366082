import { useCallback, useEffect, useState } from 'react';
import { Form, Image, Row, Col } from 'react-bootstrap';
import {
  BikeLine,
  CarLine,
  GoodLine,
  Shield,
  ThumbUp,
  TimerFlash,
} from '../../assets/icons/HomeSettingIcons';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import SearchField from '../SearchField/SearchField';
import withoutAuth from '../../services/withoutAuthProvider';
import ArrowRightSearch from '../../assets/icons/ArrowRightSearch';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { SkeletonCustom, SkeletonImage } from '../Skeleton/Skeleton';

const IconPreview = ({ key, label, icon: Icon }) => (
  <div className='d-flex align-items-center fw-semibold my-2'>
    <div className='me-2' style={{ width: '36px' }}>
      <div
        className='d-inline-block rounded-circle'
        style={style.iconContainer}
      >
        <div className='d-flex flex-center h-100'>
          <Icon className='text-primary' />
        </div>
      </div>
    </div>
    <div>{label}</div>
  </div>
);
const iconData = [
  { title: 'Terpercaya & Aman', icon: Shield },
  { title: 'Mudah & Cepat', icon: TimerFlash },
  { title: 'Penawaran Terbaik', icon: ThumbUp },
];

const initialTab = [
  { title: 'Car', icon: CarLine, active: true },
  { title: 'Bike', icon: BikeLine, active: false },
  { title: 'Good', icon: GoodLine, active: false },
];

const hargaBar = [
  {
    label: 'Dibawah Rp 100,00 jt',
    filter: {
      start_base_price: 0,
      end_base_price: 100000000,
    },
  },
  {
    label: 'Rp 100,00 jt - 200,00 jt',
    filter: {
      start_base_price: 100000000,
      end_base_price: 200000000,
    },
  },
  {
    label: 'Rp 200,00 jt - 300,00 jt',
    filter: {
      start_base_price: 200000000,
      end_base_price: 300000000,
    },
  },
  {
    label: 'Diatas 300,00 jt',
    filter: {
      start_base_price: 300000000,
    },
  },
  {
    label: 'Tampilkan Semua',
    filter: {},
  },
];

const initialFilter = {
  search: '',
  unit_maker_id: '',
  unit_model_id: '',
  unit_model_type_id: '',
  year: '',
  location_id: '',
  unit_segment_id: '',
};

export default function FilterHome() {
  const today = format(new Date(), 'yyyy');
  const { push } = useHistory();
  const [tab, setTab] = useState([]);
  const [filter, setFilter] = useState({ ...initialFilter });
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // list data
  const [unitMakers, setUnitMakers] = useState([]);
  const [unitModels, setUnitModels] = useState([]);
  const [unitModelTypes, setUnitModelTypes] = useState([]);
  const [unitSegments, setUnitSegments] = useState([]);
  const [locations, setLocaitons] = useState([]);
  const [unitType, setUnitType] = useState([]);
  const [selectedUnitType, setSelectedUnitType] = useState([]);
  const [tags, setTags] = useState([]);

  const selectedTab = tab.find((el) => el.active === true);

  const handleClick = (path) => push(path);

  const years = [
    {
      id: 1,
      name: `${today - 3} - ${today}`,
    },
    {
      id: 2,
      name: `${today - 7} - ${today - 4}`,
    },
    {
      id: 3,
      name: `${today - 11} - ${today - 8}`,
    },
    {
      id: 4,
      name: `${today - 15} - ${today - 12}`,
    },
    {
      id: 5,
      name: `Dibawah ${today - 16}`,
    },
  ];

  const merkBars = [
    {
      name: 'unit_maker_id',
      child: unitMakers,
      type: 'select',
      label: 'Unit Maker',
    },
    {
      name: 'unit_model_id',
      child: unitModels,
      type: 'select',
      label: 'Unit Model',
    },
    {
      name: 'unit_model_type_id',
      child: unitModelTypes,
      type: 'select',
      label: 'Unit Model Type',
    },
    {
      name: 'year',
      child: years,
      type: 'select',
      label: 'Tahun',
    },
    {
      name: 'location_id',
      child: locations,
      type: 'select',
      label: 'Lokasi',
    },
    {
      name: 'unit_segment_id',
      child: unitSegments,
      type: 'select',
      label: 'Unit Segmen',
    },
  ];

  const submitValidation = useCallback((obj) => {
    let inValid = true;
    for (var key in obj) {
      if (obj[key] !== '') {
        inValid = false;
      }
    }
    return inValid;
  }, []);

  const changeActive = (idx) => {
    setTab((prev) =>
      prev.map((el, i) => ({
        ...el,
        active: i === idx,
      }))
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getLocation = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/location',
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data.data);
      setLocaitons(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };
  const getDataUnitMaker = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/unit-maker',
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data.data);
      setUnitMakers(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataUnitModel = async () => {
    const params = {
      unit_maker_id: filter.unit_maker_id,
    };
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/unit-model',
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data);
      setUnitModels(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataUnitModelType = async () => {
    const params = {
      unit_maker_id: filter.unit_model_id,
    };
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/unit-model-type',
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data);
      setUnitModelTypes(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getUnitSegment = async () => {
    const params = {
      unit_type_id: 1,
    };
    const response = await withoutAuth.getDataWithoutAuth(
      '/api/unit-segment',
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data);
      setUnitSegments(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getUnitType = async () => {
    const response = await withoutAuth.getDataWithoutAuth('/api/unit-type', {});
    if (response?.status === 200) {
      const dataUnitType = response.data.data.data;
      var data_tab = dataUnitType.map((row) => ({
        id: row.id,
        title: row.name,
        icon: row.icon_name,
        active: false,
      }));
      setTab(data_tab);
      setUnitType(dataUnitType);
      setSelectedUnitType(dataUnitType[0].id);
      changeActive(0);
      setIsLoading(false);
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    const unit_type = unitType.find(
      (unit_type) => unit_type.id === parseInt(selectedUnitType)
    );

    if (typeof unit_type !== undefined) {
      setTags(unit_type?.tags?.filter((el) => el.is_active === 1));
      // console.log("Unit Type", unit_type?.tags)
      // unit_type?.tags.map((tag, index) => (
      //     console.log(tag)
      // ))
    }
  }, [selectedUnitType]);

  useEffect(() => {
    // getLocation();
    // getDataUnitMaker();
    // getUnitSegment();
    getUnitType();
  }, []);

  useEffect(() => {
    if (filter.unit_maker_id !== '') {
      getDataUnitModel();
    }
    setFilter((prev) => ({
      ...prev,
      unit_model_id: '',
      unit_model_type_id: '',
    }));
  }, [filter.unit_maker_id]);

  useEffect(() => {
    if (filter.unit_model_id !== '') {
      getDataUnitModelType();
    }
    setFilter((prev) => ({
      ...prev,
      unit_model_type_id: '',
    }));
  }, [filter.unit_model_id]);

  const handleSearch = () => {
    const activeTab = tab.find((el) => el.active === true);
    push('/unit-lelang', {
      dataFilter: {
        ...filter,
        unit_type_id: activeTab.id,
      },
    });
  };

  const changeTab = (id, idx) => {
    changeActive(idx);
    setSelectedUnitType(id);
  };

  const handleFilterTag = (value) => {
    push('/unit-lelang', {
      dataFilter: { tag_id: value.toString(), unit_type_id: selectedUnitType },
    });
  };

  const handlePriceTag = (value) => {
    push('/unit-lelang', {
      dataFilter: {
        ...value,
        unit_type_id: selectedUnitType,
      },
    });
  };

  return (
    <div
      className='container mb-4 container-home-filter'
      style={{ position: 'relative' }}
    >
      <Row className='d-flex'>
        <Col md={9} className='mb-3 home-filter-no-padding'>
          <div className='home-filter border-end p-3 d-flex flex-column mb-2'>
            {/* tab */}
            <div className='fs-5 me-4 fw-bold'>
              {isLoading ? <SkeletonCustom width='40%' /> : 'Unit Lelang'}
            </div>
            <div className='border-bottom d-flex tab-tipe-lelang'>
              {isLoading ? (
                <div className='pb-2 w-25'>
                  <SkeletonCustom height='30px' />
                </div>
              ) : (
                tab.map(({ id, title, active, icon }, idx) => (
                  <div
                    className={`p-3 d-flex cursor-pointer align-items-center fw-semibold hover-primary ${
                      active
                        ? 'text-primary border-bottom border-primary border-2'
                        : 'text-dark'
                    }`}
                    style={{ gap: '10px' }}
                    onClick={() => {
                      changeActive(idx);
                      setSelectedUnitType(id);
                    }}
                    key={idx}
                  >
                    <span className={'mdi ' + icon}></span>
                    {title}
                  </div>
                ))
              )}
            </div>
            <div className='d-flex d-md-none d-lg-none mt-2'>
              <div className='position-relative w-100'>
                <button
                  className='form-select w-100 d-block'
                  onClick={() => setIsOpen(!isOpen)}
                  onBlur={() =>
                    setTimeout(() => {
                      setIsOpen(false);
                    }, 200)
                  }
                >
                  <span className={`me-2 mdi ${selectedTab?.icon}`}></span>
                  {selectedTab?.title}
                </button>
                <CustomSelect
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  tab={tab}
                  onClick={changeTab}
                />
              </div>

              {/* <Form.Select
                                onChange={(e) => { setSelectedUnitType(e.target.value) }}
                            >
                                {tab.map(({ id, title, active, icon }, idx) => (
                                    <option key={idx} value={id}>
                                        {title}
                                    </option>
                                ))
                                }
                            </Form.Select> */}
            </div>

            {/* search bar */}
            {isLoading ? (
              <SkeletonCustom height='35px' className='my-3' />
            ) : (
              <div className='my-3'>
                <SearchField
                  placeholder='Cari mobil berdasarkan Merek, Model, atau Kata Kunci'
                  value={filter.search}
                  name='search'
                  onChange={handleChange}
                />
              </div>
            )}

            {/* content */}
            <div className='h-100'>
              <div
                className='d-flex flex-column align-items-stretch h-100'
                style={{ gap: '10px' }}
              >
                {/* merk bar */}
                {/*!(tab[2].active) && (
                                        <div className='row'>
                                            {
                                                merkBar.map((el, idx) => (
                                                    
                                                ))
                                            }
    
                                            {
                                                merkBars.map((el, idx) => (
                                                    <div className='col-2' key={idx}>
                                                        {
                                                            el.type === 'select' ? (
                                                                <Form.Select
                                                                    style={{ fontSize: "14px", lineHeight: "20px", color: "#092540", width: "100%" }}
                                                                    placeholder={el.name}
                                                                    title={el}
                                                                    required
                                                                    name={el.name}
                                                                    value={filter[el.name]}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">{el.label}</option>
                                                                    {
                                                                        el.child.map((a, b) => (
                                                                            <option key={b} value={a.id}>{a.name}</option>
                                                                        ))
                                                                    }
                                                                </Form.Select>
                                                            ) : (
                                                                <input
                                                                    type="number"
                                                                    name={el.name}
                                                                    value={filter[el.name]}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder={el.label}
                                                                    aria-label="keyword"
                                                                    aria-describedby="basic-addon1"
                                                                    style={{ borderLeft: 0 }}
                                                                />
                                                            )
                                                        }
    
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )*/}
                {submitValidation(filter) ? (
                  ''
                ) : (
                  <div className='d-flex align-items-center justify-content-between font-sm'>
                    <div
                      className='text-primary fw-semibold cursor-pointer'
                      onClick={() => setFilter(initialFilter)}
                    >
                      Reset pencarian
                    </div>
                    <div>
                      <PrimaryButton className='w-100' onClick={handleSearch}>
                        Cari
                        <ArrowRightSearch className='text-light ms-2' />
                      </PrimaryButton>
                    </div>
                  </div>
                )}
                {/* Tab bar */}
                <div
                  className='flex-grow-1'
                  style={{
                    display: 'flex',
                    overflowX: 'auto',
                    gap: '12px',
                    paddingBottom: '10px',
                  }}
                >
                  {isLoading
                    ? new Array(4).fill(1).map((item, index) => (
                        <div className='col-md-3 h-100' key={index}>
                          <SkeletonImage />
                        </div>
                      ))
                    : tags
                    ? tags.map((tag, index) => (
                        <div
                          className='col-md-3 h-100 cursor-pointer'
                          key={index}
                          onClick={() => handleFilterTag(`${tag.id}`)}
                        >
                          <div
                            className='bg-grey rounded-custom h-100 card-unit-tag'
                            key={index}
                          >
                            <div className='fw-semibold p-3 pb-4'>
                              {tag?.name || ''}
                            </div>
                            <Image src={tag?.logo_file_url} />
                          </div>
                        </div>
                      ))
                    : ''}
                </div>

                {/* price bar */}
                <div
                  className='d-flex home-filter-price'
                  style={{ gap: '10px', paddingBottom: '10px' }}
                >
                  {isLoading
                    ? new Array(5).fill(1).map((el, i) => (
                        <div
                          className='flex-grow-1 rounded-custom tag-price'
                          key={i}
                        >
                          <SkeletonCustom />
                        </div>
                      ))
                    : hargaBar.map((el, idx) => (
                        <div
                          className='flex-grow-1 border rounded-custom font-sm px-2 py-1 text-center cursor-pointer tag-price'
                          key={idx}
                          onClick={() => handlePriceTag(el.filter)}
                        >
                          {el.label}
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} className='mb-3 home-filter-titip-lelang-no-padding'>
          <div className='home-filter-titip-lelang p-3'>
            <div className='fs-5 me-4 fw-bold'>
              {isLoading ? <SkeletonCustom width='40%' /> : 'Titip Lelang'}
            </div>
            <div className='d-flex flex-column my-2'>
              {isLoading
                ? new Array(3)
                    .fill(1)
                    .map((el, i) => (
                      <SkeletonCustom
                        height='30px'
                        width='55%'
                        className='mb-3'
                        key={i}
                      />
                    ))
                : iconData.map((el, idx) => (
                    <div key={idx}>
                      <IconPreview label={el.title} icon={el.icon} />
                    </div>
                  ))}
            </div>
            {isLoading ? (
              <>
                <SkeletonCustom height='30px' />
                <div className='mt-3'>
                  <SkeletonCustom count={2} />
                </div>
              </>
            ) : (
              <>
                <PrimaryButton
                  className='w-100'
                  onClick={() => push('/titip-lelang')}
                >
                  Titip Lelang
                </PrimaryButton>
                <div className='text-secondary mt-3 font-xs'>
                  Dengan melanjutkan, saya setuju dengan
                  <br />
                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => handleClick('/kebijakan-privasi')}
                  >
                    Kebijakan Privasi
                  </span>{' '}
                  &{' '}
                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => handleClick('/syarat-dan-ketentuan')}
                  >
                    Ketentuan Pengguna
                  </span>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

const style = {
  iconContainer: {
    width: '35px',
    height: '35px',
    backgroundColor: '#F9E6E6',
  },
};

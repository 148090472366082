import React, { useState } from 'react'
import { Col, Container, Row, Form, FormControl, Button } from 'react-bootstrap'
import Time from '../../assets/icons/Time'
import Location from '../../assets/icons/Location'
import SingleUnit from '../../assets/images/single-unit.png'
import CarouselLayout from '../../containers/Layouts/CarouselLayout/CarouselLayout'
import { bca } from "../../assets/images/beliNIPLicon";
import Payment from '../../components/Modals/Payment'
import ModalLayout from '../../containers/Layouts/ModalLayout/ModalLayout'

export default function Checkout() {

    const [isOpenPayment, setIsOpenPayment] = useState(false);

    const openModalPayment = () => {
        setIsOpenPayment(true);
    }

    const closeModalPayment = () => {
        setIsOpenPayment(false);
    }

    return (
        <>
            <div className="page w-100 h-100">
                <section>
                    <Container>
                        <Row>
                            <Col md={{ span: 8 }}>
                                <div className="box-border mt-3 mb-3">
                                    <div className='row data-invoice justify-content-between'>
                                        <div className="col-md-6 mb-2">
                                            <span className="title">No.Invoice</span><br />
                                            <span className='value'>INV/201203912410294/CAR/3810912314</span>
                                        </div>
                                        <div className="col-md-3 mb-2">
                                            <span className="title">Tanggal Pembelian</span><br />
                                            <span className='value'>01 Feb 2022, 09:30 WIB</span>
                                        </div>
                                        <div className="col-md-3 mb-2">
                                            <span className="title">Status</span><br />
                                            <span className='value'><span className='badge-status warning'>Menunggu Pembayaran</span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='box-border mb-3'>
                                    <Row>
                                        <Col className='mb-2' md={{ span: 6 }}>
                                            <CarouselLayout>
                                                <div>
                                                    <img src={SingleUnit} alt="Unit 1" />
                                                </div>
                                                <div>
                                                    <img src={SingleUnit} alt="Unit 2" />
                                                </div>
                                                <div>
                                                    <img src={SingleUnit} alt="Unit 3" />
                                                </div>
                                                <div>
                                                    <img src={SingleUnit} alt="Unit 4" />
                                                </div>
                                                <div>
                                                    <img src={SingleUnit} alt="Unit 5" />
                                                </div>
                                            </CarouselLayout>
                                        </Col>
                                        <Col className='mb-2' md={{ span: 6 }}>
                                            <div className='group justify-content-between'>
                                                <div>
                                                    <span className="nomor-lot">Lot A1</span>
                                                    <div className="unit-name">2019 DAIHATSU SIGRA 1.0 M</div>
                                                </div>
                                                <div className="grade-unit-detail">
                                                    Grade<br />
                                                    <span className='grade-detail'>A</span>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="unit-title">Harga Final</span>
                                                <span className="unit-price">Rp 103.000.000</span>
                                                <div style={{ marginTop: "20px" }}>
                                                    <a className='fw-semibold no-underline'>Lihat Detail Unit</a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='box-lelang mb-3'>
                                    <div className='row'>
                                        <div className='col-md-6' style={{ paddingLeft: "20px", paddingTop: "10px", paddingRight: "20px" }}>
                                            <span className='lelang-title'>LELANG BEKASI</span>
                                            <div className="lelang-date" style={{ color: "#1E1E1F" }}>12 Feb 2021</div>
                                        </div>
                                        <div className='col-md-6' style={{ paddingLeft: "20px", paddingTop: "10px", paddingRight: "20px" }}>
                                            <div className="lelang-time"><Time /> <span>11:00 - 23:00 WIB</span></div>
                                            <div className="lelang-location"><Location /> <span>Jl. Raya Narogong No. 37</span></div>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                            <Col md={{ span: 4 }}>
                                <div className="box-border summary-transaction mb-3" >
                                    <div className='title'>Ringkasan</div>
                                    <div>
                                        <div className='amount-item'>
                                            <div className="d-flex justify-content-between mb-1">
                                                <div className='item-name'>Harga Terbentuk Lelang</div>
                                                <div className='item-amount'>Rp 103.000.000</div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-1">
                                                <div className='item-name'>Deposit Lelang</div>
                                                <div className='item-amount'>(Rp 5.000.000)</div>
                                            </div>
                                            <div className="d-flex justify-content-between border-bottom-amount">
                                                <div className='item-name'>Biaya Admin</div>
                                                <div className='item-amount'>Rp 1.750.000</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className='item-name'>Total Bayar</div>
                                                <div className='item-amount'>Rp 84.750.000</div>
                                            </div>
                                        </div>
                                        <div className='mt-20'>
                                            <div className='summary-name'>Total Tagihan</div>
                                            <div className='summary-amount'>Rp 84.750.000</div>
                                        </div>
                                        <div className='mt-20'>
                                            <Form className="form-caready">
                                                <Form.Group className="form-group" controlId="formBasicEmail">
                                                    <Form.Label>Metode Pembayaran</Form.Label>
                                                    <Form.Select
                                                        placeholder="Metode Pembayaran"
                                                    >
                                                        <option value=""><img src={bca} alt="bca" /> BCA Virtual Account</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button className="button-login mb-3" style={{ display: "block !important", width: "100%" }} variant="primary" type="button" onClick={openModalPayment}>
                                                    Bayar
                                                </Button>
                                            </Form>
                                        </div>
                                        <div className='summary-note mt-20'>
                                            Pemenang lelang yang tidak melunasi pembayaran sesuai tenggat waktu yang telah ditetapkan akan dinyatakan wanprestassi (kemenangan batal). Uang jamian pun otomatis hangus.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <ModalLayout
                show={isOpenPayment}
                handleClose={closeModalPayment}
                title={"Menunggu Pembayaran"}
                className=""
            >
                <Payment />
            </ModalLayout>
        </>
    )
}

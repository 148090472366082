import axios from 'axios'
import { store } from '../config/store';
import { actionsAuth } from '../redux/auth.slice';

const API = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_URL,
  // timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

// For POST requests
API.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  (err) => {
    const { status } = err.response;
    if (status === 401) {
      store.dispatch(actionsAuth.logout({}))
      localStorage.clear();
    }
    return Promise.reject(err);
  }
);

export default API;




import { createSlice } from '@reduxjs/toolkit'
// Slice
const pageSlice = createSlice({
  name: 'page',
  initialState: {
    page: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload.page;
    }
  },
});

export const pageSelector = state => state.pageReducer.page;

export const { actions: actionsPage, reducer } = pageSlice;
import InfoSignUp from "../../components/InfoSignUp/InfoSignUp";
import { Link } from "react-router-dom";
import {
    label1,
    snk1,
    snk2,
    snk3,
    snk4,
    snk5,
    snk6,
    snk7
} from "./data";
import snkImage from '../../assets/images/kp.webp';

const KebijakanPrivasi = () => {
    return (
        <div>
            <div
                className="text-white fw-bold"
                style={{ height: '395px', fontSize: '3rem', backgroundImage: `url('${snkImage}')` }}
            >
                <div className="d-flex flex-center h-100 w-100" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    Kebijakan Privasi
                </div>
            </div>
            <section className="py-5">
                <div className="container snk" style={{ fontSize: '1.25rem' }}>
                    <div>
                        {label1}
                    </div>
                    <div className="fw-bold">Kebijakan Privasi (Privacy Policy)</div>
                    <div>{snk1}</div>
                    <div>{snk2}</div>
                    <div>{snk3}</div>
                    <div>
                        <div>Pengguna mempunyai hak dan wewenang dari waktu ke waktu untuk:</div>
                        <div className="ps-4">
                            <ol type="a">
                                {snk4.map((el, idx) => (
                                    <li key={`snk-${idx}`}>{el}</li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    <div>{snk5}</div>
                    <div>{snk6}</div>
                    <div>{snk7}</div>
                    <div>
                        Untuk mengakses Syarat dan Ketentuan kami, anda dapat mengunjungi laman website kami di <Link to='/syarat-dan-ketentuan'>https://caready.co.id/syarat-dan-ketentuan</Link>.
                    </div>
                </div>
            </section>
            <InfoSignUp />
        </div>
    )
}

export default KebijakanPrivasi;
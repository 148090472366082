export default function SpecialNIPL({ className }) {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.4987 2.33268V14.6002C17.4988 14.681 17.4754 14.7602 17.4313 14.8279C17.3872 14.8957 17.3243 14.9492 17.2504 14.9819L9.9987 18.191L2.74703 14.9827C2.67281 14.9499 2.60977 14.8962 2.56566 14.8281C2.52155 14.76 2.49827 14.6805 2.4987 14.5993V2.33268H0.832031V0.666016H19.1654V2.33268H17.4987ZM6.66536 8.99935V10.666H13.332V8.99935H6.66536ZM6.66536 5.66602V7.33268H13.332V5.66602H6.66536Z'
        fill='white'
      />
    </svg>
  );
}

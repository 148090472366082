import { format } from 'date-fns';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// import ArrowRight from "../../assets/icons/ArrowRight";
import Notification from '../../assets/icons/Notification';
import withAuthProvider from '../../services/withAuthProvider';
import { SkeletonNotification } from '../Skeleton/Skeleton';

const dat = [1, 1, 1];

export const DropdownNotification = () => {
  const { push } = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getNotifications = async () => {
    setIsLoading(true);
    const params = {
      page: 1,
      page_size: 10,
    };
    const response = await withAuthProvider.getDataWithAuth(
      `api/notification`,
      params
    );
    if (response?.status === 200) {
      const { data } = response.data.data;
      setNotifications(data);
    } else {
      // console.log(response.response);
    }
  };

  const seenNotification = async () => {
    const body = {};
    // const response = await withAuthProvider.postDataWithAuth(`/api/notification/seen/${id}`, body);
  };

  const clickSeenAll = async () => {
    push('/pengaturan/notifikasi');
    // const body = {}
    // const response = await withAuthProvider.postDataWithAuth(`/api/notification/seen-all`, body);
  };

  const markAsRead = async () => {
    const body = {};
    const response = await withAuthProvider.postDataWithAuth(
      `/api/notification/seen-all`,
      body
    );
  };

  const clickSeen = (notification) => {
    console.log(notification);
  };

  const toggled = () => {
    getNotifications().finally(() => setIsLoading(false));
    markAsRead();
  };

  return (
    <Dropdown align='end' onToggle={toggled}>
      <Dropdown.Toggle
        id='dropdown-basic'
        variant='light'
        className='disable-caret  cursor-pointer hover-primary bg-header'
      >
        <div className='px-md-3 px-2 py-2 d-flex mx-1'>
          <Notification />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: '324px', height: '400px' }}>
        <div className='d-flex justify-content-between fw-bold border-bottom border-1 font-sm px-3 pb-2'>
          <div>Notifikasi</div>
          <div className='cursor-pointer' onClick={clickSeenAll}>
            Lihat selengkapnya
          </div>
        </div>
        <div className='overflow-auto' style={{ height: '345px' }}>
          {isLoading
            ? dat.map((el, idx) => (
                <div className='px-3 py-2 border-bottom font-sm' key={idx}>
                  <SkeletonNotification />
                </div>
              ))
            : notifications.map((notification, b) => (
                <div
                  className='px-3 py-2 border-bottom font-sm'
                  key={b}
                  onClick={() => clickSeenAll(notification)}
                  style={{
                    backgroundColor:
                      notification?.seen_at === null
                        ? 'rgba(207,23,1,0.05)'
                        : '#FFF',
                  }}
                >
                  <div className='d-flex'>
                    <div style={{ width: '30px' }}>
                      <div
                        className='d-inline-block bg-primary rounded-circle me-2'
                        style={{ width: '20px', height: '20px' }}
                      >
                        <div className='d-flex justify-content-center align-items-center h-100'>
                          <Icon />
                        </div>
                      </div>
                    </div>
                    <div className='d-inline'>
                      <div className='text-dark fw-bold'>
                        {notification?.type || '-'}
                      </div>
                      <div className='text-secondary'>
                        {notification?.message || '-'}
                      </div>
                      <div className='text-secondary font-xs'>
                        {format(
                          new Date(notification?.created_at),
                          'dd MMM yyyy, HH:mm'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          {notifications.length === 0 && isLoading === false && (
            <div className='px-3 py-2 font-sm text-center text-secondary w-100'>
              Tidak ada notifikasi
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Icon = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 8.5H11V9.5H1V8.5H2V5C2 3.93913 2.42143 2.92172 3.17157 2.17157C3.92172 1.42143 4.93913 1 6 1C7.06087 1 8.07828 1.42143 8.82843 2.17157C9.57857 2.92172 10 3.93913 10 5V8.5ZM4.5 10.5H7.5V11.5H4.5V10.5Z'
      fill='white'
    />
  </svg>
);

import React from 'react';
import Footers from '../../Footers/Footers';
import FooterUnit from '../../Footers/FooterUnit';
import Headers from '../../Headers/Headers';
// import Helper from '../helper';

const DetailUnitLayout = (props) => {
    const { children } = props;

    return (
        <>
            <div className="position-relative main-container" >
                <Headers />
                <div className="unit-image h-100 w-100 main-content" style={{ minHeight: 'calc(100vh - 4rem)' }}>
                    {children}
                </div>
                <Footers />
            </div>
            <FooterUnit />
            {/* <Helper /> */}
        </>
    )
}

export default DetailUnitLayout;
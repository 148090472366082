import ArrowRight from "../../assets/icons/ArrowRight";
import { infoData } from "../../pages/Home/homeData";
import { CardInfo } from "../Cards/CardInfo";
import { SkeletonBlog } from "../Skeleton/Skeleton";

export default function InfoCaready(props) {
    const { data, loading, push } = props;

    const seeAll = () => push('/info-caready');

    const seeDetail = (slug) => push(`/info-caready-detail/${slug}`);

    return (
        <section className="py-4">
            <div className="container">
                <div className="d-flex">
                    <div className="fs-5 me-4 fw-bold">Info Caready</div>
                    <div className="hover-primary font-sm d-flex align-items-center cursor-pointer" onClick={seeAll}>
                        Lihat Semua
                        <ArrowRight className='hover-primary ms-2' />
                    </div>
                </div>
                <div className="my-3 row">
                    {
                        loading ?
                            new Array(3).fill(1).map((el, idx) => (
                                <div className="col-md-4" key={idx}>
                                    <SkeletonBlog />
                                </div>
                            ))
                            : data.map((info, idx) => (
                                <div className="col-md-4 cursor-pointer mb-5" key={idx} onClick={() => seeDetail(info.slug)}>
                                    <CardInfo
                                        title={info?.title}
                                        image={info?.image_url}
                                        badges={info?.tags || []}
                                        date={info?.created_at}
                                    />
                                </div>
                            ))
                    }
                </div>
            </div>
        </section>
    )
}
import { Search } from "../../assets/icons/Search";

const SearchField = ({
    placeholder,
    value,
    name,
    onChange
}) => {
    return (
        <div className="input-group rounded-custom">
            <span className="input-group-text" id="basic-addon1" style={{ borderRight: 0, backgroundColor: 'transparent' }}>
                <Search className='text-secondary' />
            </span>
            <input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                className="form-control"
                placeholder={placeholder}
                aria-label="keyword"
                aria-describedby="basic-addon1"
                style={{ borderLeft: 0 }}
            />
        </div>
    )
}

export default SearchField;
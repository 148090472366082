const label1 = `PT Caready (disebut juga sebagai "Pengelola") ingin mengucapkan terima kasih telah mempercayakan penjualan mobil anda di Caready Indonesia. Sebelum anda mengirimkan data-data mobil anda kepada kami, kami ingin anda membaca dan menyetujui Syarat-syarat dan Ketentuan-ketentuan dibawah ini:`
const snk1 = `Saya, (disebut juga sebagai "Pengguna") dengan ini setuju untuk memberikan Pengelola dan/atau pihak terkait lainnya ijin untuk menggunakan data-data saya seperti: nama, kontak, alamat email, dan lain-lain (selanjutnya disebut juga "Data Pribadi") dengan ketentuan yang akan disebutkan dibawah ini:`;

const snk2 = `Data Pribadi akan digunakan oleh Pengelola guna menghubungkan Pengguna dengan dealer-dealer mobil rekanan Pengelola melalui media komunikasi seperti: email, surat tercatat, SMS dan/atau telepon.`;

const snk3 = `Tidak menutup kemungkinan Pengelola akan menggunakan jasa dari pihak ketiga untuk memproses Data Pribadi milik para Pengguna tersebut. Perlu diketahui juga bahwa pihak ketiga yang dimaksud wajib memenuhi peraturan yang telah dituangkan ke dalam kontrak dengan Pengelola dimana penggunaan Data Pribadi dari Pengguna tidak akan digunakan disalahgunakan untuk hal-hal lain selain yang tertuang di dalam kontrak tersebut.`;

const snk4 = [
    `Mencabut izin penggunaan Data Pribadi;`,
    `Meminta perubahan dan/atau pengkinian atas Data Pribadi;`,
    `Menanyakan hal-hal sehubungan penggunaan Data Pribadi-nya dengan menghubungi Pengelola di: notification@caready.co.id`,
];

const snk5 = `Pengelola wajib mengambil langkah-langkah yang terbaik untuk menjaga keutuhan dan keamanan Data Pribadi dari Pengguna, dan juga meminta kepada pihak ketiga untuk menjaga kepentingan tersebut.`;

const snk6 = `Pengelola dengan ini juga memberikan jaminan kepada Pengguna bahwa Data Pribadi akan dihilangkan atau dihapus secara permanen apabila tidak lagi dibutuhkan oleh Pengelola.`;

const snk7 = `Pengelola akan mengikuti regulasi serta peraturan perundang-undangan yang berlaku dalam menjaga keutuhan Data Pribadi, akan tetapi apabila regulasi dan peraturan perundang-undangan tersebut mewajibkan Pengelola untuk memberikan Data Pribadi kepada pihak yang berwenang, maka Pengelola akan memberikan Data Pribadi tersebut sesuai dengan permintaan dari pihak yang berwenang tersebut.`;

export {
    label1,
    snk1,
    snk2,
    snk3,
    snk4,
    snk5,
    snk6,
    snk7,
}
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
// import SingleUnit from '../../assets/images/single-unit.png';
import ProxyBid from "../../components/Modals/ProxyBid";
import Auth from "../../components/Modals/Auth";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import Share from "../../components/Modals/Share";
import { useDispatch, useSelector } from "react-redux";
import {
  actionsUnit,
  unitSelector,
  loadingSelector,
} from "../../redux/unit.slice";
import { isLoggedSelector } from "../../redux/auth.slice";
import { useHistory } from "react-router-dom";
import withAuth from "../../services/withAuthProvider";
import withoutAuth from "../../services/withoutAuthProvider";
import toast from "react-hot-toast";
// import useOutsideClick from '../../utils/useOutsideClick';
import {
  SkeletonCustom,
  SkeletonImage,
} from "../../components/Skeleton/Skeleton";
import QuestionMark from "../../assets/icons/QuestionMark";

const defaultImage =
  "https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true";

export default function FooterUnit() {
  const [isOpenProxyBid, setIsOpenProxyBid] = useState(false);
  // const [isOpenAuth, setIsOpenAuth] = useState(false);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [isActiveProxyBid, setIsActiveProxyBid] = useState(true);
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);
  const unit = useSelector(unitSelector);
  const loading = useSelector(loadingSelector);
  const isLogged = useSelector(isLoggedSelector);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  // console.log("unit", unit);

  const intervalRef = useRef(null);
  const ref = useRef();

  // useOutsideClick(ref, () => isOpen && setIsOpen(false), true);

  const handleClick = (path) => {
    push(path);
  };

  const openModalProxyBid = () => {
    if (countTimeProxyBid() === true) setIsOpenProxyBid(true);
  };

  const closeModalProxyBid = () => {
    setIsOpenProxyBid(false);
  };

  // const openModalAuth = () => {
  //   setIsOpenAuth(true);
  // };
  //
  // const closeModalAuth = () => {
  //   setIsOpenAuth(false);
  // };

  const openModalShare = () => {
    setIsOpenShare(true);
  };

  const closeModalShare = () => {
    setIsOpenShare(false);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      countTimeProxyBid();
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [unit]);

  function clearTimer() {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }

  const countTimeProxyBid = () => {
    if (unit)
      if (unit.auction) {
        let nowDate = [
          new Date().getFullYear().toString(),
          (new Date().getMonth() + 1).toString(),
          new Date().getDate().toString(),
        ];
        const startDate = unit.auction.date_start.split("-");

        if (parseInt(nowDate[0]) > parseInt(startDate[0])) return clear();
        if (parseInt(nowDate[1]) > parseInt(startDate[1])) return clear();
        if (parseInt(nowDate[2]) > parseInt(startDate[2])) return clear();

        const startTime = unit.auction.time_start;
        let nowTime = new Date().toLocaleTimeString("id-ID");
        let secondStart = hmsToSeconds(startTime);
        let secondNow = hmsToSeconds(nowTime, ".");

        let diff = secondStart - secondNow;
        if (diff < 2800) return clear();

        setIsActiveProxyBid(true);
        return true;
      }

    function clear() {
      setIsActiveProxyBid(false);
      clearTimer();
      return false;
    }
    function hmsToSeconds(s, split = ":") {
      const b = s.split(split);
      return b[0] * 3600 + b[1] * 60 + (+b[2] || 0);
    }
  };

  const getUnit = async () => {
    const imageURL = [];
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/unit/" + unit.id,
      {}
    );
    if (response?.status === 200) {
      const dataUnit = response.data.data;
      if (dataUnit.pictures.length > 0) {
        dataUnit.pictures.map((image, i) =>
          imageURL.push({ image: image.image_url })
        );
        // setImages(image_url)
      }
      // setUnit(dataUnit)
      dispatch(actionsUnit.setUnit({ unit: dataUnit, images: imageURL }));
    } else {
      toast.error(response.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const addFavorite = async () => {
    setIsLoadingFavorite(true);
    const loading = toast.loading("Mengirimkan...");
    const response = await withAuth.postDataWithAuth("/api/favorite/add", {
      stock_unit_id: unit.id,
    });
    if (response?.status === 200) {
      toast.dismiss(loading);
      toast.success(response.data.message, {
        id: "success-message",
        duration: 3000,
      });
      getUnit();
    } else {
      toast.dismiss(loading);
      if (
        typeof response.data.message === "object" &&
        response.data.message !== null &&
        !Array.isArray(response.data.message)
      ) {
        var data_message = "";
        for (var key in response.data.message) {
          if (response.data.message.hasOwnProperty(key)) {
            data_message += response.data.message[key];
          }
        }
        toast.error(data_message, {
          id: "error-message",
          duration: 3000,
        });
      } else {
        toast.error(response.data.message, {
          id: "error-message",
          duration: 3000,
        });
      }
    }
    setIsLoadingFavorite(false);
  };

  const deleteFavorite = async () => {
    setIsLoadingFavorite(true);

    const loading = toast.loading("Mengirimkan...");
    const response = await withAuth.deleteDataWithAuth(
      `/api/favorite/${unit.id}/delete`
    );
    if (response?.status === 200) {
      toast.dismiss(loading);
      toast.success(response.data.message, {
        id: "success-message",
        duration: 3000,
      });
      getUnit();
    } else {
      toast.dismiss(loading);
      if (
        typeof response.data.message === "object" &&
        response.data.message !== null &&
        !Array.isArray(response.data.message)
      ) {
        var data_message = "";
        for (var key in response.data.message) {
          if (response.data.message.hasOwnProperty(key)) {
            data_message += response.data.message[key];
          }
        }
        toast.error(data_message, {
          id: "error-message",
          duration: 3000,
        });
      } else {
        toast.error(response.data.message, {
          id: "error-message",
          duration: 3000,
        });
      }
    }
    setIsLoadingFavorite(false);
  };

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     <p className="mb-1">
  //       Dengan Proxy Bid Anda tidak perlu stand-by di depan layar untuk
  //       melakukan bidding.
  //     </p>
  //     <p>Coming soon.</p>
  //   </Tooltip>
  // );

  return (
    <>
      <div className="unit-footer position-fixed w-100">
        <div className="container">
          <Row>
            <Col md={5} className="d-none d-sm-block">
              <div
                className="unit-info"
                style={{ display: "flex", marginTop: "11px" }}
              >
                {loading ? (
                  <div className="unit-image">
                    <SkeletonImage />
                  </div>
                ) : (
                  <div
                    className="unit-image"
                    style={
                      unit.pictures?.length > 0
                        ? {
                            position: "relative",
                            float: "left",
                            background: `url("${unit.pictures[0].image_url}")`,
                          }
                        : {
                            position: "relative",
                            float: "left",
                            background: `url(${defaultImage})`,
                          }
                    }
                  ></div>
                )}
                <div style={{ position: "relative", float: "left" }}>
                  {loading ? (
                    <div style={{ width: "200px" }}>
                      <SkeletonCustom width="60%" />
                      <SkeletonCustom />
                    </div>
                  ) : (
                    <>
                      <span className="nomor-lot">
                        Lot{" "}
                        {unit?.lot_number && unit?.auction?.auction_lane_name
                          ? unit?.auction?.auction_lane_name + unit?.lot_number
                          : ""}
                      </span>
                      <span className="unit-name">
                        {unit?.unit_name ||
                          unit?.year +
                            " " +
                            unit?.unit_maker?.name +
                            " " +
                            unit?.unit_model?.name}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div
                className="d-none d-sm-block"
                style={{
                  position: "relative",
                  float: "right",
                  marginTop: "22px",
                }}
              >
                {loading ? (
                  <div className="d-flex pb-2 gap-2">
                    <SkeletonCustom
                      className="d-inline-block"
                      height="40px"
                      width="150px"
                    />
                    <SkeletonCustom
                      className="d-inline-block"
                      height="40px"
                      width="150px"
                    />
                    <SkeletonCustom
                      className="d-inline-block"
                      height="40px"
                      width="150px"
                    />
                    <SkeletonCustom
                      className="d-inline-block"
                      height="40px"
                      width="40px"
                    />
                    <SkeletonCustom
                      className="d-inline-block"
                      height="40px"
                      width="40px"
                    />
                  </div>
                ) : (
                  <>
                    <span
                      className={`button-footer-unit live-auction `}
                      style={{
                        backgroundColor: unit?.is_cancel_auction === "0" ? "" : "#F5F6F7",
                        color: unit?.is_cancel_auction === "1" ? "gray" : "",
                        cursor: unit?.is_cancel_auction === "1" ? "default" : "pointer"
                      }}
                      onClick={() => {
                        if(unit?.is_cancel_auction === "0") {
                          if (unit?.auction?.auction_category === "TIME-BID") {
                            console.log("timebid");
                            handleClick(
                                "/time-bid-auction/" +
                                `${unit?.auction?.id}/` +
                                unit?.id
                            );
                          } else {
                            console.log("gak");
                            handleClick("/live-auction/" + unit?.auction?.id);
                          }
                        }
                      }}
                    >
                      Live Auction
                    </span>
                    <a onClick={()=>{}} style={{ cursor: "default" }}>
                      {/* <a style={{ cursor: 'default' }}> */}
                      <span
                        className={`button-footer-unit position-relative proxy-bid text-secondary`}
                        style={{
                          backgroundColor: '#F5F6F7',
                          cursor: 'not-allowed',
                        }}
                      >
                        Proxy Bid
                        {/* <span className='ps-1'> </span>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <span className='position-absolute top-0 end-0 pe-1'>
                            <QuestionMark
                              className={`${
                                isActiveProxyBid ? '' : ' text-secondary'
                              }`}
                            />
                          </span>
                        </OverlayTrigger> */}
                      </span>
                    </a>
                    <a href="/beli-nipl">
                      <span className="button-footer-unit beli-nipl cursor-pointer">
                        Beli NIPL
                      </span>
                    </a>
                    {unit?.is_favorite === "false" ? (
                      <span
                        className="button-footer-unit-small cursor-pointer"
                        onClick={addFavorite}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.8335 11.6666V14.1666H18.3335V15.8333H15.8327L15.8335 18.3333H14.1669L14.166 15.8333H11.6669V14.1666H14.1669V11.6666H15.8335ZM16.8694 3.96415C18.7544 5.85415 18.8194 8.86415 17.066 10.8266L15.8827 9.64498C16.9919 8.37498 16.9335 6.38332 15.6894 5.14165C14.4369 3.89248 12.4227 3.83915 11.1144 5.01415L10.0019 6.01248L8.88852 5.01498C7.57602 3.83832 5.56269 3.88998 4.31019 5.14332C3.06852 6.38498 3.00602 8.37248 4.15019 9.68582L11.1769 16.7242L10.0002 17.9042L2.93352 10.8275C1.18019 8.86415 1.24602 5.84915 3.13019 3.96415C5.01769 2.07748 8.03685 2.01415 10.0002 3.77415C11.9577 2.01665 14.9827 2.07498 16.8685 3.96415H16.8694Z"
                            fill="#C20102"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span
                        className="button-footer-unit-small cursor-pointer"
                        onClick={deleteFavorite}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0009 3.77415C11.9584 2.01665 14.9834 2.07498 16.8693 3.96415C18.7543 5.85415 18.8193 8.86415 17.0659 10.8275L9.99925 17.9042L2.93425 10.8275C1.18092 8.86415 1.24675 5.84915 3.13092 3.96415C5.01842 2.07748 8.03759 2.01415 10.0009 3.77415Z"
                            fill="#C20102"
                          />
                        </svg>
                      </span>
                    )}
                    <a onClick={openModalShare} className="cursor-pointer">
                      <span className="button-footer-unit-small">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3133 14.3924L7.05492 12.0699C6.64617 12.4757 6.12643 12.7513 5.56125 12.8621C4.99606 12.9729 4.41072 12.9139 3.87903 12.6925C3.34733 12.4711 2.89308 12.0973 2.57355 11.6181C2.25401 11.1389 2.0835 10.5759 2.0835 9.99993C2.0835 9.42399 2.25401 8.86093 2.57355 8.38176C2.89308 7.90258 3.34733 7.52873 3.87903 7.30735C4.41072 7.08596 4.99606 7.02694 5.56125 7.13774C6.12643 7.24853 6.64617 7.52418 7.05492 7.92993L11.3133 5.60743C11.1672 4.92221 11.2727 4.20731 11.6104 3.59347C11.9481 2.97963 12.4955 2.50787 13.1525 2.26446C13.8095 2.02104 14.5321 2.02224 15.1883 2.26783C15.8444 2.51343 16.3902 2.987 16.7259 3.60196C17.0616 4.21692 17.1647 4.93216 17.0164 5.61689C16.8681 6.30162 16.4782 6.91009 15.9182 7.33101C15.3581 7.75194 14.6652 7.9572 13.9663 7.90926C13.2673 7.86132 12.6089 7.56338 12.1116 7.06993L7.85326 9.39243C7.9382 9.79298 7.9382 10.2069 7.85326 10.6074L12.1116 12.9299C12.6089 12.4365 13.2673 12.1385 13.9663 12.0906C14.6652 12.0427 15.3581 12.2479 15.9182 12.6688C16.4782 13.0898 16.8681 13.6982 17.0164 14.383C17.1647 15.0677 17.0616 15.7829 16.7259 16.3979C16.3902 17.0129 15.8444 17.4864 15.1883 17.732C14.5321 17.9776 13.8095 17.9788 13.1525 17.7354C12.4955 17.492 11.9481 17.0202 11.6104 16.4064C11.2727 15.7925 11.1672 15.0776 11.3133 14.3924Z"
                            fill="#C20102"
                          />
                        </svg>
                      </span>
                    </a>
                  </>
                )}
              </div>
              <div className="group mobile-footer-unit">
                <div
                  className="flex-1 button-footer-unit live-auction"
                  style={{
                    backgroundColor: unit?.is_cancel_auction === "0" ? "" : "#F5F6F7",
                    color: unit?.is_cancel_auction === "1" ? "gray" : "",
                    cursor: unit?.is_cancel_auction === "1" ? "default" : "pointer"
                  }}
                  onClick={() => {
                    if(unit?.is_cancel_auction === "0") {
                      if (unit?.auction?.auction_category === "TIME-BID") {
                        console.log("timebid");
                        handleClick(
                            "/time-bid-auction/" +
                            `${unit?.auction?.id}/` +
                            unit?.id
                        );
                      } else {
                        console.log("gak");
                        handleClick("/live-auction/" + unit?.auction?.id);
                      }
                    }
                  }}
                >
                  Live Auction
                </div>
                <button
                  className="button-footer-unit more-fill"
                  style={{ opacity: isLoadingFavorite ? "0.5" : "1" }}
                  onClick={() => setIsOpen(!isOpen)}
                  onBlur={() =>
                    setTimeout(() => {
                      setIsOpen(false);
                    }, 200)
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16667 8.33325C3.25 8.33325 2.5 9.08325 2.5 9.99992C2.5 10.9166 3.25 11.6666 4.16667 11.6666C5.08333 11.6666 5.83333 10.9166 5.83333 9.99992C5.83333 9.08325 5.08333 8.33325 4.16667 8.33325ZM15.8333 8.33325C14.9167 8.33325 14.1667 9.08325 14.1667 9.99992C14.1667 10.9166 14.9167 11.6666 15.8333 11.6666C16.75 11.6666 17.5 10.9166 17.5 9.99992C17.5 9.08325 16.75 8.33325 15.8333 8.33325ZM10 8.33325C9.08333 8.33325 8.33333 9.08325 8.33333 9.99992C8.33333 10.9166 9.08333 11.6666 10 11.6666C10.9167 11.6666 11.6667 10.9166 11.6667 9.99992C11.6667 9.08325 10.9167 8.33325 10 8.33325Z"
                      fill="#1E1E1F"
                    />
                  </svg>
                </button>
              </div>
              <div
                ref={ref}
                className={`${isOpen ? "d-block" : "d-none"} more-fill-menu`}
              >
                {/* <div className="text-secondary" >Proxy Bid</div> */}
                <div
                  onClick={()=>{}}
                  style={{
                    backgroundColor: '#F5F6F7',
                    cursor: 'not-allowed',
                  }}
                  className={`text-secondary`}
                >
                  Proxy Bid
                </div>
                <div onClick={() => push("/beli-nipl")}>Beli NIPL</div>
                {unit?.is_favorite === "false" ? (
                  <div className=" cursor-pointer" onClick={addFavorite}>
                    Favorit
                  </div>
                ) : (
                  <div className=" cursor-pointer" onClick={deleteFavorite}>
                    Hapus Favorit
                  </div>
                )}
                <div onClick={openModalShare} className="cursor-pointer">
                  Bagikan
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <ModalLayout
        show={isOpenProxyBid}
        handleClose={closeModalProxyBid}
        title={isLogged === true ? "Proxy Bid" : "Masuk"}
        className="modal-detail-unit modal-transaction"
      >
        {isLogged === true ? (
          <ProxyBid handleClose={closeModalProxyBid} />
        ) : (
          <Auth />
        )}
      </ModalLayout>

      <ModalLayout
        show={isOpenShare}
        handleClose={closeModalShare}
        title={"Bagikan"}
        className="modal-detail-unit modal-transaction"
      >
        <Share shareUrl={window.location.origin + "/unit-lelang/" + unit.id} />
      </ModalLayout>
    </>
  );
}

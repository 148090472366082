import React from 'react'

export default function CardRibbon() {
    return (
        <div className="position-absolute" style={{
            paddingTop: "3px", paddingBottom: "3px", color: "#FFFFFF", background: "#CF1701", fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px", paddingLeft: "12px", paddingRight: "30px", borderTopLeftRadius: "8px", borderBottomRightRadius: "25px", marginTop:"-1px"
        }}>LIVE</div>
    )
}

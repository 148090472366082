export default function PPNIcon({ className }) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666667 0.333984H11.3333C11.5101 0.333984 11.6797 0.404222 11.8047 0.529246C11.9298 0.654271 12 0.82384 12 1.00065V13.0007C12 13.1775 11.9298 13.347 11.8047 13.4721C11.6797 13.5971 11.5101 13.6673 11.3333 13.6673H0.666667C0.489856 13.6673 0.320286 13.5971 0.195262 13.4721C0.0702379 13.347 0 13.1775 0 13.0007V1.00065C0 0.82384 0.0702379 0.654271 0.195262 0.529246C0.320286 0.404222 0.489856 0.333984 0.666667 0.333984ZM1.33333 1.66732V12.334H10.6667V1.66732H1.33333ZM2.66667 3.00065H9.33333V5.66732H2.66667V3.00065ZM2.66667 7.00065H4V8.33398H2.66667V7.00065ZM2.66667 9.66732H4V11.0007H2.66667V9.66732ZM5.33333 7.00065H6.66667V8.33398H5.33333V7.00065ZM5.33333 9.66732H6.66667V11.0007H5.33333V9.66732ZM8 7.00065H9.33333V11.0007H8V7.00065Z"
        fill="white"
      />
    </svg>
  );
}

import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { SelectInput } from "../../components/SelectInput/SelectInput";
import { car, laptop, motorcycle } from "../../assets/images/beliNIPLicon";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import ArrowRightSearch from "../../assets/icons/ArrowRightSearch";
import ModalLayout from "../../containers/Layouts/ModalLayout/ModalLayout";
import PembayaranBeliNIPL from "../../components/Modal/PembayaranBeliNIPL/PembayaranBeliNIPL";
import DetailPembelianNIPL from "../../components/Modal/PembayaranBeliNIPL/DetailPembelianNIPL";
import withoutAuth from "../../services/withoutAuthProvider";
import withAuth from "../../services/withAuthProvider";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  actionTransaction,
  transactionNIPLSelector,
} from "../../redux/transaction.slice";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const TemplateForm = ({ label, children, classNameLabel }) => {
  return (
    <div className="row py-2">
      <label
        className={`col-md-3 col-sm-12 fw-semibold font-bold form-label ${classNameLabel}`}
      >
        {label}
      </label>
      <div className="col-md-9 col-sm-12">{children}</div>
    </div>
  );
};

const tipeNIPL = [{ name: "Live", id: "Live" }];
const auctionCategory = [
  { name: "Time Bid", id: "TIME-BID" },
  { name: "Online Bid", id: "ONLINE" },
];
// const dateLelang = [{ name: '16 Feb 2022, 13:00', id: 1 }, { name: '17 Feb 2022, 13:00', id: 2 }, { name: '18 Feb 2022, 13:00', id: 3 }]

const initialTransaction = {
  unit_type_name: null,
  unit_type: null,
  nipl_type: null,
  location: null,
  location_name: null,
  auction: null,
  auction_detail: null,
  price: 0,
  quantity: 1,
  subtotal: 0,
  payment_method: null,
  service_fee: 0,
  grand_total: 0,
};

const BeliNIPL = () => {
  const [isOpen, setIsOpen] = useState(false);
  // tambahin redux namanya isPay untuk pembayaran, jadi gausah state
  const [isPay, setIsPay] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [unitType, setUnitType] = useState([]);
  const [location, setLocation] = useState([]);
  const [auction, setAuction] = useState([]);
  const [transaction, setTransaction] = useState({ ...initialTransaction });
  const [locationId, setLocationId] = useState(null);
  const [unitTypeId, setUnitTypeId] = useState(null);
  const [depo, setDepo] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [loadingForm, setLoadingForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const transaction_data = useSelector(transactionNIPLSelector);

  // form validation rules
  const validationSchema = Yup.object().shape({
    unit_type: Yup.string().required("Silahkan memilih Tipe Unit Lelang"),
    nipl_type: Yup.string().required("Silahkan memilih tipe NIPL"),
    location: Yup.string().required("Silahkan memilih Lokasi"),
    auction: Yup.string().required("Silahkan memilih Jadwal"),
    auction_category: Yup.string().required("Silahkan memilih Kategori Lelang"),
  });

  const defaultValues = {
    unit_type: "",
    nipl_type: "",
    location: "",
    auction: "",
    auction_category: "",
  };

  // functions to build form returned by useForm() hook
  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const openModal = (data) => {
    setIsOpen(true);
    dispatch(actionTransaction.setNIPL({ nipl: data }));
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsPay(false);
    resetForm();
  };

  // FETCH DATA
  const getUnitType = async () => {
    const response = await withoutAuth.getDataWithoutAuth("/api/unit-type", {});
    if (response?.status === 200) {
      const dataUnitType = response?.data.data.data;
      if (dataUnitType.length > 0) {
        setUnitTypeId(dataUnitType[0]?.id);
        setValue("unit_type", dataUnitType[0]?.id);
        setDepo(dataUnitType[0]?.deposit || 0);
      }
      setUnitType(dataUnitType);
      setIsLoading(false);
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const getAuction = async () => {
    const params = {
      location_id: parseInt(locationId),
      unit_type_id: parseInt(unitTypeId),
      auction_category: selectedCategory,
    };
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction",
      params
    );
    if (response?.status === 200) {
      const dataAuction = response?.data.data.data;
      const newData = dataAuction.map((row) => ({
        label:
          row.date_start && row.time_start
            ? format(
                new Date(`${row.date_start} ${row.time_start}`),
                "dd MMM yyyy, HH:mm"
              )
            : "-",
        value: row.id,
      }));
      setAuction(newData);
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const getLocation = async () => {
    const response = await withoutAuth.getDataWithoutAuth("/api/location", {});
    if (response?.status === 200) {
      const dataLocation = response?.data.data.data;
      setLocation(dataLocation);
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const sendTransaction = async () => {
    const dataTransaction = new FormData();
    dataTransaction.append("unit_type_id", transaction_data.unit_type);
    dataTransaction.append("type", transaction_data.nipl_type);
    dataTransaction.append("location_id", transaction_data.location);
    dataTransaction.append("auction_id", transaction_data.auction);
    dataTransaction.append("price", transaction_data.price);
    dataTransaction.append("qty", transaction_data.quantity);
    dataTransaction.append("sub_total", transaction_data.subtotal);
    dataTransaction.append("service_fee", transaction_data.service_fee);
    dataTransaction.append("grand_total", transaction_data.grand_total);
    dataTransaction.append(
      "payment_bank_code",
      transaction_data.payment_method
    );
    dataTransaction.append(
      "auction_category",
      transaction_data.auction_category
    );

    setLoadingForm(true);
    const loading = toast.loading("Mengirimkan...");
    const response = await withAuth.postDataWithAuth(
      "/api/nipl/transaction/add",
      dataTransaction
    );
    if (response?.status === 200) {
      toast.dismiss(loading);
      toast.success(response?.data.message, {
        id: "success-message",
        duration: 3000,
      });

      setLoadingForm(false);
      dispatch(actionTransaction.setNIPLInvoice({ data: response?.data.data }));
      setIsPay(true);
    } else {
      toast.dismiss(loading);
      if (
        typeof response?.data.message === "object" &&
        response?.data.message !== null &&
        !Array.isArray(response?.data.message)
      ) {
        var data_message = "";
        for (var key in response?.data.message) {
          if (response.data.message.hasOwnProperty(key)) {
            data_message += response?.data.message[key];
          }
        }
        toast.error(data_message, {
          id: "error-message",
          duration: 3000,
        });
      } else {
        if (
          (response?.data.message ===
            "Terjadi Perubahan Harga Biaya Layanan." ||
            response?.data.message === "Terjadi Perubahan Harga Deposit.") &&
          response?.status === 422
        ) {
          getUnitType();
        }
        toast.error(response?.data.message, {
          id: "error-message",
          duration: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (locationId !== null && unitTypeId !== null) {
      getAuction();
    }
  }, [unitTypeId, locationId, selectedCategory]);

  const resetForm = () => {
    setUnitTypeId(parseInt(unitType[0]?.id) || null);
    setQuantity(1);
    setDepo(unitType[0]?.deposit || 0);
    setAuction([]);
    reset(defaultValues);
    setTransaction({ ...initialTransaction });
    dispatch(actionTransaction.setNIPLInvoice({ data: [] }));
    dispatch(actionTransaction.setNIPL({ nipl: [] }));
  };

  useEffect(() => {
    getUnitType();
    getLocation();
  }, []);

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const unit_type_found = unitType.find(
      (unit_type) => unit_type.id === parseInt(data.unit_type)
    );
    const location_found = location.find(
      (loc) => loc.id === parseInt(data.location)
    );
    const auction_found = auction.find(
      (auction_data) => auction_data.value === parseInt(data.auction)
    );

    const newData = {
      unit_type_name: unit_type_found?.name,
      unit_type: unit_type_found?.id,
      nipl_type: data.nipl_type,
      location: location_found?.id,
      location_name: location_found.name,
      auction: auction_found.value,
      auction_detail: auction_found.label,
      price: depo,
      quantity: quantity,
      subtotal: quantity * depo,
      payment_method: null,
      service_fee: unit_type_found?.midtrans_service_fee,
      grand_total:
        quantity * depo + parseInt(unit_type_found?.midtrans_service_fee),
      auction_category: selectedCategory,
    };

    setTransaction({ ...newData });
    openModal(newData);
    // e.target.reset();
  };

  const convertRupiah = (number) => {
    if (!number) return "-";

    var number_string = number.toString(),
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi number ribuan
    if (ribuan) {
      let separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    return "Rp. " + rupiah;
  };

  return (
    <>
      <div className="h-100 w-100">
        <section className="">
          <Container>
            <Row>
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                <div className="card rounded-custom beli-nipl">
                  <div className="card-body">
                    <div>
                      <div className="fw-bold h5">Beli NIPL</div>
                    </div>
                    {isLoading ? (
                      <div className="d-flex align-items-center justify-content-center py-5">
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="border-bottom pb-3">
                          <TemplateForm
                            label="Tipe Unit Lelang"
                            classNameLabel="required"
                          >
                            <Controller
                              control={control}
                              name="unit_type"
                              render={({
                                field: { onChange, name, value },
                              }) => (
                                <div className="row list-type-unit-checkbox">
                                  {unitType.map((el, idx) => (
                                    <div
                                      className="col-md-4 col-sm-12 cursor-pointer type-unit-checkbox"
                                      key={idx}
                                      onClick={() => {
                                        setUnitTypeId(parseInt(el.id));
                                        setDepo(el?.deposit || 0);
                                        setValue("unit_type", el.id);
                                        setValue(
                                          "service_fee",
                                          el?.midtrans_service_fee
                                        );
                                        setValue("auction", "");
                                      }}
                                    >
                                      <label
                                        className={`border p-2 d-flex justify-content-between align-items-center rounded-custom text-left ${
                                          el.id === unitTypeId
                                            ? "border-bottom border-primary border-2"
                                            : "text-dark"
                                        }`}
                                      >
                                        <div className="d-flex center-vertical">
                                          {el.icon_name ? (
                                            <span
                                              className={`mdi ${el.icon_name} ${
                                                el.id === unitTypeId
                                                  ? "text-primary"
                                                  : ""
                                              }`}
                                            ></span>
                                          ) : (
                                            ""
                                          )}
                                          <div className="font-sm ml-1">
                                            {el.name}
                                          </div>
                                        </div>
                                        <input
                                          className="form-check-input fs-6"
                                          type="radio"
                                          name="tipeUnit"
                                          checked={el.id === unitTypeId}
                                          style={{ minWidth: "15px" }}
                                        />
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                            />
                            {errors.unit_type?.message && (
                              <span className="error-message">
                                {errors.unit_type?.message}
                              </span>
                            )}
                          </TemplateForm>
                          <TemplateForm
                            label="Tipe NIPL"
                            classNameLabel="required"
                          >
                            <Controller
                              control={control}
                              name="nipl_type"
                              render={({
                                field: { onChange, name, value },
                              }) => (
                                <SelectInput
                                  placeholder="Tipe Unit"
                                  options={tipeNIPL}
                                  optionLabel="name"
                                  optionValue="id"
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                />
                              )}
                            />
                            {errors.nipl_type?.message && (
                              <span className="error-message">
                                {errors.nipl_type?.message}
                              </span>
                            )}
                          </TemplateForm>
                          <TemplateForm
                            label="Kategori Lelang"
                            classNameLabel="required"
                          >
                            <Controller
                              control={control}
                              name="auction_category"
                              render={({
                                field: { onChange, name, value },
                              }) => (
                                <SelectInput
                                  placeholder="Kategori Lelang"
                                  options={auctionCategory}
                                  optionLabel="name"
                                  onChange={(event) => {
                                    onChange(event);
                                    let category = null;
                                    if (event === "Online Bid")
                                      category = "ONLINE";
                                    if (event === "Time Bid")
                                      category = "TIME-BID";
                                    setSelectedCategory(category);
                                  }}
                                  value={value}
                                  name={name}
                                />
                              )}
                            />
                            {errors.auction_category?.message && (
                              <span className="error-message">
                                {errors.nipl_type?.message}
                              </span>
                            )}
                          </TemplateForm>
                          <TemplateForm
                            label="Lokasi"
                            classNameLabel="required"
                          >
                            <Controller
                              control={control}
                              name="location"
                              render={({
                                field: { onChange, name, value },
                              }) => (
                                <SelectInput
                                  placeholder="Lokasi"
                                  options={location}
                                  optionLabel="name"
                                  optionValue="id"
                                  // onChange={onChange}
                                  onChange={(event, values) => {
                                    onChange(event);
                                    setLocationId(event);
                                    setValue("auction", "");
                                  }}
                                  value={value}
                                  name={name}
                                />
                              )}
                            />
                            {errors.location?.message && (
                              <span className="error-message">
                                {errors.location?.message}
                              </span>
                            )}
                          </TemplateForm>
                          <TemplateForm
                            label="Jadwal"
                            classNameLabel="required"
                          >
                            <Controller
                              control={control}
                              name="auction"
                              render={({
                                field: { onChange, name, value },
                              }) => (
                                <SelectInput
                                  placeholder="Jadwal"
                                  options={auction}
                                  optionLabel="label"
                                  optionValue="value"
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                />
                              )}
                            />
                            {errors.auction?.message && (
                              <span className="error-message">
                                {errors.auction?.message}
                              </span>
                            )}
                          </TemplateForm>
                          <TemplateForm label="Harga Per NIPL">
                            <div
                              className="fw-bold"
                              style={{ fontSize: "16px" }}
                            >
                              Rp. {depo.toLocaleString() || "0"}
                            </div>
                          </TemplateForm>
                          <TemplateForm label="Jumlah NIPL">
                            <div
                              className="border p-1 border-hover-primary rounded-custom"
                              style={{ width: "30%" }}
                            >
                              <div className="d-flex justify-content-between">
                                <div
                                  className="px-2 text-primary fw-bold fs-5 cursor-pointer"
                                  onClick={() =>
                                    quantity > 1
                                      ? setQuantity((prev) => prev - 1)
                                      : {}
                                  }
                                >
                                  -
                                </div>
                                <div className="px-2 fw-bold">
                                  <input
                                    type="text"
                                    className="w-100 text-center"
                                    maxLength={3}
                                    style={{ border: "none" }}
                                    value={quantity}
                                    readOnly={true}
                                  />
                                </div>
                                <div
                                  className="px-2 text-primary fw-bold fs-5 cursor-pointer"
                                  onClick={() =>
                                    setQuantity((prev) => prev + 1)
                                  }
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </TemplateForm>
                        </div>
                        <Container className="pt-3">
                          <Row className="center-vertical">
                            <Col md={{ span: 3 }} xs={{ span: 4 }}>
                              <span className="fw-semibold font-sm">
                                Subtotal
                              </span>
                            </Col>
                            <Col md={{ span: 4 }} xs={{ span: 5 }}>
                              <span
                                className="fw-bold text-primary text-left mb-2"
                                style={{ fontSize: "16px" }}
                              >
                                {/* Rp. {(depo * quantity).toLocaleString() || '-'} */}
                                {convertRupiah(depo * quantity)}
                              </span>
                            </Col>
                            <Col md={{ span: 5 }} xs={{ span: 12 }}>
                              <PrimaryButton
                                type="submit"
                                className="center-horizontal-vertical button-next-step"
                                style={{ width: "100%" }}
                              >
                                <div className="d-flex align-items-center flex-1">
                                  <div>Lanjutkan ke Pembayaran</div>
                                  <ArrowRightSearch className="filter-icon" />
                                </div>
                              </PrimaryButton>
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </div>
      <ModalLayout
        show={isOpen}
        handleClose={closeModal}
        title={isPay ? "Menunggu Pembayaran" : "Pembayaran"}
        className=""
      >
        {isPay ? (
          <DetailPembelianNIPL />
        ) : (
          <PembayaranBeliNIPL handleClick={() => sendTransaction()} />
        )}
      </ModalLayout>
    </>
  );
};

export default BeliNIPL;

export default function Warning({ color = '#EA9437' }) {
  return (
    <svg
      width='14'
      height='12'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.332031 4.33333V0.666667C0.332031 0.489856 0.402269 0.320286 0.527293 0.195262C0.652318 0.0702379 0.821887 0 0.998698 0H12.9987C13.1755 0 13.3451 0.0702379 13.4701 0.195262C13.5951 0.320286 13.6654 0.489856 13.6654 0.666667V4.33333C13.2233 4.33333 12.7994 4.50893 12.4869 4.82149C12.1743 5.13405 11.9987 5.55797 11.9987 6C11.9987 6.44203 12.1743 6.86595 12.4869 7.17851C12.7994 7.49107 13.2233 7.66667 13.6654 7.66667V11.3333C13.6654 11.5101 13.5951 11.6797 13.4701 11.8047C13.3451 11.9298 13.1755 12 12.9987 12H0.998698C0.821887 12 0.652318 11.9298 0.527293 11.8047C0.402269 11.6797 0.332031 11.5101 0.332031 11.3333V7.66667C0.774059 7.66667 1.19798 7.49107 1.51054 7.17851C1.8231 6.86595 1.9987 6.44203 1.9987 6C1.9987 5.55797 1.8231 5.13405 1.51054 4.82149C1.19798 4.50893 0.774059 4.33333 0.332031 4.33333ZM4.9987 4V5.33333H8.9987V4H4.9987ZM4.9987 6.66667V8H8.9987V6.66667H4.9987Z'
        fill='#4991F2'
      />
    </svg>
  );
}

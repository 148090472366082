import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Form, FormControl, Button } from 'react-bootstrap'
import backdrop from '../../assets/images/backdrop.webp'
import toast from 'react-hot-toast';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import withoutAuth from '../../services/withoutAuthProvider';
import withAuth from '../../services/withAuthProvider';
import { useSelector } from 'react-redux';
import { userSelector } from '../../redux/auth.slice';
import { TitipLelang1, TitipLelang2, TitipLelang3 } from '../../assets/icons/TitipLelangIcons';


export default function TitipLelang(props) {
    const [dataCategories, setDataCategory] = useState([]);
    const [dataBranch, setDataBranch] = useState([]);
    const user = useSelector(userSelector);

    const getCategories = async () => {
        const response = await withoutAuth.getDataWithoutAuth('/api/unit-type', {})
        if (response?.status === 200) {
            setDataCategory(response?.data.data.data)
        } else {
            toast.error(response?.data?.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }

    const getBranch = async () => {
        const response = await withoutAuth.getDataWithoutAuth('/api/location', {})
        if (response?.status === 200) {
            setDataBranch(response?.data.data.data)
        } else {
            toast.error(response?.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }

    useEffect(() => {
        getBranch();
        getCategories();
        reset({ name: user?.name, email: user?.email, phone: user?.phone });
    }, [])

    // form validation rules 
    const validationSchema = Yup.object().shape({
        unit_type_id: Yup.string().required("Silahkan memilih kategori lelang"),
        location_id: Yup.string().required("Silahkan memilih cabang"),
        name: Yup.string().required("Silahkan mengisi nama lengkap"),
        email: Yup.string().required("Silahkan mengisi e-mail"),
        phone: Yup.string().required("Silahkan mengisi telepon"),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {

        const dataTitipLelang = new FormData();

        dataTitipLelang.append("unit_type_id", data.unit_type_id);
        dataTitipLelang.append("location_id", data.location_id);
        dataTitipLelang.append("name", data.name);
        dataTitipLelang.append("email", data.email);
        dataTitipLelang.append("phone", data.phone);

        const loading = toast.loading('Mengirimkan...');
        const response = await withAuth.postDataWithAuth('/api/entrust_request', dataTitipLelang)
        if (response?.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
            reset()
        } else {
            toast.dismiss(loading);
            toast.error(response.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }

    }

    return (
        <>
            <section className="cover-background position-relative overflow-hidden" style={{ background: `url(${backdrop})` }}>
                <div className='background-overlay'></div>
                <Container>

                    <Row>
                        <Col md={props.isLogged ? 9 : 12}>
                            <div className="titip-lelang left-section">
                                <h2 className="text-center">Kenapa Titip Lelang di Caready?</h2>
                                <div className="d-flex flex-center px-3 bullet-titip-lelang">
                                    <div className="pack">
                                        <div className='image-circle d-flex flex-center'>
                                            <TitipLelang1 className="h-50 w-50" />
                                        </div>
                                        <div className='text-bullet'>Terpercaya & Aman</div>
                                    </div>
                                    <div className="pack">
                                        <div className='image-circle d-flex flex-center'>
                                            <TitipLelang2 className="h-50 w-50" />
                                        </div>
                                        <div className='text-bullet'>Proses Mudah & Cepat</div>
                                    </div>
                                    <div className="pack">
                                        <div className='image-circle d-flex flex-center'>
                                            <TitipLelang3 className="h-50 w-50" />
                                        </div>
                                        <div className='text-bullet'>Penawaran Terbaik</div>
                                    </div>
                                </div>
                                <div className='text-center button-titip-lelang'>
                                    {
                                        props.isLogged ? (
                                            <div className='readmore mb-3'>
                                                <span onClick={() => props.push("/titip-lelang")} className="cursor-pointer">Baca Selengkapnya &nbsp;&nbsp;
                                                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.47668 6.00011L0.351685 1.87511L1.53002 0.696777L6.83335 6.00011L1.53002 11.3034L0.351685 10.1251L4.47668 6.00011Z" fill="white" />
                                                    </svg>
                                                </span>
                                            </div>
                                        ) : (
                                            <>

                                                <span className="btn-pink cursor-pointer mb-3" onClick={() => props.push("/masuk")}>
                                                    Masuk Untuk Mulai Titip Lelang
                                                </span>
                                            </>
                                        )}
                                </div>
                            </div>
                        </Col>

                        {
                            props.isLogged ? (
                                <Col md={3}>
                                    <div className='box-border titip-lelang-form'>
                                        <div className="header mb-2">
                                            <span className="title">Titip Lelang</span><br />
                                        </div>
                                        <div className="body">
                                            <Form className="form-caready" onSubmit={handleSubmit(onSubmit)}>
                                                <Form.Group className="form-group" controlId="formBasicEmail">
                                                    <Form.Label className="required">Kategori Lelang</Form.Label>
                                                    <Form.Select
                                                        placeholder="Pilih Kategori Lelang"
                                                        {...register('unit_type_id')}
                                                    >
                                                        <option value="">Pilih Kategori Lelang</option>
                                                        {dataCategories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.unit_type_id?.message && <span className='error-message'>{errors.unit_type_id?.message}</span>}
                                                </Form.Group>

                                                <Form.Group className="form-group" controlId="formBasicEmail">
                                                    <Form.Label className="required">Cabang</Form.Label>
                                                    <Form.Select
                                                        placeholder="Pilih Cabang"
                                                        {...register('location_id')}
                                                    >
                                                        <option value="">Pilih Cabang</option>
                                                        {dataBranch.map((branch) => (
                                                            <option key={branch.id} value={branch.id}>
                                                                {branch.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.location_id?.message && <span className='error-message'>{errors.location_id?.message}</span>}
                                                </Form.Group>

                                                <Form.Group className="form-group" controlId="formBasicEmail">
                                                    <Form.Label className="required">Nama Lengkap</Form.Label>
                                                    <FormControl
                                                        placeholder="Nama Lengkap"
                                                        aria-label="Nama Lengkap"
                                                        aria-describedby="basic-addon1"
                                                        {...register('name')}
                                                    />
                                                    {errors.name?.message && <span className='error-message'>{errors.name?.message}</span>}
                                                </Form.Group>

                                                <Form.Group className="form-group" controlId="formBasicEmail">
                                                    <Form.Label className="required">E-mail</Form.Label>
                                                    <FormControl
                                                        placeholder="E-mail"
                                                        aria-label="E-mail"
                                                        aria-describedby="basic-addon1"
                                                        {...register('email')}
                                                    />
                                                    {errors.email?.message && <span className='error-message'>{errors.email?.message}</span>}
                                                </Form.Group>

                                                <Form.Group className="form-group" controlId="formBasicEmail">
                                                    <Form.Label className="required">Telepon</Form.Label>
                                                    <FormControl
                                                        placeholder="Telepon"
                                                        aria-label="Telepon"
                                                        aria-describedby="basic-addon1"
                                                        {...register('phone')}
                                                    />
                                                    {errors.phone?.message && <span className='error-message'>{errors.phone?.message}</span>}
                                                </Form.Group>

                                                <Button className="button-login mb-3" style={{ display: "block !important", width: "100%" }} variant="primary" type="submit">
                                                    Selanjutnya
                                                </Button>

                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            ) : ""
                        }

                    </Row>
                </Container>

            </section>
        </>
    )
}

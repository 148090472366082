import InfoSignUp from "../../components/InfoSignUp/InfoSignUp";
import {
    kp1,
    kp2,
    kp3,
    kp4,
    kp42,
    kp5
} from "./data";
import snkImage from '../../assets/images/snk.webp';

const SyaratDanKetentuan = () => {
    return (
        <div>
            <div
                className="text-white fw-bold"
                style={{ height: '395px', fontSize: '3rem', backgroundImage: `url('${snkImage}')` }}
            >
                <div className="d-flex flex-center h-100 w-100 " style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                     <div className="syarat-dan-ketentuan flex-center" >
                        Syarat dan Ketentuan
                     </div>
                </div>
            </div>
            <section className="py-5">
                <div className="container snk" style={{ fontSize: '1.25rem' }}>
                    <div>{kp1}</div>
                    <div>{kp2}</div>
                    {
                        kp3.map((kp, indx) => (
                            <div key={`kebijakan privasi-${indx}`}>
                                <div className="fw-bold mb-3">{kp.label}</div>
                                {kp.caption !== '' && <div>{kp.caption}</div>}
                                {kp.caption2 !== '' && <div>{kp.caption2}</div>}
                                {
                                    kp.child.length > 0 && (
                                        <ol>
                                            {
                                                kp.child.map((chld, idx) => (
                                                    <li key={`kebijakan privasi-${indx}-${idx}`}>
                                                        <div>
                                                            <div>{chld.label}</div>
                                                            {
                                                                chld?.points?.length > 0 && (
                                                                    <ol type="a">
                                                                        {chld?.points?.map((el, i) => (
                                                                            <li key={`kebijakan privasi-${indx}-${idx}-${i}`}>{el}</li>
                                                                        ))}
                                                                    </ol>
                                                                )
                                                            }
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ol>
                                    )
                                }
                            </div>
                        ))
                    }

                    {/* Pemenang Lelang*/}
                    <div>
                        <div className="fw-bold mb-3">Pemenang Lelang</div>
                        <ol>
                            <li>
                                <div>
                                    <div>Pemenang Lelang wajib membayar biaya administrasi sesuai dengan ketentuan sebagai berikut:</div>
                                    {
                                        kp4.length > 0 && (
                                            <ol type='a'>
                                                {
                                                    kp4.map((chld, idx) => (
                                                        <li key={`kebijakan privasi-33-${idx}`}>
                                                            <div>
                                                                <div>{chld.label} :</div>
                                                                {chld?.points?.length > 0 && chld?.points.map(({ value1, value2 }, i) => (
                                                                    <div className="d-flex mb-3 border-b" key={`ini ${i}`}>
                                                                        <div className="w-50">{value1}</div>
                                                                        <div className="w-50 d-flex align-items-center">{value2}</div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ol>
                                        )
                                    }
                                </div>
                            </li>
                            {
                                kp42.map((el, indx) => (
                                    <li key={`kebijakan privasi-34-${indx}`}>
                                        <div>
                                            <div>{el.label}</div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ol>
                    </div>


                    {/* keluhan, klaim, dan sengketa*/}
                    <div>
                        <div className="fw-bold mb-3">{kp5.label}</div>
                        {kp5.caption !== '' && <div>{kp5.caption}</div>}
                        {kp5.caption2 !== '' && <div>{kp5.caption2}</div>}
                        {
                            kp5.child.length > 0 && (
                                <ol>
                                    {
                                        kp5.child.map((chld, idx) => (
                                            <li key={`kebijakan privasi-32-${idx}`}>
                                                <div>
                                                    <div>{chld.label}</div>
                                                    {
                                                        chld?.points?.length > 0 && (
                                                            <ol type="a">
                                                                {chld?.points?.map((el, i) => (
                                                                    <li key={`kebijakan privasi-32-${idx}-${i}`}>{el}</li>
                                                                ))}
                                                            </ol>
                                                        )
                                                    }
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ol>
                            )
                        }
                    </div>
                </div>
            </section>
            <InfoSignUp />
        </div>
    )
}

export default SyaratDanKetentuan;
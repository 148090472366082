export default function Time({ className }) {
    return (
        <svg 
        className={className}
        width="18" 
        height="18" 
        viewBox="0 0 18 18" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path 
            d="M9.00008 17.3333C4.39758 17.3333 0.666748 13.6025 0.666748 8.99996C0.666748 4.39746 4.39758 0.666626 9.00008 0.666626C13.6026 0.666626 17.3334 4.39746 17.3334 8.99996C17.3334 13.6025 13.6026 17.3333 9.00008 17.3333ZM9.83342 8.99996V4.83329H8.16675V10.6666H13.1667V8.99996H9.83342Z" 
            fill="#9AA2B1" />
        </svg>
    );
}

export default function LockOn({ active }) {
  return (
    <svg
      width='12'
      height='14'
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6667 5.66634H11.3333C11.5101 5.66634 11.6797 5.73658 11.8047 5.8616C11.9298 5.98663 12 6.1562 12 6.33301V12.9997C12 13.1765 11.9298 13.3461 11.8047 13.4711C11.6797 13.5961 11.5101 13.6663 11.3333 13.6663H0.666667C0.489856 13.6663 0.320286 13.5961 0.195262 13.4711C0.0702379 13.3461 0 13.1765 0 12.9997V6.33301C0 6.1562 0.0702379 5.98663 0.195262 5.8616C0.320286 5.73658 0.489856 5.66634 0.666667 5.66634H1.33333V4.99967C1.33333 4.38684 1.45404 3.78 1.68856 3.21382C1.92308 2.64763 2.26683 2.13318 2.70017 1.69984C3.13351 1.2665 3.64796 0.922759 4.21414 0.688237C4.78033 0.453715 5.38716 0.333008 6 0.333008C6.61284 0.333008 7.21967 0.453715 7.78586 0.688237C8.35204 0.922759 8.86649 1.2665 9.29983 1.69984C9.73317 2.13318 10.0769 2.64763 10.3114 3.21382C10.546 3.78 10.6667 4.38684 10.6667 4.99967V5.66634ZM9.33333 5.66634V4.99967C9.33333 4.11562 8.98214 3.26777 8.35702 2.64265C7.7319 2.01753 6.88406 1.66634 6 1.66634C5.11595 1.66634 4.2681 2.01753 3.64298 2.64265C3.01786 3.26777 2.66667 4.11562 2.66667 4.99967V5.66634H9.33333ZM5.33333 8.33301V10.9997H6.66667V8.33301H5.33333Z'
        fill={active === 'on' ? '#8C8D8F' : '#ff0000'}
      />
    </svg>
  );
}

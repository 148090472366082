import React from 'react'
import { bca } from "../../assets/images/beliNIPLicon";

export default function Payment() {
    return (
        <>
            <div className='mt-20 mb-3'>
                <div className='summary-name'>Total Tagihan</div>
                <div className='summary-amount'>Rp 84.750.000</div>
            </div>
            <div className="p-2 rounded font-xs mb-3 text-center" style={{ backgroundColor: 'rgba(207, 23, 1, 0.05)' }}>
                <div>Bayar Sebelum: <span className="fw-semibold">2 Feb 2022 13:00 WIB</span></div>
            </div>
            <div className='amount-item mb-3'>
                <div className="d-flex justify-content-between mb-1 border-bottom-amount">
                    <div className='item-name'>BCA Virtual Account</div>
                    <div className='item-amount'><img src={bca} /></div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className='item-name'>No. Virtual Account</div>
                    <div className='item-amount'>Rp 103.000.000</div>
                </div>
            </div>
            <div className='subtitle-transaction mb-3'>Rincian Pembayaran</div>
            <div className='amount-item mb-3'>
                <div className="d-flex justify-content-between mb-1">
                    <div className='item-unit'>Lot 1 - 2019 DAIHATSU SIGRA 1.0 M</div>
                </div>
                <div className="d-flex justify-content-between mb-1">
                    <div className='item-name'>Bekasi - 16 Feb  2022 - 13:00 WIB</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className='item-name'>1 x Rp 103.000.000</div>
                    <div className='item-amount'>Rp 103.000.000</div>
                </div>
            </div>
            <div className='amount-item mb-3'>
                <div className="d-flex justify-content-between mb-1">
                    <div className='item-name'>Harga Terbentuk Lelang</div>
                    <div className='item-amount'>Rp 103.000.000</div>
                </div>
                <div className="d-flex justify-content-between mb-1">
                    <div className='item-name'>Deposit Lelang</div>
                    <div className='item-amount'>(Rp 5.000.000)</div>
                </div>
                <div className="d-flex justify-content-between border-bottom-amount">
                    <div className='item-name'>Biaya Admin</div>
                    <div className='item-amount'>Rp 1.750.000</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className='item-name'>Total Bayar</div>
                    <div className='item-amount'>Rp 84.750.000</div>
                </div>
            </div>
        </>
    )
}

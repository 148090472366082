import { format } from "date-fns";
import { id } from 'date-fns/esm/locale';

export const replaceEnter = (str) => {
    str = str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    return str;
}

export const locDateFormatter = (location_name, date, time) => {
    const str = `${location_name}, ${format(date, 'dd MMM yyyy', { locale: id })} ${time.substr(0, 5)}`
    return str;
}

export const dateFormatter = (date, notFull = false) => {
    let format_date = 'dd MMM yyyy, HH:mm'
    if (notFull) {
        format_date = 'dd MMM yyyy';
    }
    const str = format(new Date(date), format_date, { locale: id })
    return str;
}

export const isDate = (str) => {
    if (str && !isNaN(Date.parse(str))) {
        let [y, M, d, h, m, s] = str.split(/[- : T Z]/);
        return !!(y && M <= 12 && d <= 31);
    }
};

export const validatePassword = input => {
    let status = {};
    input.length < 8 ? (status.length = false) : (status.length = true);
    !/\d/.test(input) ? (status.number = false) : (status.number = true);
    !/[A-Z]/.test(input) || !/[a-z]/.test(input) ? (status.capitalLower = false) : (status.capitalLower = true);
    // !/[!@#$%^&*()_+]/.test(input) ? (status.special = false) : (status.special = true);
    return status;
};



import React from 'react';
import Footers from '../../Footers/Footers';
import Headers from '../../Headers/Headers';
// import Helper from '../helper';

const MainLayout = (props) => {
    const { children } = props;

    return (
        <>
            <Headers />
            <div className="w-100 position-relative main-content" style={{ minHeight: 'calc(100vh - 4rem)', backgroundColor: '#F5F6F7' }}>
                {children}
            </div>
            <Footers />
            {/* <Helper /> */}
        </>
    )
}

export default MainLayout;
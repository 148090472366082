import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick, arrowClass, arrowStroke } = props;
    return (
        <>
            <div className={`${arrowClass ? arrowClass : "slider-arrow"} next`} style={{ ...style }} onClick={onClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18L15 12L9 6" stroke={arrowStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick, arrowClass, arrowStroke } = props;
    return (
        <>
            <div className={`${arrowClass ? arrowClass : "slider-arrow"} previous`} style={{ ...style }} onClick={onClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke={arrowStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </>
    );
}

export default class CarouselLayout extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const settings = {
            dots: this.props.dots ? true : false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow className="slider-arrow" arrowClass={`${this.props.arrowClass ? this.props.arrowClass : "slider-arrow"}`} arrowStroke={`${this.props.arrowStroke ? this.props.arrowStroke : "white"}`} />,
            prevArrow: <SamplePrevArrow className="slider-arrow" arrowClass={`${this.props.arrowClass ? this.props.arrowClass : "slider-arrow"}`} arrowStroke={`${this.props.arrowStroke ? this.props.arrowStroke : "white"}`} />
        };

        return (
            <>
                <Slider {...settings} className={this.props.className}>
                    {this.props.children}
                </Slider>
            </>
        );
    }
}
import { createSlice } from '@reduxjs/toolkit'
// Slice
const liveAuctionSlice = createSlice({
    name: 'liveAuction',
    initialState: {
        modal: false,
        currentDataUnit: {},
        finalPrice: 0
    },
    reducers: {
        setModal: (state, action) => {
            state.modal = action.payload.modal;
        },
        setCurrentDataUnit: (state, action) => {
            state.currentDataUnit = action.payload.unit;
        },
        setFinalPrice: (state, action) => {
            state.finalPrice = action.payload.price;
        },
    },
});

export const modalSelector = state => state.liveActionReducer.modal;
export const currentdataUnitSelector = state => state.liveActionReducer.currentDataUnit;
export const finalPriceSelector = state => state.liveActionReducer.finalPrice;

export const { actions: actionsLiveAuction, reducer } = liveAuctionSlice;
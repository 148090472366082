import React from 'react'
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";

export default function FilterTag() {
    return (
        <div>
            <Container>
                <Row>
                    {/* <Col span={12}>
                        <div className="tag-filter-unit-lelang">
                            <span>Produk Pilihan :</span>
                            <span className="tag">Lelang Mendatang
                            </span>
                            <span className="tag">Lokasi Terdekat
                            </span>
                            <span className="tag">Rekomendasi
                            </span>
                            <span className="tag">Harga Terendah
                            </span>
                            <span className="tag">Grade A
                            </span>
                            <span className="tag">Harga dibawah 100 Juta
                            </span>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
    )
}

import React, { useEffect } from "react";
import {Route,useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import withAuth from "../../services/withAuthProvider";
import toast from "react-hot-toast";

const CustomRoute = ({ path, layout: Layout, page: Page }) => {
  const isLogged = useSelector((state) => state.authReducer.isLogged);
  const location = useLocation();
  const getProfile = async () => {
    const response = await withAuth.getDataProfile();
    if (response?.status !== 200) {
      toast.error(response?.data?.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    if(isLogged){
      getProfile()
    }
    scrollToTop();
  }, [path, location.pathname]);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <Route path={path}>
      <Layout>
        <Page />
      </Layout>
    </Route>
  );
};

export default CustomRoute;
import React from 'react'
import { Form } from 'react-bootstrap';
import { format } from "date-fns";
import { id } from 'date-fns/esm/locale';

const FilterJadwalLelang = (props, key) => {
    const today = format(new Date(), 'eeee, dd MMM yyyy', { locale: id });
    return (
        <div className="filterJadwalLelang">

            <div className='align-items-center justify-content-between flex-column flex-md-row w-100 gap-2' style={{
                display: 'flex',
                padding: "20px",
                background: "#FFFFFF",
                boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                borderRadius: "12px",
                marginTop: "26px",
                marginBottom: "12px",
                border: "1px solid #F5F5F5"
            }}>
                {
                    props.show === 1 ?
                        <div style={{ display: "table", flex: "1", fontSize: "18px", lineHeight: "28px", fontWeight: "bold" }}>
                            <span style={{
                                display: "table - cell", verticalAlign: "middle"
                            }}>{today}</span>
                        </div>
                        : ''
                }
                <div className='d-flex w-100-md-auto flex-md-row flex-column'>
                    <div className='jadwal-lelang-option'>
                        <Form.Select
                            style={{ fontSize: "14px", lineHeight: "20px", color: "#092540" }}
                            placeholder="Seri"
                            name="location_id"
                            onChange={props.handleChange}
                            value={props?.data?.location_id}
                            required
                        >
                            <option value="">Kota</option>
                            {
                                props.locations.map((el, idx) => <option key="idx" value={el.id} >{el.name}</option>)
                            }
                        </Form.Select>
                    </div>
                    <div className='jadwal-lelang-option'>
                        <Form.Select
                            style={{ fontSize: "14px", lineHeight: "20px", color: "#092540" }}
                            placeholder="Seri"
                            name="unit_type_id"
                            onChange={props.handleChange}
                            value={props?.data?.unit_type_id}
                            required
                        >
                            <option value="">Objek Lelang</option>
                            {
                                props.unitTypes.map((el, idx) => <option key="idx" value={el.id} >{el.name}</option>)
                            }
                            {/* <option value="">Mobil</option>
                        <option value="">Motor</option>
                        <option value="">Goods</option> */}
                        </Form.Select>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FilterJadwalLelang;

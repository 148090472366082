import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import withoutAuthProvider from "../../services/withoutAuthProvider";
import ProcedeBidding from "../../components/TabContent/Prosedur/ProcedeBidding";

export default function Prosedur() {
  const { push } = useHistory();
  const { part } = useParams();
  const [procedure, setProcedure] = useState();
  const [subprocedure, setSubprocedure] = useState([]);
  const [procedeTitle, setProcedeTitle] = useState("");
  const [loading, setLoading] = useState(true);

  const handleClick = (path, procede) => {
    push(path);
    setSubprocedure(procede.subprocedure);
    setProcedeTitle(procede.title);
  };

  const getProcedure = async () => {
    setLoading(true);

    const response = await withoutAuthProvider.getDataWithoutAuth(
      "/api/procedure",
      {}
    );

    if (response?.status === 200) {
      const { data } = response.data.data;
      setProcedure(data);

      if (part) {
        const title = part.split("-").join(" ");

        const dataSelected = data.find(
          (dat) => dat.title.toLowerCase() === title
        );

        if (dataSelected) {
          setSubprocedure(dataSelected.subprocedure);
          setProcedeTitle(dataSelected.title);
        }
      } else {
        setSubprocedure(data[0].subprocedure);
        setProcedeTitle(data[0].title);
      }

      setLoading(false);
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getProcedure();
  }, []);

  if (loading) {
    return (
      <div className="py-5 font-sm text-secondary text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <>
      <div className="w-100 h-100 page">
        <section>
          <Container>
            <Row>
              <Col md={{ span: 3 }}>
                <div className="box-border mt-15">
                  {procedure &&
                    procedure.map((procede) => {
                      const procedeRoute = procede.title
                        .split(" ")
                        .join("-")
                        .toLowerCase();
                      return (
                        <div
                          className={`nav-item-vertical cursor-pointer ${
                            part === procedeRoute ? "active" : ""
                          }`}
                          onClick={() =>
                            handleClick(`/prosedur/${procedeRoute}`, procede)
                          }
                        >
                          {procede.title}
                        </div>
                      );
                    })}
                </div>
              </Col>
              <Col md={{ span: 9 }}>
                <div className="box-border mt-15 mb-3">
                  {procedeTitle && (
                    <ProcedeBidding
                      procedure={subprocedure}
                      title={procedeTitle}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

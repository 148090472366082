import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/images/caready.png'
import logo2 from '../../../assets/images/caready-image.png'

const AuthLayout = (props) => {
    const { children } = props;
    const { push } = useHistory();

    const handleClick = (path) => {
        push(path)
    }

    return (
        <div className='vw-100 vh-100 row p-0 mx-0' style={{ backgroundColor: '#E5E5E5' }}>
            <div className='col-md-7 px-0 d-none d-sm-block'>
                <div className='d-flex flex-center h-100 w-100'>
                    <img src={logo2} alt="caready" height={240} width={300} />
                </div>
            </div>
            <div className='col-md-5 col-sm-12 px-0 form-auth' style={{ backgroundColor: '#FFF' }}>
                <div className='w-100 h-100 p-5'>
                    <div className='px-5 h-100'>
                        <div style={{ height: '5%' }}>
                            <img src={logo} alt="caready" className='cursor-pointer logo-auth' onClick={() => handleClick('/')} />
                        </div>
                        <div className='d-flex align-items-center' style={{ height: '92%' }}>
                            {children}
                        </div>
                        <div style={{ height: '3%' }}>
                            <div className=' font-sm d-flex flex-col justify-align-center'>
                                <div className='me-2 border-end pe-2 text-secondary'>CAREADY © 2023 all right reserved</div>
                                <div className='text-primary cursor-pointer' onClick={() => handleClick('/faq')}>Bantuan</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout;
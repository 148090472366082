export function ThumbUp({ className }) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66659 7.5H4.16659V17.5H1.66659C1.44557 17.5 1.23361 17.4122 1.07733 17.2559C0.921049 17.0996 0.833252 16.8877 0.833252 16.6667V8.33333C0.833252 8.11232 0.921049 7.90036 1.07733 7.74408C1.23361 7.5878 1.44557 7.5 1.66659 7.5ZM6.07742 6.4225L11.4108 1.08917C11.4816 1.01808 11.5758 0.974974 11.6759 0.967778C11.7761 0.960582 11.8754 0.989779 11.9558 1.05L12.6666 1.58333C12.864 1.73154 13.0131 1.93488 13.0951 2.16771C13.1771 2.40055 13.1884 2.65246 13.1274 2.89167L12.1666 6.66667H17.4999C17.9419 6.66667 18.3659 6.84226 18.6784 7.15482C18.991 7.46738 19.1666 7.89131 19.1666 8.33333V10.0867C19.1668 10.3045 19.1243 10.5202 19.0416 10.7217L16.4624 16.9842C16.3995 17.1369 16.2926 17.2674 16.1554 17.3592C16.0182 17.4511 15.8567 17.5001 15.6916 17.5H6.66658C6.44557 17.5 6.23361 17.4122 6.07733 17.2559C5.92105 17.0996 5.83325 16.8877 5.83325 16.6667V7.01167C5.8333 6.79067 5.92113 6.57875 6.07742 6.4225Z" fill="currentColor" />
        </svg>
    );
}

export function TimerFlash({ className }) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.31834 4.97333C6.64646 3.90922 8.29817 3.33061 10 3.33333C11.7708 3.33333 13.3983 3.94666 14.6817 4.97333L15.8925 3.76249L17.0708 4.94083L15.86 6.15166C16.7414 7.25512 17.2934 8.58502 17.4523 9.98832C17.6113 11.3916 17.3708 12.8113 16.7586 14.0839C16.1463 15.3566 15.1872 16.4305 13.9915 17.1821C12.7959 17.9338 11.4123 18.3325 10 18.3325C8.58773 18.3325 7.20415 17.9338 6.0085 17.1821C4.81284 16.4305 3.85369 15.3566 3.24144 14.0839C2.62918 12.8113 2.3887 11.3916 2.54767 9.98832C2.70663 8.58502 3.25858 7.25512 4.14 6.15166L2.92917 4.94166L4.1075 3.76333L5.31834 4.97416V4.97333ZM10.8333 9.99999V6.24583L6.66667 11.6667H9.16667V15.4167L13.3333 9.99999H10.8333ZM6.66667 0.833328H13.3333V2.49999H6.66667V0.833328Z" fill="currentColor" />
        </svg>
    );
}

export function Shield({ className }) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0.833328L16.8475 2.35499C17.2283 2.43999 17.5 2.77749 17.5 3.16833V11.4908C17.5 13.1625 16.6642 14.7242 15.2733 15.6508L10 19.1667L4.72667 15.6508C3.335 14.7233 2.5 13.1625 2.5 11.4917V3.16833C2.5 2.77749 2.77167 2.43999 3.1525 2.35499L10 0.833328ZM13.71 6.85166L9.585 10.9758L7.22833 8.61916L6.05 9.79749L9.58583 13.3333L14.8892 8.02999L13.71 6.85166V6.85166Z" fill="currentColor" />
        </svg>
    );
}

// headers
export function CarLine({ className }) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8333 16.6667H4.16663V17.5C4.16663 17.721 4.07883 17.933 3.92255 18.0892C3.76627 18.2455 3.55431 18.3333 3.33329 18.3333H2.49996C2.27895 18.3333 2.06698 18.2455 1.9107 18.0892C1.75442 17.933 1.66663 17.721 1.66663 17.5V9.16666L3.73329 4.34333C3.8619 4.04333 4.07576 3.78769 4.34834 3.60815C4.62092 3.4286 4.94022 3.33304 5.26663 3.33333H14.7333C15.0594 3.33336 15.3784 3.42908 15.6506 3.6086C15.9229 3.78813 16.1365 4.04359 16.265 4.34333L18.3333 9.16666V17.5C18.3333 17.721 18.2455 17.933 18.0892 18.0892C17.9329 18.2455 17.721 18.3333 17.5 18.3333H16.6666C16.4456 18.3333 16.2337 18.2455 16.0774 18.0892C15.9211 17.933 15.8333 17.721 15.8333 17.5V16.6667ZM16.6666 10.8333H3.33329V15H16.6666V10.8333ZM3.47996 9.16666H16.52L14.7341 5H5.26663L3.48079 9.16666H3.47996ZM5.41663 14.1667C5.08511 14.1667 4.76716 14.035 4.53274 13.8005C4.29832 13.5661 4.16663 13.2482 4.16663 12.9167C4.16663 12.5851 4.29832 12.2672 4.53274 12.0328C4.76716 11.7984 5.08511 11.6667 5.41663 11.6667C5.74815 11.6667 6.06609 11.7984 6.30051 12.0328C6.53493 12.2672 6.66663 12.5851 6.66663 12.9167C6.66663 13.2482 6.53493 13.5661 6.30051 13.8005C6.06609 14.035 5.74815 14.1667 5.41663 14.1667ZM14.5833 14.1667C14.2518 14.1667 13.9338 14.035 13.6994 13.8005C13.465 13.5661 13.3333 13.2482 13.3333 12.9167C13.3333 12.5851 13.465 12.2672 13.6994 12.0328C13.9338 11.7984 14.2518 11.6667 14.5833 11.6667C14.9148 11.6667 15.2328 11.7984 15.4672 12.0328C15.7016 12.2672 15.8333 12.5851 15.8333 12.9167C15.8333 13.2482 15.7016 13.5661 15.4672 13.8005C15.2328 14.035 14.9148 14.1667 14.5833 14.1667Z" fill="currentColor" />
        </svg>
    );
}
export function BikeLine({ className }) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33331 11.0467V10H1.66664V8.33333H6.9708L9.33331 6.66667H12.2425L11.3333 4.16667H9.16664V2.5H12.5L13.41 5H16.6666V7.5H14.32L15.5333 10.835C16.4539 10.8643 17.3316 11.2313 17.9989 11.8662C18.6663 12.5011 19.0766 13.3594 19.1517 14.2775C19.2267 15.1955 18.9613 16.1091 18.406 16.8439C17.8507 17.5788 17.0443 18.0836 16.1407 18.2621C15.237 18.4405 14.2993 18.2801 13.5063 17.8115C12.7133 17.3429 12.1206 16.5988 11.841 15.7212C11.5615 14.8435 11.6147 13.8937 11.9906 13.0528C12.3665 12.2119 13.0387 11.5387 13.8791 11.1617L12.8491 8.33333H12.06L10.7416 13.225L10.74 13.2242L10.7416 13.2292L8.30414 14.1158C8.39211 14.8177 8.27978 15.5301 7.98011 16.1709C7.68044 16.8116 7.20566 17.3546 6.61058 17.737C6.0155 18.1194 5.3244 18.3257 4.61707 18.3321C3.90973 18.3384 3.21502 18.1446 2.61315 17.773C2.01127 17.4014 1.52677 16.8671 1.2156 16.2319C0.904422 15.5966 0.779261 14.8863 0.854564 14.183C0.929868 13.4797 1.20256 12.812 1.64117 12.257C2.07977 11.702 2.6664 11.2824 3.33331 11.0467ZM4.99997 10.8558C5.55284 10.9177 6.08502 11.1019 6.55792 11.3949C7.03082 11.6879 7.4326 12.0825 7.73414 12.55L9.36497 11.9558L10.3358 8.33333H9.87997L7.49997 10H4.99997V10.8558ZM4.58331 16.6667C5.13584 16.6667 5.66574 16.4472 6.05644 16.0565C6.44714 15.6658 6.66664 15.1359 6.66664 14.5833C6.66664 14.0308 6.44714 13.5009 6.05644 13.1102C5.66574 12.7195 5.13584 12.5 4.58331 12.5C4.03077 12.5 3.50087 12.7195 3.11017 13.1102C2.71947 13.5009 2.49997 14.0308 2.49997 14.5833C2.49997 15.1359 2.71947 15.6658 3.11017 16.0565C3.50087 16.4472 4.03077 16.6667 4.58331 16.6667ZM15.4166 16.6667C15.9692 16.6667 16.4991 16.4472 16.8898 16.0565C17.2805 15.6658 17.5 15.1359 17.5 14.5833C17.5 14.0308 17.2805 13.5009 16.8898 13.1102C16.4991 12.7195 15.9692 12.5 15.4166 12.5C14.8641 12.5 14.3342 12.7195 13.9435 13.1102C13.5528 13.5009 13.3333 14.0308 13.3333 14.5833C13.3333 15.1359 13.5528 15.6658 13.9435 16.0565C14.3342 16.4472 14.8641 16.6667 15.4166 16.6667Z" fill="currentColor" />
        </svg>
    );
}
export function GoodLine({ className }) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83329 3.33334V16.6667H14.1666V3.33334H5.83329ZM4.99996 1.66667H15C15.221 1.66667 15.4329 1.75447 15.5892 1.91075C15.7455 2.06703 15.8333 2.27899 15.8333 2.50001V17.5C15.8333 17.721 15.7455 17.933 15.5892 18.0893C15.4329 18.2455 15.221 18.3333 15 18.3333H4.99996C4.77895 18.3333 4.56698 18.2455 4.4107 18.0893C4.25442 17.933 4.16663 17.721 4.16663 17.5V2.50001C4.16663 2.27899 4.25442 2.06703 4.4107 1.91075C4.56698 1.75447 4.77895 1.66667 4.99996 1.66667ZM9.99996 14.1667C10.221 14.1667 10.4329 14.2545 10.5892 14.4107C10.7455 14.567 10.8333 14.779 10.8333 15C10.8333 15.221 10.7455 15.433 10.5892 15.5893C10.4329 15.7455 10.221 15.8333 9.99996 15.8333C9.77895 15.8333 9.56698 15.7455 9.4107 15.5893C9.25442 15.433 9.16663 15.221 9.16663 15C9.16663 14.779 9.25442 14.567 9.4107 14.4107C9.56698 14.2545 9.77895 14.1667 9.99996 14.1667Z" fill="currentColor" />
        </svg>
    );
}
export default function User({ className }) {
    return (
        <svg
            className={className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6666 18.3333H3.33325V16.6666C3.33325 15.5615 3.77224 14.5017 4.55364 13.7203C5.33504 12.9389 6.39485 12.4999 7.49992 12.4999H12.4999C13.605 12.4999 14.6648 12.9389 15.4462 13.7203C16.2276 14.5017 16.6666 15.5615 16.6666 16.6666V18.3333ZM9.99992 10.8333C9.34331 10.8333 8.69313 10.7039 8.0865 10.4526C7.47987 10.2014 6.92868 9.83308 6.46438 9.36879C6.00009 8.90449 5.63179 8.3533 5.38052 7.74667C5.12925 7.14004 4.99992 6.48986 4.99992 5.83325C4.99992 5.17664 5.12925 4.52646 5.38052 3.91983C5.63179 3.31321 6.00009 2.76201 6.46438 2.29772C6.92868 1.83343 7.47987 1.46513 8.0865 1.21385C8.69313 0.962581 9.34331 0.833252 9.99992 0.833252C11.326 0.833252 12.5978 1.36004 13.5355 2.29772C14.4731 3.2354 14.9999 4.50717 14.9999 5.83325C14.9999 7.15933 14.4731 8.4311 13.5355 9.36879C12.5978 10.3065 11.326 10.8333 9.99992 10.8333Z"
                fill="currentColor" />
        </svg>

    );
}

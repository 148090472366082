import { useState } from "react"

const Eye = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.984375 10C1.76771 5.73333 5.50688 2.5 10.0002 2.5C14.4935 2.5 18.2319 5.73333 19.016 10C18.2327 14.2667 14.4935 17.5 10.0002 17.5C5.50688 17.5 1.76854 14.2667 0.984375 10ZM10.0002 14.1667C11.1053 14.1667 12.1651 13.7277 12.9465 12.9463C13.7279 12.1649 14.1669 11.1051 14.1669 10C14.1669 8.89493 13.7279 7.83512 12.9465 7.05372C12.1651 6.27232 11.1053 5.83333 10.0002 5.83333C8.89514 5.83333 7.83533 6.27232 7.05393 7.05372C6.27253 7.83512 5.83354 8.89493 5.83354 10C5.83354 11.1051 6.27253 12.1649 7.05393 12.9463C7.83533 13.7277 8.89514 14.1667 10.0002 14.1667ZM10.0002 12.5C9.33717 12.5 8.70128 12.2366 8.23244 11.7678C7.7636 11.2989 7.50021 10.663 7.50021 10C7.50021 9.33696 7.7636 8.70107 8.23244 8.23223C8.70128 7.76339 9.33717 7.5 10.0002 7.5C10.6632 7.5 11.2991 7.76339 11.768 8.23223C12.2368 8.70107 12.5002 9.33696 12.5002 10C12.5002 10.663 12.2368 11.2989 11.768 11.7678C11.2991 12.2366 10.6632 12.5 10.0002 12.5Z" fill="#9AA2B1" />
</svg>


export const PasswordField = (props) => {
    const [isShow, setIsShow] = useState(false)
    return (
        <div className="position-relative">
            <label htmlFor={props.id} className="font-sm fw-bold">{props.label} <span className='text-primary'>*</span></label>
            <div className="input-group">
                <input
                    type={isShow ? 'text' : 'password'}
                    className="form-control font-sm"
                    name={props.name}
                    id={props.id}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    value={props.value} />
                <div
                    className="cursor-pointer input-group-text bg-light"
                    onClick={() => setIsShow(!isShow)}
                >
                    <Eye />
                </div>
            </div>
            {
                props.extended ? (
                    <div className="text-secondary font-xs my-2">
                        <ul>
                            <li style={{textDecorationLine: props.validation?.length ? 'line-through' : 'none'}}>Kata sandi harus terdiri dari minimal 8 karakter</li>
                            <li style={{textDecorationLine: props.validation?.number ? 'line-through' : 'none'}}>Tambahkan setidaknya satu angka</li>
                            <li style={{textDecorationLine: props.validation?.capitalLower ? 'line-through' : 'none'}}>Sertakan huruf besar dan kecil</li>
                        </ul>
                    </div>
                ) : ''
            }
        </div>
    )
}

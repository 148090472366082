import API from './axiosConfig';
import axios from 'axios'
import { tokenSelector } from '../redux/auth.slice';

// const API = axios.create({
//     // .. where we make our configurations
//     baseURL: process.env.REACT_APP_URL,
//     timeout: 10000,
//     headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//     }
// });


function buildResponse(response) {
    return response;
}

const token = localStorage.getItem('token')

API.defaults.headers.common['Authorization'] = `Bearer ${token}`

export default {
    setToken(token) {
        API.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    getDataWithAuth: async (url, parameter) => {
        return API.get(`${url}`, {
            params: parameter
        })
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
    getDataProfile: async () => {
        return API.get(`/api/account/profile`, {
        })
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
    postDataWithAuth: async (url, body) => {
        return API.post(`${url}`, body)
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
    postDataWithAuthFormData: async (url, body) => {
        return API.post(`${url}`, body, {headers: { "Content-Type": "multipart/form-data" }})
          .then((response) => buildResponse(response))
          .catch((err) => buildResponse(err.response ?? null));
    },
    deleteDataWithAuth: async (url) => {
        return API.delete(`${url}`)
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
}

import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import withAuthProvider from '../../services/withAuthProvider';
import MoreButton from '../MoreButton/MoreButton';
import { SkeletonNotification } from '../Skeleton/Skeleton';

const dat = [1, 2, 2];

const initialPaginate = {
  page: 1,
  total: 1,
};

export default function NotifikasiContent() {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState({ ...initialPaginate });

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const getNotifications = async () => {
    const params = {
      page: paginate.page,
      page_size: 5,
    };
    const response = await withAuthProvider.getDataWithAuth(
      `api/notification`,
      params
    );
    if (response?.status === 200) {
      const res = response.data.data;
      setNotifications((prev) => [...prev, ...res.data]);
      setPaginate((prev) => ({
        ...prev,
        total: res.last_page,
      }));
    } else {
      // console.log(response.response);
    }
  };

  const markAsRead = async () => {
    const body = {};
    const response = await withAuthProvider.postDataWithAuth(
      `/api/notification/seen-all`,
      body
    );
  };

  useEffect(() => {
    getNotifications().finally(() => setIsLoading(false));
    //eslint-disable-next-line
    markAsRead();
  }, [paginate.page]);

  return (
    <>
      <div className='tab-content-caready border-bottom'>
        <h3 className='title mb-0'>Notifikasi</h3>
      </div>
      <div className='overflow-hidden'>
        <div className='overflow-auto pb-4' style={{ maxHeight: '600px' }}>
          {isLoading
            ? dat.map((el, idx) => (
                <div
                  className='pt-3 font-sm'
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                  key={idx}
                >
                  <SkeletonNotification />
                </div>
              ))
            : notifications.map((notification, b) => (
                <div
                  className='pt-3 font-sm'
                  key={b}
                  style={{
                    backgroundColor:
                      notification?.seen_at === null
                        ? 'rgba(207,23,1,0.05)'
                        : '#FFF',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  <div className='d-flex mb-3'>
                    <div style={{ width: '30px' }}>
                      <div
                        className='d-inline-block bg-primary rounded-circle me-2'
                        style={{ width: '20px', height: '20px' }}
                      >
                        <div className='d-flex justify-content-center align-items-center h-100'>
                          <Icon />
                        </div>
                      </div>
                    </div>
                    <div className='d-inline'>
                      <div className='text-dark fw-bold'>
                        {notification?.type || '-'}
                      </div>
                      <div className='text-secondary'>
                        {notification?.message || '-'}
                      </div>
                      <div className='text-secondary font-xs'>
                        {format(
                          new Date(notification?.created_at),
                          'dd MMM yyyy, HH:mm'
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className='my-0' />
                </div>
              ))}
          {notifications.length === 0 && isLoading === false && (
            <div className='px-3 py-2 font-sm text-center text-secondary w-100'>
              Tidak ada Notifikasi
            </div>
          )}
        </div>
        {paginate.page !== paginate.total && <MoreButton onClick={loadMore} />}
      </div>
    </>
  );
}

const Icon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='20' height='20' rx='10' fill='#C20102' />
    <path
      d='M14 12.5H15V13.5H5V12.5H6V9C6 7.93913 6.42143 6.92172 7.17157 6.17157C7.92172 5.42143 8.93913 5 10 5C11.0609 5 12.0783 5.42143 12.8284 6.17157C13.5786 6.92172 14 7.93913 14 9V12.5ZM8.5 14.5H11.5V15.5H8.5V14.5Z'
      fill='white'
    />
  </svg>
);

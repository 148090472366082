import React from "react";
import parse from 'html-react-parser';

const Service = ({ title, desc, icon }) => {
  return (
    <>
      <div className="services">
        <div className="mb-2">
          <img
            src={icon}
            alt=""
            className="rounded-circle"
            style={{ width: 80, height: 80 }}
          />
        </div>
        <div className="title">{title}</div>
        <div className="desc">{desc ? parse(desc) : null}</div>
      </div>
    </>
  );
};

export default Service;

export default function ArrowRight({ className }) {
    return (
        <svg
        className={className} 
        width="8" 
        height="12" 
        viewBox="0 0 8 12" 
        fill="currentColor" 
        xmlns="http://www.w3.org/2000/svg">
            <path 
            d="M4.97656 6.00011L0.851562 1.87511L2.0299 0.696777L7.33323 6.00011L2.0299 11.3034L0.851562 10.1251L4.97656 6.00011Z" 
            fill="currentColor" />
        </svg>
    );
}


import React, { useState, useEffect } from 'react'
import { Button, Form, Spinner } from "react-bootstrap";
import toast from 'react-hot-toast';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import withoutAuth from '../../services/withoutAuthProvider';
import withAuth from '../../services/withAuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionsAuth } from '../../redux/auth.slice';
import decodeJwtResponse from "jwt-decode";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

export default function Auth() {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const google = window?.google;
    const [loadingFacebook, setLoadingFacebook] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);

    const handleClick = (path) => {
        push(path)
    }

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Silahkan mengisi e-mail"),
        password: Yup.string().required("Silahkan mengisi password"),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {

        const dataLogin = new FormData();

        dataLogin.append("email", data.email);
        dataLogin.append("password", data.password);

        const loading = toast.loading('Mengirimkan...');
        const response = await withoutAuth.postDataWithoutAuth('/api/login', dataLogin)
        if (response?.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
            reset()
            //Set token to withAuthProvider
            withAuth.setToken(response.data.data.accessToken);

            const responseUserData = await withAuth.getDataWithAuth('/api/account/user', {})
            if (responseUserData.status === 200) {
                dispatch(actionsAuth.setUser({ token: response.data.data.accessToken, user: responseUserData.data.data }));
            }
        } else {
            toast.dismiss(loading);
            if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {
                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key]
                    }
                }
                toast.error(data_message, {
                    id: 'error-message',
                    duration: 3000
                });
            } else {
                toast.error(response.data.message, {
                    id: 'error-message',
                    duration: 3000
                });
            }
        }

    }

    const responseFacebook = async (response) => {
        const dataUser = {
            id: response.id,
            email: response.email

        }

        setLoadingFacebook(true);
        const loading = toast.loading('Mengirimkan...');
        const responseAPI = await withoutAuth.postDataWithoutAuth('/api/login/social/facebook', dataUser)
        if (responseAPI.status === 200) {
            toast.dismiss(loading);
            toast.success(responseAPI.data.message, {
                id: 'success-message',
                duration: 3000
            });
            setLoadingFacebook(false);
            //Set token to withAuthProvider
            withAuth.setToken(responseAPI.data.data.accessToken);

            const responseUserData = await withAuth.getDataWithAuth('/api/account/user', {})
            if (responseUserData.status === 200) {
                dispatch(actionsAuth.setUser({ token: responseAPI.data.data.accessToken, user: responseUserData.data.data }));
            }

        } else {
            toast.dismiss(loading);
            toast.error(responseAPI.data.message, {
                id: 'error-message',
                duration: 3000
            });
            setLoadingFacebook(false);
        }
    }

    const responseGoogle = async (response) => {
        const responsePayload = decodeJwtResponse(response.credential);

        const dataUser = {
            id: responsePayload.sub,
            email: responsePayload.email
        }

        setLoadingGoogle(true);
        const loading = toast.loading('Mengirimkan...');
        const responseAPI = await withoutAuth.postDataWithoutAuth('/api/login/social/google', dataUser)
        if (responseAPI.status === 200) {
            toast.dismiss(loading);
            toast.success(responseAPI.data.message, {
                id: 'success-message',
                duration: 3000
            });
            setLoadingGoogle(false);
            //Set token to withAuthProvider
            withAuth.setToken(responseAPI.data.data.accessToken);

            const responseUserData = await withAuth.getDataWithAuth('/api/account/user', {})
            if (responseUserData.status === 200) {
                dispatch(actionsAuth.setUser({ token: responseAPI.data.data.accessToken, user: responseUserData.data.data }));
            }
        } else {
            toast.dismiss(loading);
            toast.error(responseAPI.data.message, {
                id: 'error-message',
                duration: 3000
            });
            setLoadingGoogle(false);
        }
        google?.accounts?.id?.disableAutoSelect();
    }

    useEffect(() => {
        google?.accounts?.id?.initialize({
            client_id: "366814934859-50ro7582kr5fo1ri80ftbmh36iqbe45n.apps.googleusercontent.com",
            callback: responseGoogle
        });
        google?.accounts?.id?.renderButton(
            document.getElementById("buttonGoogle"),
            { theme: "outline", size: "medium" }  // customization attributes
        );
    }, [])

    return (
        <>
            <div className="form-modal">
                <span className="text mb-3">Belum punya akun? <span className="text-primary cursor-pointer" onClick={() => handleClick("/daftar")}>Daftar</span></span>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label className="required">Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" {...register('email')} />
                        {errors.email?.message && <span className='error-message'>{errors.email?.message}</span>}
                    </Form.Group>

                    <Form.Group className="form-group" controlId="formBasicPassword">
                        <Form.Label className="required">Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" {...register('password')} />
                        {errors.password?.message && <span className='error-message'>{errors.password?.message}</span>}
                    </Form.Group>

                    <Form.Group className="group mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Ingat Saya" style={{ flex: "1" }} />
                        <div><span className="text-primary cursor-pointer" onClick={() => handleClick("/lupa-password")}><span style={{ float: "right !important" }}>Lupa Kata Sandi</span></span></div>
                    </Form.Group>

                    <Button className="button-login mb-3" style={{ display: "block !important", width: "100%" }} variant="primary" type="submit">
                        Selanjutnya
                    </Button>

                    <div className="mb-3">
                        <span className='text-line'>atau masuk dengan</span>
                    </div>
                    <div className="group">
                        <div id="buttonGoogle"></div>
                        {/* <Button type="button" className="cancel" variant="default">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.0834 9.15837C17.0834 8.44664 17.0257 7.92727 16.9007 7.38867H8.77344V10.601H13.5439C13.4478 11.3994 12.9284 12.6016 11.7742 13.4095L11.7581 13.517L14.3277 15.5077L14.5058 15.5255C16.1408 14.0154 17.0834 11.7937 17.0834 9.15837Z" fill="#4285F4" />
                                <path d="M8.77343 17.622C11.1106 17.622 13.0726 16.8525 14.5058 15.5253L11.7742 13.4092C11.0433 13.919 10.0622 14.2749 8.77343 14.2749C6.48436 14.2749 4.54153 12.7649 3.84897 10.6777L3.74746 10.6864L1.07547 12.7543L1.04053 12.8514C2.46398 15.6791 5.38786 17.622 8.77343 17.622Z" fill="#34A853" />
                                <path d="M3.84895 10.6779C3.66621 10.1393 3.56045 9.5622 3.56045 8.96591C3.56045 8.36956 3.66621 7.7925 3.83934 7.2539L3.83449 7.13919L1.12902 5.03809L1.0405 5.08019C0.453823 6.25361 0.117188 7.5713 0.117188 8.96591C0.117188 10.3605 0.453823 11.6782 1.0405 12.8516L3.84895 10.6779Z" fill="#FBBC05" />
                                <path d="M8.77348 3.65661C10.3989 3.65661 11.4953 4.35872 12.1205 4.94546L14.5635 2.56018C13.0632 1.16558 11.1106 0.30957 8.77348 0.30957C5.38789 0.30957 2.46399 2.25239 1.04053 5.08006L3.83937 7.25376C4.54156 5.16666 6.48439 3.65661 8.77348 3.65661Z" fill="#EB4335" />
                            </svg>
                            &nbsp;&nbsp;Google</Button> */}
                        <FacebookLogin
                            appId="1172350439997325"
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={renderProps => (
                                <Button type="button" className="cancel" variant="default" onClick={renderProps.onClick} disabled={loadingFacebook}>
                                    {loadingFacebook ? (<Spinner animation="border" size="sm" />) : ""}
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.49984 18.3332H10.7498V12.604H8.6665V9.99984H10.7498V7.9165C10.7498 6.19011 12.1484 4.7915 13.8748 4.7915H15.9582V7.39567H14.9165C14.3415 7.39567 13.8748 7.3415 13.8748 7.9165V9.99984H16.479L15.4373 12.604H13.8748V18.3332H16.9998C18.1484 18.3332 19.0832 17.3984 19.0832 16.2498V3.74984C19.0832 2.60053 18.1484 1.6665 16.9998 1.6665H4.49984C3.35053 1.6665 2.4165 2.60053 2.4165 3.74984V16.2498C2.4165 17.3984 3.35053 18.3332 4.49984 18.3332Z" fill="#4967AA" />
                                    </svg>
                                    &nbsp;&nbsp;Facebook</Button>
                            )}
                        />
                    </div>
                </Form>
            </div>
        </>
    )
}

import React from 'react';

const FullscreenLayout = (props) => {
    const { children } = props;

    return (
        <>
            <div className="w-100 position-relative" style={{ minHeight: '100vh', backgroundColor: '#F5F6F7' }}>
                {children}
            </div>
        </>
    )
}

export default FullscreenLayout;
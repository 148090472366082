import defBanner from '../assets/images/defaultBanner.png';

const defaultImageCar = 'https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true'

const defaultBlog = 'https://pertaniansehat.com/v01/wp-content/uploads/2015/08/default-placeholder.png';

const defaultBanner = defBanner;

export {
    defaultBanner, defaultImageCar, defaultBlog
}
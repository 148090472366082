import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { dateFormatter } from '../../../utils/string'

export const InfoCareadyContent = ({ data }) => (
    <Container>
        <Row>
            <Col md={{ span: 8, offset: 2 }}>
                <div className="article">
                    <span className="date">{data?.created_at ? dateFormatter(data.created_at, true) : '-'}</span>
                    <h1 className='title hover-primary'>{data?.title || '-'}</h1>
                    <div>
                        {
                            data?.tags && data?.tags.map((el, idx) => <span className='tag mx-1' key={idx}>{el.name}</span>)
                        }
                    </div>
                    <div className='image'>
                        <img src={data?.image_url} alt="" className="w-100 h-100" loading='lazy' />
                    </div>
                    <div className='blog-content' dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
            </Col>
        </Row>
    </Container>
)
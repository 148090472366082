import { Button, Modal } from "react-bootstrap";

const ModalLayout = ({
  show,
  handleClose,
  title = "",
  children,
  className,
  type = "",
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      contentClassName={`rounded-custom  mx-auto ${
        type === "home-banner" ? "bg-transparent border-0" : ""
      } ${className !== "" ? className : "modal-transaction"} ${
        title === "" ? "noheader" : ""
      }`}
    >
      <Modal.Header closeButton style={{ border: "none" }}>
        <div className="fw-semibold">{title}</div>
      </Modal.Header>
      <div className="px-3 pb-3">{children}</div>
    </Modal>
  );
};

export default ModalLayout;

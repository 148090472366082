export const TitipLelang1 = ({ className }) => {
    return (
        <svg className={className} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9472_46952)">
                <path d="M28 2.33301L47.173 6.59367C48.2393 6.83167 49 7.77667 49 8.87101V32.174C49 36.8547 46.6597 41.2273 42.7653 43.822L28 53.6663L13.2347 43.822C9.338 41.225 7 36.8547 7 32.1763V8.87101C7 7.77667 7.76067 6.83167 8.827 6.59367L28 2.33301ZM38.388 19.1843L26.838 30.732L20.2393 24.1333L16.94 27.4327L26.8403 37.333L41.6897 22.4837L38.388 19.1843Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_9472_46952">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const TitipLelang2 = ({ className }) => {
    return (
        <svg className={className} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9472_46984)">
                <path d="M14.8913 13.925C18.6101 10.9455 23.2349 9.32541 28 9.33301C32.9583 9.33301 37.5153 11.0503 41.1087 13.925L44.499 10.5347L47.7983 13.834L44.408 17.2243C46.876 20.314 48.4215 24.0377 48.8666 27.967C49.3117 31.8962 48.6383 35.8713 46.924 39.4347C45.2097 42.9982 42.5241 46.0052 39.1762 48.1097C35.8284 50.2142 31.9544 51.3307 28 51.3307C24.0456 51.3307 20.1716 50.2142 16.8238 48.1097C13.4759 46.0052 10.7903 42.9982 9.07603 39.4347C7.36172 35.8713 6.68837 31.8962 7.13347 27.967C7.57857 24.0377 9.12404 20.314 11.592 17.2243L8.20168 13.8363L11.501 10.537L14.8913 13.9273V13.925ZM30.3333 27.9997V17.488L18.6667 32.6663H25.6667V43.1663L37.3333 27.9997H30.3333ZM18.6667 2.33301H37.3333V6.99967H18.6667V2.33301Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_9472_46984">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const TitipLelang3 = ({ className }) => {
    return (
        <svg className={className} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9472_47016)">
                <path d="M4.66665 21.0003H11.6666V49.0003H4.66665C4.04781 49.0003 3.45432 48.7544 3.01673 48.3168C2.57915 47.8793 2.33331 47.2858 2.33331 46.6669V23.3336C2.33331 22.7148 2.57915 22.1213 3.01673 21.6837C3.45432 21.2461 4.04781 21.0003 4.66665 21.0003ZM17.017 17.9833L31.9503 3.04993C32.1488 2.85088 32.4125 2.73018 32.6928 2.71003C32.9732 2.68989 33.2514 2.77164 33.4763 2.94026L35.4666 4.43359C36.0194 4.84856 36.4369 5.41792 36.6666 6.06986C36.8962 6.7218 36.9277 7.42713 36.757 8.09693L34.0666 18.6669H49C50.2377 18.6669 51.4246 19.1586 52.2998 20.0338C53.175 20.9089 53.6666 22.0959 53.6666 23.3336V28.2429C53.6673 28.8528 53.5484 29.4568 53.3167 30.0209L46.095 47.5559C45.9188 47.9834 45.6196 48.349 45.2353 48.6061C44.851 48.8633 44.399 49.0005 43.9366 49.0003H18.6666C18.0478 49.0003 17.4543 48.7544 17.0167 48.3168C16.5791 47.8793 16.3333 47.2858 16.3333 46.6669V19.6329C16.3334 19.0141 16.5794 18.4207 17.017 17.9833Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_9472_47016">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const TitipL1 = ({ className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_9477_47074)">
                <path d="M12 1L20.217 2.826C20.674 2.928 21 3.333 21 3.802V13.789C21 15.795 19.997 17.669 18.328 18.781L12 23L5.672 18.781C4.002 17.668 3 15.795 3 13.79V3.802C3 3.333 3.326 2.928 3.783 2.826L12 1ZM16.452 8.222L11.502 13.171L8.674 10.343L7.26 11.757L11.503 16L17.867 9.636L16.452 8.222Z" fill="#C2000D" />
            </g>
            <defs>
                <clipPath id="clip0_9477_47074">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export const TitipL2 = ({ className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_9477_47078)">
                <path d="M6.382 5.968C7.97575 4.69107 9.95781 3.99674 12 4C14.125 4 16.078 4.736 17.618 5.968L19.071 4.515L20.485 5.929L19.032 7.382C20.0897 8.70615 20.7521 10.302 20.9428 11.986C21.1336 13.6699 20.845 15.3735 20.1103 16.9007C19.3756 18.4279 18.2246 19.7167 16.7898 20.6186C15.355 21.5205 13.6947 21.999 12 21.999C10.3053 21.999 8.64498 21.5205 7.2102 20.6186C5.77541 19.7167 4.62443 18.4279 3.88973 16.9007C3.15502 15.3735 2.86644 13.6699 3.0572 11.986C3.24796 10.302 3.9103 8.70615 4.96801 7.382L3.51501 5.93L4.92901 4.516L6.382 5.969V5.968ZM13 12V7.495L8.00001 14H11V18.5L16 12H13ZM8.00001 1H16V3H8.00001V1Z" fill="#C2000D" />
            </g>
            <defs>
                <clipPath id="clip0_9477_47078">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export const TitipL3 = ({ className }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_9477_47082)">
                <path d="M2 9.00011H5V21.0001H2C1.73478 21.0001 1.48043 20.8948 1.29289 20.7072C1.10536 20.5197 1 20.2653 1 20.0001V10.0001C1 9.73489 1.10536 9.48054 1.29289 9.293C1.48043 9.10547 1.73478 9.00011 2 9.00011ZM7.293 7.70711L13.693 1.30711C13.7781 1.22181 13.8911 1.17008 14.0112 1.16144C14.1314 1.15281 14.2506 1.18785 14.347 1.26011L15.2 1.90011C15.4369 2.07796 15.6158 2.32196 15.7143 2.60137C15.8127 2.88077 15.8262 3.18306 15.753 3.47011L14.6 8.00011H21C21.5304 8.00011 22.0391 8.21083 22.4142 8.5859C22.7893 8.96097 23 9.46968 23 10.0001V12.1041C23.0003 12.3655 22.9493 12.6244 22.85 12.8661L19.755 20.3811C19.6795 20.5643 19.5513 20.721 19.3866 20.8312C19.2219 20.9414 19.0282 21.0002 18.83 21.0001H8C7.73478 21.0001 7.48043 20.8948 7.29289 20.7072C7.10536 20.5197 7 20.2653 7 20.0001V8.41411C7.00006 8.14892 7.10545 7.8946 7.293 7.70711Z" fill="#C2000D" />
            </g>
            <defs>
                <clipPath id="clip0_9477_47082">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
import { createSlice } from '@reduxjs/toolkit'
// Slice
const unitSlice = createSlice({
    name: 'unit',
    initialState: {
        unit: [],
        loading: true,
        images: []
    },
    reducers: {
        setUnit: (state, action) => {
            state.unit = action.payload.unit;
            state.images = action.payload.images;
        },
        setLoading: (state, action) => {
            state.loading = action.payload.loading;
        }
    },
});

export const unitSelector = state => state.unitReducer.unit;
export const loadingSelector = state => state.unitReducer.loading;
export const imagesSelector = state => state.unitReducer.images;

export const { actions: actionsUnit, reducer } = unitSlice;
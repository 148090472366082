import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Row, Col } from "react-bootstrap";

const SkeletonCustom = ({
    height,
    width,
    count,
    className
}) => <Skeleton width={width} height={height} count={count} className={className} />

const SkeletonImage = () => <Skeleton height="100%" width="100%" />

const SkeletonNotification = () => (
    <div className="d-flex">
        <div style={{ width: '30px', height: '30px' }}>
            <div className="me-2 w-100 h-100 flex-center">
                <div style={{ width: '20px', height: '20px' }}>
                    <Skeleton circle height="100%" />
                </div>
            </div>
        </div>
        <div className="w-100 me-2">
            <Skeleton width="40%" />
            <Skeleton count={2} />
            <Skeleton width="50%" />
        </div>
    </div>
)

const SkeletonFavorite = () => (
    <div className="row h-100">
        <div className="col-md-3 pe-md-0 position-relative">
            <span className="position-absolute top-0 end-0 py-1 px-2 fw-semibold mt-2 me-4 me-md-2 font-sm text-primary bg-light rounded">
                <Skeleton width="20%" />
            </span>
            <Skeleton height={150} />
            {/* <img src={data?.stock_unit?.pictures[0]?.image_url || defaultImageCar} className='w-100 h-100' alt="" /> */}
        </div>
        <div className="col-md-6 ps-md-0">
            <div className="p-2 h-100">
                <Skeleton width="30%" />
                <div className="border-bottom py-2">
                    <Skeleton count={3} />
                </div>
                <div className="d-flex font-xs align-items-center my-2 justify-content-between w-100">
                    <Skeleton width={200} />
                </div>
            </div>
        </div>
        <div className="col-md-3 py-3 px-4">
            <div className="flex-center flex-column h-100">
                <Skeleton count={3} heigh="150%" />
            </div>
        </div>
    </div>
)

const SkeletonSearch = () => (
    <Skeleton height={40} />
)

const SkeletonNIPL = () => (
    <>
        <Skeleton width="30%" />
        <Skeleton width="40%" />
    </>
)

const SkeletonNIPLTagihan = () => (
    <>
        <div className='mb-2'>
            <Skeleton width="25%" />
        </div>
        <div className="row tagihan-gap">
            <div className="col-md-4">
                <Skeleton count={2} />
            </div>
            <div className="col-md-4">
                <Skeleton count={2} />
            </div>
            <div className="col-md-4">
                <div className="d-flex align-items-end justify-content-end h-100 fw-semibold">
                    <div className='w-100'>
                        <Skeleton width="75%" />
                    </div>
                </div>
            </div>
        </div>
    </>
)

const SkeletonProxyBid = () => (
    <div className="card-transaksi">
        <div className='body'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='d-flex mb-3 mb-md-0' style={{ borderRight: "1px solid #BEBFC2" }}>
                        <div>
                            <div className="unit-image">
                                <Skeleton height="100%" />
                            </div>
                        </div>
                        <div className="d-flex flex-column w-100 py-1">
                            <div className="border-bottom me-2 pb-1" style={{ marginLeft: "10px" }}>
                                <div className='w-100'>
                                    <Skeleton width="40%" />
                                </div>
                                <div className='w-100'>
                                    <Skeleton count={3} />
                                </div>
                            </div>
                            <div className="font-xs align-items-center py-2 px-2 justify-content-between">
                                <Skeleton width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='d-flex flex-column flex-md-row'>
                        <div className='w-100 d-flex flex-row flex-md-column w-100 justify-content-between mb-2'>
                            <div className='w-100'>
                                <Skeleton width="70%" className='mb-3' />
                            </div>
                            <div className='w-100'>
                                <Skeleton count={2} />
                            </div>
                        </div>
                        <div className='w-100 text-end'>
                            <Skeleton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const SkeletonLokasiKami = () => (
    <>
        <div className='d-none d-sm-block'>
            <div style={{ width: '180px', height: '180px', borderRadius: '10px' }}>
                <SkeletonImage />
            </div>
        </div>
        <div className='w-100'>
            <div style={{ marginLeft: '20px' }}>
                <Skeleton width="50%" className="mb-3" />
                <Skeleton count={4} />
                <Skeleton width="40%" className='mt-2' />
            </div>
        </div>
    </>
)

const SkeletonBlog = () => (
    <>
        <div className="rounded-custom" style={{ height: '200px' }}>
            <SkeletonImage />
        </div>
        <div className="my-3">
            <Skeleton width="20%" />
        </div>
        <div>
            <Skeleton />
            <Skeleton width="35%" />
        </div>
    </>
)

const SkeletonTransaction = () => (
    <div className="card-transaksi">
        <div className="header">
            <Row>
                <div className="col-md-6">
                    <span className="title"><Skeleton width="50%" /></span><br />
                    <span className='value'><Skeleton width="50%" /></span>
                </div>
                <div className="col-md-3">
                    <span className="title"><Skeleton width="50%" /></span><br />
                    <span className='value'><Skeleton width="50%" /></span>
                </div>
                <div className="col-md-3">
                    <span className="title"><Skeleton width="50%" /></span><br />
                    <span className='value'>
                        <Skeleton width="50%" />
                    </span>
                </div>
            </Row>
        </div>
        <div className='body'>
            <Row>
                <Col md={6}>
                    <div className='d-flex' style={{ borderRight: "1px solid #BEBFC2", height: "114px" }}>
                        <div>
                            <div className="unit-image" style={{ position: "relative", float: "left" }}>
                                <Skeleton height="100%" />
                            </div>
                        </div>
                        <div style={{ marginLeft: "10px", width: "100%" }}>
                            <Skeleton width="70%" className='mb-3' />
                            <Skeleton width="30%" />
                            <Skeleton width="50%" />
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='d-flex'>

                        <div style={{ flex: "1" }}>
                            <Skeleton width="30%" />
                            <Skeleton width="50%" />
                            <Skeleton width="30%" />
                            <Skeleton width="50%" />
                        </div>
                        <div className='button'>

                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
)

const SkeletonJadwal = () => (
    <div
        className="card-unit-jadwal h-100"
    >
        {/* {props.live === 1 ? (<CardRibbon></CardRibbon>) : ""} */}
        <div className="card-unit-jadwal-header overflow-hidden">
            {/* <img className="card-unit-jadwal-image" src={props.unit_type_image} alt="..." loading='lazy' /> */}
            {/* <img src={carStatic} alt="" loading='lazy' /> */}
            <SkeletonImage />
        </div>

        <div className="card-unit-jadwal-body d-flex flex-column">
            <div style={{ height: '50px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Skeleton count={2} />
            </div>
            <div className="card-unit-jadwal-date"><Skeleton width="50%" /></div>
            <div className="">
                <Skeleton count="2" width="80%" />
            </div>
        </div>
    </div>
)

const SkeletonUnitLelang = () => (
    <div className={`card-unit h-100`}>
        <div className="card-unit-header">
            <SkeletonImage />
        </div>
        <div className="card-unit-body d-flex flex-column">
            <Skeleton count={2} />
            <div className="">
                <Skeleton width="30%" />
            </div>
            <div className="card-unit-price d-table">
                <Skeleton width="60%" />
            </div>
            <div className="card-unit-location d-table">
            </div>
            <div className="card-unit-footer d-table mt-auto ">
                <div className="time-location">
                    <Skeleton width="80%" />
                </div>
            </div>
        </div>
    </div>
)

export {
    SkeletonCustom,
    SkeletonImage,
    SkeletonFavorite,
    SkeletonNIPL,
    SkeletonNIPLTagihan,
    SkeletonNotification,
    SkeletonProxyBid,
    SkeletonSearch,
    SkeletonLokasiKami,
    SkeletonBlog,
    SkeletonTransaction,
    SkeletonJadwal,
    SkeletonUnitLelang
}
import React from 'react'
import Time from '../../assets/icons/Time'
import Location from '../../assets/icons/Location'
import ArrowRight from '../../assets/icons/ArrowRight'
import '../../assets/css/component.css';
import CardRibbon from './CardRibbon';
// import imageCar from '../../assets/images/category-car.png'
import carStatic from '../../assets/images/iamgeAuc.webp'
import { useHistory } from 'react-router-dom';
import { format } from "date-fns";
import { id } from 'date-fns/esm/locale';

const CardUnitJadwal = (props, key) => {
  const { push } = useHistory();
  return (
    <div
      key={props.key}
      className={`card-unit-jadwal h-100 cursor-pointer ${props.live === 1 ? "live" : ""}`}
      onClick={props.onClick}
    // onClick={() => push("/unit-lelang", { dataFilter: { auction_id: props.id } })}
    >
      {props.live === 1 ? (<CardRibbon></CardRibbon>) : ""}
      <div className="card-unit-jadwal-header overflow-hidden">
        <img className="card-unit-jadwal-image" src={props.unit_type_image} alt="..." loading='lazy' />
        {/* <img src={carStatic} alt="" loading='lazy' /> */}
      </div>

      <div className="card-unit-jadwal-body d-flex flex-column">
        <div style={{ height: '50px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <h5 className="card-unit-jadwal-title">{props.judul}</h5>
        </div>
        <div className="card-unit-jadwal-date">{format(new Date(props?.tanggal), 'dd MMM yyyy', { locale: id })}</div>
        <div className="card-unit-jadwal-time d-flex">
          <Time /><span>{props.waktu}</span>
        </div>
        <div className="card-unit-jadwal-location d-flex">
          <Location /><span> {props.lokasi}</span>
        </div>
        {/* <div className="card-unit-jadwal-footer d-table mt-auto " style={{}}>
          <span className='cursor-pointer' style={{ display: "table - cell", verticalAlign: "middle", marginRight: "10px" }} onClick={() => push("/unit-lelang", { dataFilter: { auction_id: props.id } })}>Lihat Detail</span> <ArrowRight />
        </div> */}
      </div>
    </div>
  )
}

export default CardUnitJadwal;

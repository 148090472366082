import React, { useState } from 'react'
import { NiplActive, Riwayat, Tagihan } from './NiplChild';

const tabList = [
    { key: 'aktif', title: 'NIPL Aktif', component: NiplActive, active: true },
    { key: 'tagihan', title: 'Tagihan', component: Tagihan, active: false },
    { key: 'riwayat', title: 'Lihat Riwayat', component: Riwayat, active: false },
]

export default function NiplContent() {
    const [tabs, setTabs] = useState([...tabList]);

    const handleClick = (idx) => {
        setTabs(prev => prev.map((el, indx) => ({
            ...el,
            active: idx === indx,
        })))
    }

    const activeTabs = tabs.findIndex((el) => el.active);

    return (
        <>
            <div className='tab-content-caready'>
                <h3 className='title'>NIPL</h3>
                <ul className='mb-3 nav nav-tabs'>
                    {tabs.map((el, idx) => (
                        <li className='nav-item' key={idx}>
                            <button type="button" className={`nav-link ${el.active ? 'active' : ''}`} onClick={() => handleClick(idx)}>
                                {el.title}
                            </button>
                        </li>
                    ))}
                </ul>
                <div className='mb-3'>
                    {
                        activeTabs === 2 ? <Riwayat /> : activeTabs === 1 ? <Tagihan /> : <NiplActive />
                    }
                </div>
            </div>
        </>
    )
}

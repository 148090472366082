import footerYT from "../../assets/images/footerYT.png";
import footerIG from "../../assets/images/footerIG.png";
import footerFB from "../../assets/images/footerFB.png";
import footerLI from "../../assets/images/footerLI.png";

export const footerImages = [
  {
    image: footerFB,
    path: "https://www.facebook.com/BalaiLelangCaready",
  },
  {
    image: footerYT,
    path: "https://www.youtube.com/channel/UCxpoOwfOExeRrpGh9o_R4kg",
  },
  {
    image: footerIG,
    path: "https://www.instagram.com/balailelangcaready/",
  },
  // {
  //     image: footerLI,
  //     path: '/',
  // }
];

export const footerContent = [
  {
    title: "LAYANAN",
    child: [
      // {
      //     label: 'Cari Objek Lelang',
      //     path: '/cari-objek-lelang',
      // },
      {
        label: "Objek Lelang",
        path: "/unit-lelang",
      },
      {
        label: "Jadwal Lelang",
        path: "/jadwal-lelang-hari-ini",
      },
      {
        label: "Jadwal Lelang Quartal",
        path: "/jadwal-lelang-quartal",
      },
      {
        label: "Beli NIPL",
        path: "/beli-nipl",
      },
      {
        label: "Titip Lelang",
        path: "/titip-lelang",
      },
      // {
      //     label: 'Garansi',
      //     path: '/garansi',
      // },
    ],
  },
  {
    title: "INFORMASI",
    child: [
      {
        label: "Tentang Kami",
        path: "/tentang-kami",
      },
      {
        label: "Prosedur",
        path: "/prosedur",
      },
      {
        label: "Info Caready",
        path: "/info-caready",
      },
      {
        label: "Kebijakan Privasi",
        path: "/kebijakan-privasi",
      },
      {
        label: "Syarat dan Ketentuan",
        path: "/syarat-dan-ketentuan",
      },
    ],
  },
  {
    title: "BANTUAN",
    child: [
      {
        label: "Hubungi Kami",
        path: "/hubungi-kami",
      },
      {
        label: "FAQ",
        path: "/faq",
      },
      {
        label: "Lokasi Kami",
        path: "/lokasi-kami",
      },
    ],
  },
];

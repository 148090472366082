export const PrimaryButton = ({
    onClick,
    type = 'button',
    className,
    children,
    style
}) => <button type={type} className={`btn btn-primary font-sm ${className}`} onClick={onClick} style={style} >{children}</button>

const style = {
    borderRadius: '8px'
}
import { Dropdown } from "react-bootstrap"
import { useHistory } from "react-router-dom";
import ArrowBottom from "../../assets/icons/ArrowBottom";

export const DropdownJadwal = () => {
    const { push } = useHistory();
    return (
        <Dropdown align="end">
            <Dropdown.Toggle id="dropdown-basic" variant='light' className='disable-caret  cursor-pointer bg-header'>
                <div className='px-3 py-2 d-flex align-items-center'>
                    <p className="mb-0 me-1 text-secondary fw-bold hover-primary">Jadwal</p>
                    <ArrowBottom />
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '200px' }}>
                <Dropdown.ItemText >
                    <div className='dropdown-item-header fw-normal' onClick={() => { push('/jadwal-lelang-hari-ini') }}>Lelang hari ini</div>
                </Dropdown.ItemText>
                <Dropdown.ItemText >
                    <div className='dropdown-item-header fw-normal' onClick={() => { push('/jadwal-lelang-mendatang') }}>Lelang mendatang</div>
                </Dropdown.ItemText>
            </Dropdown.Menu>
        </Dropdown>
    )
}
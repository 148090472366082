import HeaderTop from '../../components/Header/HeaderTop.js';
import HeaderBottom from '../../components/Header/HeaderBottom.js';
import { useState } from 'react';
import HeaderMenu from '../../components/Header/HeaderMenu.js';

export default function Headers() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <header className='position-fixed top-0 vw-100' style={{ zIndex: '5' }}>
            <HeaderTop />
            <HeaderBottom setIsOpen={setIsOpen} />
            <HeaderMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        </header>
    )
}
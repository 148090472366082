import { format } from 'date-fns'

const DetailTagihanNIPL = (props) => {
    const invoice = props.invoice;
    return (
        <div className="font-sm">
            <div className="fw-semibold pb-2 mb-3">
                <div className="fs-6 mb-1">Total Tagihan</div>
                <div className="fs-5">Rp {invoice?.grand_total ? Number(invoice.grand_total).toLocaleString() : "-"}</div>
            </div>
            <div className="p-2 rounded font-xs mb-3 text-center" style={{ backgroundColor: 'rgba(207, 23, 1, 0.05)' }}>
                <div>Bayar Sebelum: <span className="fw-semibold">{invoice?.expiry_date ? format(new Date(`${invoice.expiry_date}`), 'dd MMM yyyy, HH:mm') : "-"}</span></div>
            </div>
            <div className="card p-2 mb-3 rounded-custom">
                <div className="d-flex pb-1 border-bottom justify-content-between mb-1">
                    <div>{invoice?.payment_bank_code ? invoice?.payment_bank_code.toUpperCase() : ""} Virtual Account</div>
                    <div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-1">
                    <div className="text-secondary">No. Virtual Account</div>
                    <div>{invoice?.payment_bank_virtual_account || "-"}</div>
                </div>
            </div>

            <div className="py-2 mb-2">
                <div className="font-sm fw-semibold mb-2">Rincian Pembayaran</div>
                <div className="card p-2 mb-3 rounded-custom">
                    <div className="fw-semibold font-sm mb-1">NIPL - {invoice?.unit_type_name || ""}</div>
                    <div className="mb-1 text-secondary">{invoice?.location_name || ""} - {invoice?.auction_detail}</div>
                    <div className="d-flex justify-content-between">
                        <div className="text-secondary">{invoice.quantity || 1} x {invoice?.price ? invoice.price.toLocaleString() : "-"}</div>
                        <div>Rp {invoice?.sub_total ? invoice.sub_total.toLocaleString() : "-"}</div>
                    </div>
                </div>
                <div className="card p-2 rounded-custom">
                    <div className="d-flex justify-content-between mb-1">
                        <div className="text-secondary">Subtotal</div>
                        <div>Rp {invoice?.sub_total ? invoice.sub_total.toLocaleString() : "-"}</div>
                    </div>
                    <div className="d-flex pb-2 border-bottom justify-content-between mb-1">
                        <div className="text-secondary">Biaya Layanan</div>
                        <div>Rp {invoice?.service_fee ? Number(invoice.service_fee).toLocaleString() : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="text-secondary">Total Bayar</div>
                        <div>Rp {invoice?.grand_total ? Number(invoice.grand_total).toLocaleString() : "-"}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DetailTagihanNIPL;
import Location from "../../assets/icons/Location";
import Time from "../../assets/icons/Time";
import CarouselLayout from "../../containers/Layouts/CarouselLayout/CarouselLayout";
import { RightContent } from "./RightContent";
import SingleUnit from "../../assets/images/single-unit.png";
import { useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  actionTransaction,
  transactionUnitSelector,
} from "../../redux/transaction.slice";
import withAuth from "../../services/withAuthProvider";
import { useState, useEffect } from "react";
import { replaceEnter } from "../../utils/string";
import SpecialNIPL from "../../assets/icons/SpecialNIPL";
import Coupon from "../../assets/icons/Coupon";
import EyeIcon from "../../assets/icons/EyeIcon";
import ModalLayout from "../../containers/Layouts/ModalLayout/ModalLayout";

const defaultImage =
  "https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true";

const DetailTransaski = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const [images, setImages] = useState([]);
  const [showFakturs, setShowFakturs] = useState(false);
  const [fakturs, setFakturs] = useState([]);
  const detail = useSelector(transactionUnitSelector);
  const dispatch = useDispatch();

  function renderStatus(param) {
    switch (param) {
      case "PENDING":
        return "<span class='badge-status warning'>Menunggu Pembayaran</span>";
      case "PAID":
        return "<span class='badge-status success'>Lunas</span>";
      case "WANPRESTASI":
        return "<span class='badge-status danger'>Wanprestasi</span>";
      case "OVERPAID":
        return "<span class='badge-status danger'>Overpaid</span>";
      default:
        return "";
    }
  }

  const getTransaction = async () => {
    const imageURL = [];
    const response = await withAuth.getDataWithAuth(
      "/api/trx-invoice-unit/transaction/unit/" + id,
      {}
    );
    if (response?.status === 200) {
      const dataTransaction = response.data.data;
      if (dataTransaction.stock_unit.pictures.length > 0) {
        dataTransaction.stock_unit.pictures.map((image, i) =>
          imageURL.push({ image: image.image_url })
        );
        setImages(imageURL);
      }
      dispatch(actionTransaction.setUnit({ data: dataTransaction }));
      toast.success(response.data.message, {
        id: "success-message",
        duration: 3000,
      });
    } else {
      toast.error(response.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getTransaction();
  }, []);

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-8 mb-3">
          <div>
            <div className="card rounded-custom p-3 mb-3">
              <div className="row d-flex justify-content-between text-secondary font-sm">
                <div className="col-md-6 mb-2">
                  <div className="fw-semibold">No.Invoice</div>
                  <div>{detail?.transaction_number}</div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="fw-semibold">Tanggal Pembelian</div>
                  <div>
                    {detail?.created_at
                      ? format(
                          new Date(`${detail?.created_at}`),
                          "dd MMM yyyy, HH:mm"
                        ) + " WIB"
                      : "-"}
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="fw-semibold">Status</div>
                  <div>
                    <span
                      className="value"
                      dangerouslySetInnerHTML={{
                        __html: renderStatus(detail?.status || ""),
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card rounded-custom p-3 mb-3">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="unit-images w-100 h-100 mb-2"
                    style={{ paddingTop: 0 }}
                  >
                    <CarouselLayout>
                      {images.length > 0 ? (
                        images.slice(0, 5).map((image, i) => (
                          <div>
                            {/* <img src={image.image} alt="Unit 5" /> */}
                            <div
                              className="with-background detail-unit-slider transaction-detail"
                              style={
                                image.image
                                  ? { background: `url("${image.image}")` }
                                  : {}
                              }
                            ></div>
                          </div>
                        ))
                      ) : (
                        <div>
                          <div
                            className="with-background detail-unit-slider transaction-detail"
                            style={
                              defaultImage
                                ? { background: `url(${defaultImage})` }
                                : {}
                            }
                          ></div>
                        </div>
                      )}
                    </CarouselLayout>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-between align-items-start fw-bold mb-2">
                    <div className="pe-3">
                      <div className="fw-normal">
                        <span className="py-1 px-2 rounded bg-primary text-light font-sm">
                          Lot{" "}
                          {detail?.stock_unit?.lot_number &&
                          detail?.auction?.auction_lane_name
                            ? detail?.auction?.auction_lane_name +
                              detail?.stock_unit?.lot_number
                            : "-"}
                        </span>
                        {detail?.result?.number_of_nipl > 1 && (
                          <span
                            className="nomor-lot nipl"
                            style={{ marginLeft: 5 }}
                          >
                            <span className="icon-nipl">
                              <SpecialNIPL />
                            </span>
                            Special NIPL
                          </span>
                        )}
                      </div>
                      <div className="fs-4">
                        {detail?.stock_unit?.unit_name ||
                          detail?.stock_unit?.year +
                            " " +
                            detail?.stock_unit?.unit_maker?.name +
                            " " +
                            detail?.stock_unit?.unit_model?.name}
                      </div>
                    </div>
                    <div className="border border-primary border-1 rounded text-center text-primary p-2">
                      <div className="font-sm">Grade</div>
                      <div className="fs-4 mb-0">
                        {detail?.stock_unit?.unit_inspection?.grade || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="text-secondary font-sm">Harga Final</div>
                    <div className="fs-4 fw-bold">
                      Rp.{" "}
                      {detail?.final_price
                        ? Number(detail?.final_price).toLocaleString()
                        : "-"}
                    </div>
                    {detail?.result?.number_of_nipl > 1 && (
                      <>
                        <div className="coupon-footer d-flex">
                          <Coupon />
                          <p
                            className="m-0"
                            style={{
                              borderRight: "none",
                            }}
                          >
                            <span>{detail.result.number_of_nipl} NIPL</span>{" "}
                            yang terpakai
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="text-primary cursor-pointer disable-copy fw-semibold mb-4"
                    onClick={() =>
                      push("/unit-lelang/" + detail?.stock_unit?.id)
                    }
                    style={{ fontSize: "larger" }}
                  >
                    Lihat Detail Unit
                  </div>
                  <div className="text-secondary font-sm">Faktur Pajak</div>
                  <div
                    className="border rounded-custom font-sm px-2 py-1 col-4 text-center cursor-pointer mt-2"
                    onClick={() => {
                      setShowFakturs(true);
                      setFakturs(detail?.stock_unit?.fakturs);
                    }}
                  >
                    <EyeIcon />
                    &nbsp;&nbsp; Lihat
                  </div>
                </div>
              </div>
            </div>
            <div className="card rounded-custom p-3">
              <div className="row">
                <div className="col-md-6 fw-bold mb-3">
                  <div className="fs-5 mb-1">
                    {detail?.stock_unit?.location?.name || "-"}
                  </div>
                  <div>
                    {detail?.auction?.date_start && detail?.auction?.time_start
                      ? format(
                          new Date(
                            `${detail?.auction.date_start} ${detail?.auction.time_start}`
                          ),
                          "dd MMM yyyy"
                        )
                      : "-"}
                  </div>
                </div>
                <div className="col-md-6 text-secondary font-sm">
                  <div className="d-flex align-items-top mb-2">
                    <Location />
                    <div
                      className="ms-2"
                      dangerouslySetInnerHTML={{
                        __html: detail?.stock_unit?.location?.detail
                          ? replaceEnter(detail?.stock_unit?.location?.detail)
                          : "-",
                      }}
                    ></div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Time />
                    <div className="ms-2">
                      {detail?.auction?.date_start &&
                      detail?.auction?.time_start
                        ? format(
                            new Date(
                              `${detail.auction.date_start} ${detail.auction.time_start}`
                            ),
                            "HH:mm"
                          )
                        : "-"}{" "}
                      -{" "}
                      {detail?.auction?.date_start &&
                      detail?.auction?.time_finish
                        ? format(
                            new Date(
                              `${detail.auction.date_start} ${detail.auction.time_finish}`
                            ),
                            "HH:mm"
                          )
                        : "-"}{" "}
                      WIB
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card rounded-custom p-3">
            <RightContent />
          </div>
        </div>
      </div>

      <ModalLayout
        show={showFakturs}
        handleClose={() => setShowFakturs(false)}
        title="Faktur Pajak"
        className="w-75"
      >
        <div className="row">
          {fakturs?.length ? (
            fakturs.map((faktur) => (
              <>
                <div className="col-9">{faktur?.filename}</div>
                <a
                  className="col-2 border rounded-custom font-sm px-2 py-1 text-center cursor-pointer"
                  href={faktur?.file_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <EyeIcon />
                  &nbsp;&nbsp; Lihat
                </a>
              </>
            ))
          ) : (
            <div>Tidak ada data Faktur Pajak</div>
          )}
        </div>
      </ModalLayout>
    </div>
  );
};

export default DetailTransaski;

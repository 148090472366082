import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

function ArtworkPending({ status }) {
    const { push } = useHistory();

    return (
        <div className='page pb-3 live-auction'>
            <Container className='center-horizontal-vertical' style={{ height: "calc(100vh - 2em)" }}>
                <div className='d-flex flex-column'>
                    <div className='center-horizontal-vertical mb-3'>
                        <svg width="74" height="68" viewBox="0 0 74 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M62 0.320312H54C51.8 0.320312 50 2.12031 50 4.32031C50 6.52031 48.2 8.32031 46 8.32031C43.8 8.32031 42 6.52031 42 4.32031C42 2.12031 40.2 0.320312 38 0.320312H28C25.8 0.320312 24 2.12031 24 4.32031C24 6.52031 22.2 8.32031 20 8.32031C17.8 8.32031 16 6.52031 16 4.32031C16 2.12031 14.2 0.320312 12 0.320312H4C1.8 0.320312 0 2.12031 0 4.32031V54.3203C0 56.5203 1.8 58.3203 4 58.3203H34.32C33.26 55.6403 32.64 52.7403 32.64 49.6803C32.64 36.7803 43.1 26.3203 56 26.3203C59.58 26.3203 62.96 27.1603 66 28.6003V4.32031C66 2.12031 64.2 0.320312 62 0.320312ZM12 48.6803C9.8 48.6803 8 46.8803 8 44.6803C8 42.4803 9.8 40.6803 12 40.6803C14.2 40.6803 16 42.4803 16 44.6803C16 46.8803 14.2 48.6803 12 48.6803ZM12 36.6803C9.8 36.6803 8 34.8803 8 32.6803C8 30.4803 9.8 28.6803 12 28.6803C14.2 28.6803 16 30.4803 16 32.6803C16 34.8803 14.2 36.6803 12 36.6803ZM24 48.6803C21.8 48.6803 20 46.8803 20 44.6803C20 42.4803 21.8 40.6803 24 40.6803C26.2 40.6803 28 42.4803 28 44.6803C28 46.8803 26.2 48.6803 24 48.6803ZM24 36.6803C21.8 36.6803 20 34.8803 20 32.6803C20 30.4803 21.8 28.6803 24 28.6803C26.2 28.6803 28 30.4803 28 32.6803C28 34.8803 26.2 36.6803 24 36.6803ZM24 24.6803C21.8 24.6803 20 22.8803 20 20.6803C20 18.4803 21.8 16.6803 24 16.6803C26.2 16.6803 28 18.4803 28 20.6803C28 22.8803 26.2 24.6803 24 24.6803ZM36 24.6803C33.8 24.6803 32 22.8803 32 20.6803C32 18.4803 33.8 16.6803 36 16.6803C38.2 16.6803 40 18.4803 40 20.6803C40 22.8803 38.2 24.6803 36 24.6803Z" fill="#8C8D8F" />
                            <path d="M56 31.6807C46.06 31.6807 38 39.7407 38 49.6807C38 59.6207 46.06 67.6807 56 67.6807C65.94 67.6807 74 59.6207 74 49.6807C74 39.7407 65.94 31.6807 56 31.6807ZM58.5 50.6807C58.5 52.0607 57.38 53.1807 56 53.1807H46C44.62 53.1807 43.5 52.0607 43.5 50.6807C43.5 49.3007 44.62 48.1807 46 48.1807H53.5V38.6807C53.5 37.3007 54.62 36.1807 56 36.1807C57.38 36.1807 58.5 37.3007 58.5 38.6807V50.6807Z" fill="#8C8D8F" />
                        </svg>
                    </div>
                    <div className='title'>
                        {
                            status === 'COMING' && (
                                'Lelang Belum Dimulai'
                            )
                        }
                        {
                            status === 'PENDING' && (
                                'Lelang sedang di jeda'
                            )
                        }
                        {
                            status === 'CLOSED' && (
                                'Lelang telah selesai'
                            )
                        }
                    </div>
                    {
                        status === 'COMING' && (
                            <div className="subtitle mb-3">
                                Silahkan ikuti lelang sesuai jadwal
                            </div>
                        )
                    }
                    {
                        status !== 'CLOSED' && (
                            <Button className="btn-primary-caready mb-3" variant="primary" type="button" onClick={() => window.location.reload()}>
                                Refresh halaman ini
                            </Button>
                        )
                    }
                    {
                        status !== 'PENDING' && (
                            <Button className="btn-primary-caready mb-3" variant="secondary" type="button" onClick={() => push("/masuk")}>
                                Kembali ke Halaman Utama
                            </Button>
                        )
                    }
                </div>
            </Container >
        </div >
    )
}

export default ArtworkPending
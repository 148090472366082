import CardUnit from "../CardUnit/CardUnit";
import ArrowRightSearch from "../../assets/icons/ArrowRightSearch";
import ArrowRight from "../../assets/icons/ArrowRight";
import CardUnitJadwal from "../CardUnit/CardUnitJadwal";
import FilterHome from "../Filters/FilterHome";
import withoutAuth from "../../services/withoutAuthProvider";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import CarouselMultipleLayout from "../../containers/Layouts/CarouselLayout/CarouselMultipleLayout";
import CarouselLayout from "../../containers/Layouts/CarouselLayout/CarouselLayout";
import { defaultBlog } from "../../utils/defaultValue";
import { userSelector } from "../../redux/auth.slice";
import { Container } from "react-bootstrap";
import withAuthProvider from "../../services/withAuthProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  SkeletonCustom,
  SkeletonImage,
  SkeletonJadwal,
  SkeletonUnitLelang,
} from "../Skeleton/Skeleton";
import { actionsPage } from "../../redux/page.slice";

const defaultImage =
  "https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true";

export function SearchSection() {
  const [banner, setBanner] = useState([]);
  const [units, setUnit] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const { push } = useHistory();
  const today = format(new Date(), "yyyy-MM-dd");
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [updatePasswordAlert, setUpdatePasswordAlert] = useState(false);
  const [loadingAuction, setLoadingAuction] = useState(true);
  const [loadingUnit, setLoadingUnit] = useState(true);
  const [loadingBanner, setLoadingBanner] = useState(true);
  const [filterData, setFilterData] = useState({
    page_size: 8,
    grade: null,
    sort_by: null,
    sort_order: null,
    start_auction_date: null,
    end_base_price: null,
  });
  const isLogged = useSelector((state) => state.authReducer.isLogged);
  const user = useSelector(userSelector);

  const handlerFilterData = (value) => {
    setFilterData((data) => {
      return {...data, ...value};
    });
  };

  const getUnit = async () => {
    const response = await withoutAuth.getDataWithoutAuth("/api/unit", filterData);
    if (response?.status === 200) {
      const dataUnit = response.data.data.data;
      setUnit(dataUnit);
      setLoadingUnit(false);
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const getAuctions = async () => {
    const params = {
      start_date: today,
      page_size: 10,
      page: 1,
    };

    const response = await withoutAuth.getDataWithoutAuth("/api/auction", params);
    if (response?.status === 200) {
      const dataAUciotn = response.data.data.data;
      setAuctions(dataAUciotn);
      setLoadingAuction(false);
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const getBanner = async () => {
    const params = {
      page_size: 5,
      page: 1,
      type: "HOME",
    };

    const response = await withoutAuth.getDataWithoutAuth(
      "/api/banner",
      params
    );
    if (response?.status === 200) {
      const dataBanner = response.data.data.data;
      // const dataBanner = [
      //   {
      //     file_url: newBanner,
      //   }
      // ]
      // console.log('ini banner', dataBanner)
      setBanner(dataBanner);
      setLoadingBanner(false);
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const getProfile = async () => {
    const response = await withAuthProvider.getDataWithAuth("/api/account/profile", {});
    if (response?.status === 200) {
      const dataProfile = response.data.data;
      localStorage.setItem("user_id", dataProfile.user_id);
      if (
        dataProfile?.full_name == null ||
        dataProfile?.country_id === null ||
        dataProfile?.city_id === null ||
        dataProfile?.npwp_number === null ||
        dataProfile?.personal_id_file_url == null ||
        dataProfile?.personal_id_number == null ||
        dataProfile?.personal_id_address == null ||
        dataProfile?.current_address == null ||
        dataProfile?.date_of_birth == null ||
        dataProfile?.email == null ||
        dataProfile?.phone_number == null
      ) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
      }
    } else {
      toast.error(response?.data.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getUnit();
  }, [filterData]);

  useEffect(() => {
    getUnit();
    getAuctions();
    getBanner();
    if (isLogged) getProfile();
    if(user?.required_action === 'change_password') setUpdatePasswordAlert(true);
  }, []);

  useEffect(() => {
    if (!isLogged) setShowAlert(false);
  }, [isLogged]);

  return (
    <div className="position-relative">
      {showAlert && (
        <div
          className="position-absolute alert-caready warning mb-3"
          style={{ top: "0", zIndex: "2" }}
        >
          <Container>
            <div className="group" style={{ justifyContent: "space-between" }}>
              <div>
                Halo {user?.name}, segera lengkapi informasi pribadi Anda untuk mempercepat proses lelang.{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => push("/pengaturan/profile")}
                >
                  <strong>Lengkapi Sekarang</strong>
                </span>
              </div>
              <div
                className="center-horizontal-vertical cursor-pointer"
                onClick={() => setShowAlert(false)}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.00011 4.82129L10.1251 0.696289L11.3034 1.87462L7.17844 5.99962L11.3034 10.1246L10.1251 11.303L6.00011 7.17796L1.87511 11.303L0.696777 10.1246L4.82178 5.99962L0.696777 1.87462L1.87511 0.696289L6.00011 4.82129Z"
                    fill="#1E1E1F"
                  />
                </svg>
              </div>
            </div>
          </Container>
        </div>
      )}
      {updatePasswordAlert && (
        <div
          className="position-absolute alert-caready warning"
          style={{ top: showAlert ? 45 : 0, zIndex: "2" }}
        >
          <Container>
            <div className="group" style={{ justifyContent: "space-between" }}>
              <div>
                Untuk meningkatkan keamanan, mohon untuk mengupdate password Anda.{" "}
                <span className="cursor-pointer" onClick={() => push("/pengaturan/profile", {active: 'ubah-kata-sandi'})}>
                  <strong>Update Sekarang</strong>
                </span>
              </div>
              <div
                className="center-horizontal-vertical cursor-pointer"
                onClick={() => setShowAlert(false)}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.00011 4.82129L10.1251 0.696289L11.3034 1.87462L7.17844 5.99962L11.3034 10.1246L10.1251 11.303L6.00011 7.17796L1.87511 11.303L0.696777 10.1246L4.82178 5.99962L0.696777 1.87462L1.87511 0.696289L6.00011 4.82129Z"
                    fill="#1E1E1F"
                  />
                </svg>
              </div>
            </div>
          </Container>
        </div>
      )}
      <CarouselLayout
        className="home-slider"
        arrowClass="home-slider-arrow"
        arrowStroke="#1E1E1F"
        dots={false}
      >
        {loadingBanner ? (
          <div className="with-background home-slider-item">
            <SkeletonImage />
          </div>
        ) : banner.length > 0 ? (
          banner.slice(0, 5).map((image, i) => (
            <div
              onClick={() => window.open(image?.link || "/")}
              key={"banner-" + i + 1}
            >
              <div
                className="with-background home-slider-item"
                style={
                  image?.file_url
                    ? {
                        background: `url("${image?.file_url}")`,
                        cursor: "pointer",
                      }
                    : {}
                }
              ></div>
            </div>
          ))
        ) : (
          <div>
            <div
              className="with-background home-slider-item"
              style={
                defaultImage
                  ? { background: `url(${defaultBlog})`, height: "547px" }
                  : { height: "547px" }
              }
            ></div>
          </div>
        )}
      </CarouselLayout>
      <FilterHome />

      <div className="container mb-4">
        {loadingAuction ? (
          <SkeletonCustom width="30%" />
        ) : (
          <div className="d-flex">
            <div className="fs-5 me-4 fw-bold">Jadwal Mendatang</div>
            <div
              className="hover-primary font-sm d-flex align-items-center cursor-pointer"
              onClick={() => push("/jadwal-lelang-mendatang")}
            >
              Lihat Semua
              <ArrowRight className="hover-primary ms-2" />
            </div>
          </div>
        )}

        <div className="auction-list">
          {loadingAuction ? (
            <CarouselMultipleLayout
              slideToShow={5}
              totalItem={5}
              // center
            >
              {new Array(5).fill(1).map((el, idx) => (
                <div key={`auctions-${idx + 1}`} className="col-md-3 col-6">
                  <SkeletonJadwal />
                </div>
              ))}
            </CarouselMultipleLayout>
          ) : auctions.length > 0 ? (
            <CarouselMultipleLayout
              slideToShow={5}
              totalItem={auctions.length}
              // center
            >
              {auctions.map((el, b) => (
                <div key={`auction-${b + 1}`} className="col-md-3 col-6">
                  <CardUnitJadwal
                    id={el?.id}
                    judul={el?.name || "-"}
                    tanggal={el?.date_start}
                    waktu={`${el?.time_start?.substr(
                      0,
                      5
                    )} - ${el?.time_finish?.substr(0, 5)} WIB`}
                    lokasi={el?.location?.name || "-"}
                    onClick={() => {
                      if (el?.is_exclusive) {
                        window.open(
                          "https://" +
                            el?.exclusive_seller_slug +
                            "." +
                            window.location.hostname +
                            "/unit-lelang"
                        );
                      } else {
                        if (
                          el?.auction_category !== "TIME-BID" &&
                          el?.is_started
                        ) {
                          push(`/live-auction/${el?.id}`);
                        } else {
                          dispatch(actionsPage.setPage({ page: 0 }));
                          push("/unit-lelang", {
                            dataFilter: {
                              auction_id: el?.id,
                              unit_type_id: el?.unit_type?.id,
                              isTimeBid: el?.auction_category === "TIME-BID",
                            },
                          });
                        }
                      }
                    }}
                    live={el?.is_started}
                    unit_type_image={el?.unit_type?.banner_url}
                  />
                </div>
              ))}
            </CarouselMultipleLayout>
          ) : (
            <div className="w-100 text-center">Tidak ada jadwal</div>
          )}
        </div>
      </div>

      <div className="container mb-5 overflow-hidden">
        <div className="my-3 w-100 overflow-auto">
          <div className="d-flex" style={{ gap: "15px" }}>
            {loadingUnit ? (
              new Array(5).fill(1).map((el, indx) => (
                <div style={{ width: "201px" }} key={`skeleton unit-${indx}`}>
                  <div
                    className="rounded-custom"
                    style={{ width: "180px", height: "80px" }}
                  >
                    <SkeletonImage />
                  </div>
                </div>
              ))
            ) : (
              <>
                <div style={{ width: "201px" }} key={0}>
                  <div
                    key={0}
                    className={`d-inline-block fw-bold rounded-custom cursor-pointer p-3 ${
                      filterData.start_auction_date != null
                        ? "bg-primary text-light"
                        : "bg-grey text-dark"
                    }`}
                    style={{ width: "200px", height: "80px" }}
                    onClick={() =>
                      handlerFilterData(
                        filterData.start_auction_date != null
                          ? { start_auction_date: null }
                          : {
                              start_auction_date: format(
                                new Date(),
                                "yyyy-MM-dd"
                              ),
                            }
                      )
                    }
                  >
                    Lelang Mendatang
                  </div>
                </div>

                <div style={{ width: "201px" }} key={0}>
                  <div
                    key={0}
                    className={`d-inline-block fw-bold rounded-custom cursor-pointer p-3 ${
                      filterData.sort_by != null &&
                      filterData.sort_order != null
                        ? "bg-primary text-light"
                        : "bg-grey text-dark"
                    }`}
                    style={{ width: "200px", height: "80px" }}
                    onClick={() =>
                      handlerFilterData(
                        filterData.sort_by != null &&
                          filterData.sort_order != null
                          ? { sort_by: null, sort_order: null }
                          : { sort_by: "base_price", sort_order: "asc" }
                      )
                    }
                  >
                    Harga Terendah
                  </div>
                </div>

                <div style={{ width: "201px" }} key={0}>
                  <div
                    key={0}
                    className={`d-inline-block fw-bold rounded-custom cursor-pointer p-3 ${
                      filterData.grade != null
                        ? "bg-primary text-light"
                        : "bg-grey text-dark"
                    }`}
                    style={{ width: "200px", height: "80px" }}
                    onClick={() =>
                      handlerFilterData(
                        filterData.grade != null
                          ? { grade: null }
                          : { grade: "A" }
                      )
                    }
                  >
                    Grade A
                  </div>
                </div>

                <div style={{ width: "201px" }} key={0}>
                  <div
                    key={0}
                    className={`d-inline-block fw-bold rounded-custom cursor-pointer p-3 ${
                      filterData.end_base_price != null
                        ? "bg-primary text-light"
                        : "bg-grey text-dark"
                    }`}
                    style={{ width: "200px", height: "80px" }}
                    onClick={() =>
                      handlerFilterData(
                        filterData.end_base_price != null
                          ? { end_base_price: null }
                          : { end_base_price: 100000000 }
                      )
                    }
                  >
                    Harga Dibawah 100juta
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row">
          {loadingUnit ? (
            new Array(8).fill(1).map((el, idx) => (
              <div className="col-md-3 col-6 mb-4" key={`unis-${idx + 1}`}>
                <SkeletonUnitLelang />
              </div>
            ))
          ) : units.length > 0 ? (
            units.map((item, c) => {
              const year = item?.unit_informations?.find(
                (unit_information) => unit_information.label === "Tahun"
              );

              const odometer = item?.unit_informations?.find(
                (unit_information) => unit_information.label === "Odometer"
              );

              const transmisi = item?.unit_informations?.find(
                (unit_information) => unit_information.label === "Transmisi"
              );

              const fuel = item?.unit_informations?.find(
                (unit_information) => unit_information.label === "Bahan Bakar"
              );

              const validityPeriod = item?.unit_documents?.find(
                (unit_document) => unit_document.label === "STNK"
              );

              const unitDetail = {
                police_number: item?.police_number,
                year: year?.value,
                odometer: odometer?.value,
                transmisi: transmisi?.value,
                fuel: fuel?.value,
                validityPeriod: validityPeriod?.additional_value,
              };

              let link;
              if (item?.auction?.is_exclusive) {
                link =
                  item?.auction?.exclusive_seller_slug +
                  "." +
                  window.location.hostname +
                  "/unit-lelang/" +
                  item.id;
              } else {
                link = "/unit-lelang/" + item.id;
                if (
                  item?.auction?.auction_category !== "TIME-BID" &&
                  item?.auction?.is_started === 1 && isLogged
                ) {
                  link = `/live-auction/${item?.auction?.id}`;
                } else {
                  link = "/unit-lelang/" + item.id;
                }
              }

              return (
                <div className="col-md-3 col-6 mb-4" key={`units-${c + 1}`}>
                  <CardUnit
                    link={link}
                    lot_number={
                      item?.lot_number && item?.auction?.auction_lane_name
                        ? item?.auction?.auction_lane_name + item?.lot_number
                        : "-"
                    }
                    price={item?.base_price}
                    tags={item?.tags || []}
                    nama={
                      item?.unit_name ||
                      item?.year +
                        " " +
                        item?.unit_maker?.name +
                        " " +
                        item?.unit_model?.name
                    }
                    location={item?.auction?.location_name}
                    auction={item?.auction}
                    pictures={item?.pictures}
                    grade={item?.unit_inspection?.grade}
                    // onClick={() => {
                    //   if (item?.auction?.is_exclusive) {
                    //     window.open(
                    //       item?.auction?.exclusive_seller_slug +
                    //         "." +
                    //         window.location.hostname +
                    //         "/unit-lelang/" +
                    //         item.id
                    //     );
                    //   } else {
                    //     console.log(item?.auction?.auction_category)
                    //     if (
                    //       item?.auction?.auction_category !== "TIME-BID" &&
                    //       item?.auction?.is_started
                    //     ) {
                    //       push(`/live-auction/${item?.auction?.id}`);
                    //     } else {
                    //       push("/unit-lelang/" + item.id);
                    //     }
                    //   }
                    // }}
                    unitType={item?.unit_type?.name}
                    unitDetail={unitDetail}
                  />{" "}
                </div>
              );
            })
          ) : (
            <div className="col-12 text-secondary text-center my-4">
              Data unit tidak ditemukan
            </div>
          )}
        </div>
        <div className="d-flex flex-center my-2">
          <div
            className="home-lihat-selengkapnya rounded-custom border bg-white px-3 py-1 cursor-pointer font-sm"
            onClick={() => {
              // push("/unit-lelang?" + new URLSearchParams(filterData).toString())
              dispatch(actionsPage.setPage({ page: 0 }));
              push("/unit-lelang", {
                dataFilter: { ...filterData },
              });
            }}
          >
            Lihat Selengkapnya
            <ArrowRightSearch className="ms-2 text-dark" />
          </div>
        </div>
      </div>
    </div>
  );
}

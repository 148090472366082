import React, { useState } from 'react'
import PurchaseTransactionContent from './SubContent/PurchaseTransactionContent';
import SalesTransactionContent from './SubContent/SalesTransactionContent';

const tabList = [
    { key: 'purchase', title: 'Pembelian', component: PurchaseTransactionContent, active: true },
    { key: 'sales', title: 'Penjualan', component: SalesTransactionContent, active: false }
]

const TransaksiContent = () => {
    const [tabs, setTabs] = useState([...tabList]);

    const handleClick = (idx) => {
        setTabs(prev => prev.map((el, indx) => ({
            ...el,
            active: idx === indx,
        })))
    }

    const activeTabs = tabs.findIndex((el) => el.active);

    return (
        <>
            <div className='tab-content-caready'>
                <h3 className='title'>Transaksi</h3>
                <ul className='mb-3 nav nav-tabs'>
                    {tabs.map((el, idx) => (
                        <li className='nav-item' key={idx}>
                            <button type="button" className={`nav-link ${el.active ? 'active' : ''}`} onClick={() => handleClick(idx)}>
                                {el.title}
                            </button>
                        </li>
                    ))}
                </ul>
                <div className='mb-3'>
                    {
                        activeTabs === 0 ? <PurchaseTransactionContent /> : <SalesTransactionContent />
                    }
                </div>
            </div>
        </>
    )
}

export default TransaksiContent;

import { format } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
// import ModalLayout from '../../../containers/Layouts/ModalLayout/ModalLayout';
import withAuth from '../../../services/withAuthProvider';
// import SettingTransaksiBayar from '../../Modal/SettingTransaksiBayar/SettingTransaksiBayar';
import MoreButton from '../../MoreButton/MoreButton';
import { SkeletonTransaction } from '../../Skeleton/Skeleton';

const defaultImage = 'https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true'

const ChildSales = (props) => {
  const detail = props.data;
  return (
    <div className="card-transaksi position-relative">
      <div className="header">
        <Row>
          <div className="col-md-6">
            <span className="title d-none d-sm-block mb-2">No.Invoice</span>
            <span className='value invoice-number'>{detail?.transaction_number}</span>
          </div>
          <div className="col-md-3">
            <span className="title d-none d-sm-block mb-2">Tanggal Pembelian</span>
            <span className='value invoice-number'>01 Feb 2022, 09:30 WIB</span>
          </div>
          <div className="col-md-3">
            <span className="title d-none d-sm-block mb-2">Status</span>
            <span className='value invoice-number'><span className='badge-status warning'>Menunggu Pembayaran</span></span>
          </div>
        </Row>
      </div>
      <div className='body'>
        <Row>
          <Col md={6}>
            <div className='d-flex mb-3 mb-md-0 info-unit'>
              <div>
                <div className="unit-image" style={(detail?.stock_unit?.pictures?.length > 0) ? { position: "relative", float: "left", background: `url("${detail?.stock_unit?.pictures[0].image_url}")` } : { position: "relative", float: "left", background: `url(${defaultImage})` }}></div>
              </div>
              <div className="d-flex flex-column w-100 py-1" style={{ marginLeft: "10px" }}>
                <div className='me-2 pb-1'>
                  <div className='unit-name'>{detail?.stock_unit?.unit_name || detail?.stock_unit?.year + " " + detail?.stock_unit?.unit_maker?.name + " " + detail?.stock_unit?.unit_model?.name}</div>
                  <span className="title">Harga Awal</span><br />
                  <span className="harga final">Rp {detail?.stock_unit?.base_price ? Number(detail?.stock_unit?.base_price).toLocaleString() : "-"}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className='row info-tagihan'>
              <div className="col-md-6">
                <span className="title">Harga Final</span><br />
                <span className="harga tagihan">Rp {detail?.final_price ? Number(detail?.final_price).toLocaleString() : "-"}</span><br />
                <span className="title">Tanggal Kedaluwarsa</span><br />
                <span className="tanggal">{detail?.expiry_date ? format(new Date(`${detail?.expiry_date}`), 'dd MMM yyyy, HH:mm') + "WIB" : "-"} </span>
              </div>
              <div className='col-md-6 button'>
                <a className="btn-detail cursor-pointer disable-copy" onClick={props.onClick}>Lihat detail ringkasan</a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const initialPaginate = {
  page: 1,
  total: 1
}

function SalesTransactionContent() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const { push } = useHistory();

  const loadMore = () => {
    setPaginate(prev => ({
      ...prev,
      page: prev.page + 1,
    }))
  }

  const getData = async () => {
    const params = {
      page: paginate.page,
      page_size: 5
    }

    const response = await withAuth.getDataWithAuth('/api/trx-invoice-unit/selling/unit', params)
    if (response?.status === 200) {

      //if(Object.keys(response.data).length === 0){
      const res = response.data.data;
      setData((last_data) => [
        ...last_data,
        ...res.data,
      ]);

      setPaginate(prev => ({
        ...prev,
        total: res.last_page,
      }))

      setIsLoading(false);
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000
      });
    }
  }

  useEffect(() => {
    getData().finally(() => setIsLoading(false));
  }, [paginate.page])

  return (
    <Row>
      {
        isLoading ?
          new Array(3).fill(1).map((el, idx) => (
            <div key={idx}>
              <SkeletonTransaction />
            </div>
          )) :
          data.map((el, idx) => (
            <Col key={idx} md={12}>
              <ChildSales data={el} onClick={() => push('/pengaturan/transaksi/penjualan/' + el.id)} />
            </Col>
          ))
      }
      {
        paginate.page !== paginate.total && <MoreButton onClick={loadMore} />
      }
      {
        data.length === 0 && isLoading === false &&
        <div className="px-3 py-2 font-sm text-center text-secondary w-100">Tidak ada transaksi</div>
      }
    </Row>
  )
}

export default SalesTransactionContent
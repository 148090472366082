import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import Time from "../../assets/icons/Time";
import Location from "../../assets/icons/Location";
import CardUnit from "../../components/CardUnit/CardUnit";
// import Success from '../../assets/icons/Success'
import Warning from "../../assets/icons/Warning";
import { LightBox } from "react-lightbox-pack";
import "react-lightbox-pack/dist/index.css";
import "../../assets/css/component.css";
import CarouselLayout from "../../containers/Layouts/CarouselLayout/CarouselLayout";
import { useParams, useHistory } from "react-router-dom";
import withoutAuth from "../../services/withoutAuthProvider";
import toast from "react-hot-toast";
import { format } from "date-fns";
import moment from "moment";
import CarouselMultipleLayout from "../../containers/Layouts/CarouselLayout/CarouselMultipleLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  actionsUnit,
  unitSelector,
  imagesSelector,
  loadingSelector,
} from "../../redux/unit.slice";
import {isDate, replaceEnter} from "../../utils/string";
import PageNotFound from "../Errors/PageNotFound";
import ModalLayout from "../../containers/Layouts/ModalLayout/ModalLayout";
import Image from "../../components/Images/Images";
import {
  SkeletonCustom,
  SkeletonImage,
  SkeletonUnitLelang,
} from "../../components/Skeleton/Skeleton";
import SpecialNIPL from "../../assets/icons/SpecialNIPL";
import OfferForm from "../../components/TimeBid/OfferForm";
import WarningInv from "../../assets/icons/Warning-Inv";
import PPNIcon from "../../assets/icons/PPNIcon";
import withAuthProvider from "../../services/withAuthProvider";

const defaultImage =
  "https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true";

const initialModal = {
  show: false,
  data: "",
};

export default function DetailUnit() {
  // State
  const [SpecialNIPLToggle, setSpecialNIPLToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [Ppn, setPpn] = useState({
    show: false, // ppn_buyer && ppn_buyer_include === true
    include: null, // 1 true , 0 false // ppn_buyer_include
    value: null, // ppn_buyer
    include_change: null,
  });
  const [PpnShown, setPpnShown] = useState(false);
  const [sIndex, setSIndex] = useState(0);
  const unit = useSelector(unitSelector);
  const images = useSelector(imagesSelector);
  const loading = useSelector(loadingSelector);
  const { id } = useParams();
  const { push } = useHistory();
  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState({ ...initialModal });
  const [auctionEndedTime, setAuctionEndedTime] = useState();
  const [timeBidUnitDetail, setTimeBidUnitDetail] = useState();

  const timeBidAuctionStart =
    unit?.auction?.auction_category === "TIME-BID" &&
    unit?.auction?.status === "STARTED";

  //
  const dispatch = useDispatch();
  // Handler
  const showModal = (idx) => {
    const found = unit?.unit_inspection?.result_inspections?.find(
      (el, i) => i === idx
    );
    setModalState((prev) => ({
      ...prev,
      show: true,
      data: found.image_url,
    }));
  };

  const closeModal = () =>
    setModalState((prev) => ({
      ...prev,
      show: false,
    }));

  const imgError = () => {
    setModalState((prev) => ({
      ...prev,
      data: defaultImage,
    }));
  };

  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".navigation-sticky");
    const scrollTop = window.scrollY;
    scrollTop >= 90
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);

  const informasiunitRef = useRef(null);
  const inspeksiRef = useRef(null);
  const produkterkaitRef = useRef(null);

  const sectionRefs = [
    { section: "informasi-unit", ref: informasiunitRef },
    { section: "inspeksi", ref: inspeksiRef },
    { section: "produk-terkait", ref: produkterkaitRef },
  ];

  const scrollTo = (ele) => {
    ele.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  const getUnit = async () => {
    dispatch(actionsUnit.setLoading({ loading: true }));
    const imageURL = [];
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/unit/" + id,
      {}
    );
    if (response?.status === 200) {
      const dataUnit = response.data.data;
      if (dataUnit.pictures.length > 0) {
        dataUnit.pictures.map((image, i) =>
          imageURL.push({ image: image.image_url })
        );
        // setImages(image_url)
      }

      if (dataUnit?.number_of_nipl > 1)
        setSpecialNIPLToggle(dataUnit.number_of_nipl);
      else setSpecialNIPLToggle(false);

      if (dataUnit.ppn_buyer !== null && dataUnit.ppn_buyer_include !== null)
        setPpn({
          show: true,
          include: dataUnit.ppn_buyer_include,
          value: dataUnit.ppn_buyer,
          include_change: dataUnit.ppn_buyer_fee,
        });

      setData(dataUnit);
      dispatch(actionsUnit.setUnit({ unit: dataUnit, images: imageURL }));

      dispatch(actionsUnit.setLoading({ loading: false }));
    } else {
      toast.error(response?.data?.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const getTimeBidUnitDetail = async () => {
    const response = await withAuthProvider.getDataWithAuth(
      "/api/unit-auth/" + id,
      {}
    );
    if (response?.status === 200) {
      const timeBidUnitDetail = response.data.data;

      setTimeBidUnitDetail(timeBidUnitDetail);
    } else {
      toast.error(response?.data?.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    scrollToTop();
    getUnit();
  }, [id]);

  const checkDateFormat = (date, formateNote) => {
    try {
      return format(date, formateNote);
    } catch (error) {
      console.log("error", error);
      return "";
    }
  };

  // useEffect(() => {
  //   getUnit();
  // }, [])

  const handleTooltip = (show, mobile = false) => {
    if (mobile) {
      setPpnShown(true);
      setTimeout(() => {
        setPpnShown(false);
      }, 2000);
    } else {
      let time = show ? 0 : 10000;
      setTimeout(() => {
        setPpnShown(show);
      }, time);
    }
  };

  useEffect(() => {
    if (!unit?.loading) {
      const endedTime = moment(
        `${unit?.auction?.date_finish} ${unit?.auction?.time_finish}`
      ).valueOf();
      setAuctionEndedTime(endedTime);
    }
  }, [unit]);

  return (
    <>
      {unit ? (
        <>
          <div className="navigation-sticky" ref={headerRef}>
            <Container>
              <ul>
                <li
                  className={`${
                    visibleSection === "informasi-unit" ? "active" : ""
                  }`}
                >
                  <a
                    onClick={() => {
                      scrollTo(informasiunitRef.current);
                    }}
                  >
                    Informasi Unit
                  </a>
                </li>
                <li
                  className={`${visibleSection === "inspeksi" ? "active" : ""}`}
                >
                  <a
                    onClick={() => {
                      scrollTo(inspeksiRef.current);
                    }}
                  >
                    Inspeksi
                  </a>
                </li>
                <li
                  className={`${
                    visibleSection === "produk-terkait" ? "active" : ""
                  }`}
                >
                  <a
                    onClick={() => {
                      scrollTo(produkterkaitRef.current);
                    }}
                  >
                    Produk Terkait
                  </a>
                </li>
              </ul>
            </Container>
          </div>

          <LightBox
            state={toggle}
            event={lightBoxHandler}
            data={images}
            imageWidth="60vw"
            imageHeight="70vh"
            thumbnailHeight={122}
            thumbnailWidth={163}
            setImageIndex={setSIndex}
            imageIndex={sIndex}
            allowZoom={true}
          />
          <div className="page h-100 pb-3">
            <div
              style={{
                background: "#FFFFFF",
                paddingBottom: "40px",
                paddingTop: "40px",
              }}
            >
              <div className="container">
                <Row>
                  <Col md={7}>
                    <div className="unit-images  w-100 h-100">
                      <CarouselLayout dots={true}>
                        {loading ? (
                          <div className="with-background detail-unit detail-unit-slider">
                            <SkeletonImage />
                          </div>
                        ) : images.length > 0 ? (
                          images.slice(0, 5).map((image, i) => (
                            <div>
                              <div
                                className="with-background detail-unit detail-unit-slider"
                                style={
                                  image.image
                                    ? { background: `url("${image.image}")` }
                                    : {}
                                }
                              ></div>
                            </div>
                          ))
                        ) : (
                          <div>
                            <div
                              className="with-background detail-unit detail-unit-slider"
                              style={
                                defaultImage
                                  ? { background: `url(${defaultImage})` }
                                  : {}
                              }
                            ></div>
                          </div>
                        )}
                      </CarouselLayout>
                      {loading ? (
                        ""
                      ) : (
                        <a
                          onClick={() => {
                            lightBoxHandler(true, 0);
                          }}
                        >
                          <span className="unit-image-button image-slider">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.00002 6C2.00002 5.448 2.45502 5 2.99202 5H21.008C21.556 5 22 5.445 22 6V20C22 20.552 21.545 21 21.008 21H2.99202C2.86115 20.9997 2.73161 20.9736 2.61085 20.9232C2.4901 20.8727 2.38049 20.7989 2.28832 20.706C2.19615 20.6131 2.12324 20.5029 2.07376 20.3818C2.02429 20.2606 1.99923 20.1309 2.00002 20V6ZM14 18C15.3261 18 16.5979 17.4732 17.5356 16.5355C18.4732 15.5979 19 14.3261 19 13C19 11.6739 18.4732 10.4021 17.5356 9.46447C16.5979 8.52678 15.3261 8 14 8C12.6739 8 11.4022 8.52678 10.4645 9.46447C9.5268 10.4021 9.00002 11.6739 9.00002 13C9.00002 14.3261 9.5268 15.5979 10.4645 16.5355C11.4022 17.4732 12.6739 18 14 18ZM4.00002 7V9H7.00002V7H4.00002ZM4.00002 2H10V4H4.00002V2Z"
                                fill="white"
                              />
                            </svg>
                            &nbsp;&nbsp;{unit?.pictures?.length || 0} Gambar
                          </span>
                        </a>
                      )}
                    </div>
                  </Col>

                  <Col className="unit-container" md={5}>
                    <div className="unit-detail">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>
                          {loading ? (
                            <>
                              <SkeletonCustom width="30%" />
                              <SkeletonCustom height="30px" width="300px" />
                              <div className="d-flex w-100 mt-3 gap-2">
                                <SkeletonCustom
                                  width="100px"
                                  className="d-inline-block"
                                />
                                <SkeletonCustom
                                  width="100px"
                                  className="d-inline-block"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="badge-lot d-flex align-items-center">
                                <span className="nomor-lot">
                                  Lot{" "}
                                  {unit?.lot_number &&
                                  unit?.auction?.auction_lane_name
                                    ? unit?.auction?.auction_lane_name +
                                      unit?.lot_number
                                    : "-"}
                                </span>
                                {SpecialNIPLToggle && (
                                  <span className="nomor-lot nipl">
                                    <span className="icon-nipl">
                                      <SpecialNIPL />
                                    </span>
                                    Special NIPL
                                  </span>
                                )}
                                {unit?.auction?.auction_category ===
                                  "TIME-BID" && (
                                  <div className="metode blue">TIME BID</div>
                                )}
                              </div>
                              <div className="unit-name mb-1 mt-1">
                                {unit?.unit_name ||
                                  unit?.year +
                                    " " +
                                    unit?.unit_maker?.name +
                                    " " +
                                    unit?.unit_model?.name}
                              </div>
                              <div className="unit-counter">
                                <span>
                                  <svg
                                    width="20"
                                    height="18"
                                    viewBox="0 0 20 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.3666 2.84172C16.941 2.41589 16.4356 2.0781 15.8794 1.84763C15.3232 1.61716 14.727 1.49854 14.1249 1.49854C13.5229 1.49854 12.9267 1.61716 12.3705 1.84763C11.8143 2.0781 11.3089 2.41589 10.8833 2.84172L9.99994 3.72506L9.1166 2.84172C8.25686 1.98198 7.0908 1.49898 5.87494 1.49898C4.65908 1.49898 3.49301 1.98198 2.63327 2.84172C1.77353 3.70147 1.29053 4.86753 1.29053 6.08339C1.29053 7.29925 1.77353 8.46531 2.63327 9.32506L3.5166 10.2084L9.99994 16.6917L16.4833 10.2084L17.3666 9.32506C17.7924 8.89943 18.1302 8.39407 18.3607 7.83785C18.5912 7.28164 18.7098 6.68546 18.7098 6.08339C18.7098 5.48132 18.5912 4.88514 18.3607 4.32893C18.1302 3.77271 17.7924 3.26735 17.3666 2.84172V2.84172Z"
                                      stroke="#9AA2B1"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  &nbsp;&nbsp;{unit.count_wishlist || 0}{" "}
                                  difavoritkan
                                </span>
                                <span>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_100_742)">
                                      <path
                                        d="M0.833496 9.99992C0.833496 9.99992 4.16683 3.33325 10.0002 3.33325C15.8335 3.33325 19.1668 9.99992 19.1668 9.99992C19.1668 9.99992 15.8335 16.6666 10.0002 16.6666C4.16683 16.6666 0.833496 9.99992 0.833496 9.99992Z"
                                        stroke="#9AA2B1"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                                        stroke="#9AA2B1"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_100_742">
                                        <rect
                                          width="20"
                                          height="20"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  &nbsp;&nbsp;{unit.count_view || 0} dilihat
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <div>
                          <div className="grade-unit-detail">
                            {loading ? (
                              ""
                            ) : (
                              <>
                                Grade
                                <br />
                                <span className="grade-detail">
                                  {unit?.unit_inspection?.grade || "-"}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {loading ? (
                        <>
                          <div className="mt-3">
                            <SkeletonCustom />
                            <SkeletonCustom height="30px" />
                          </div>
                          <div className="mt-3">
                            <SkeletonCustom />
                            <SkeletonCustom height="25px" />
                          </div>
                          <div className="mt-3">
                            <SkeletonCustom width="40%" count={2} />
                          </div>
                        </>
                      ) : (
                        <div>
                          <span className="unit-title">Harga Dasar Lelang</span>
                          <div className="d-flex">
                            <span className="unit-price" style={{textDecoration: unit?.is_cancel_auction !== "0" ? "line-through" : "none", textDecorationColor: 'red', textDecorationThickness: '3px'}}>
                              Rp{" "}
                              {unit.base_price
                                ? unit.base_price.toLocaleString()
                                : "-"}
                            </span>
                            {Ppn.show && !Ppn.include && (
                              <div
                                className="card-unit-ppn"
                                style={{ marginLeft: 8 }}
                              >
                                <span className="icon-ppn">
                                  <PPNIcon />
                                </span>
                                PPN {Ppn.value}%
                              </div>
                            )}
                          </div>
                          {/* <OverlayTrigger
                            placement='right'
                            delay={{ show: 100, hide: 40000 }}
                            overlay={renderTooltip}
                          > */}
                          {Ppn.show && Ppn.include ? (
                            <div
                              className="tax-detail"
                              onMouseEnter={() => handleTooltip(true)}
                              onMouseLeave={() => handleTooltip(false)}
                              onClick={() => handleTooltip(true, true)}
                            >
                              {Ppn.include === 1 ? "Include" : "Exclude"} PPN
                              <WarningInv />
                              {PpnShown && (
                                <div className="tooltip-hover">
                                  <span>
                                    Harga{" "}
                                    {Ppn.include === 1 ? "sudah" : "belum"}{" "}
                                    termasuk PPN {Ppn.value}%
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : null}

                          {/* </OverlayTrigger> */}
                          <div className="unit-note">
                            <div
                              className="left"
                              style={{
                                border: SpecialNIPLToggle ? "" : "none",
                              }}
                            >
                              <span
                                className="unit-title"
                                style={{ margin: "0" }}
                              >
                                Kelipatan Bid
                              </span>
                              <span className="unit-bid-price">
                                Rp{" "}
                                {unit?.unit_type?.multiple_bid
                                  ? unit?.unit_type?.multiple_bid.toLocaleString()
                                  : "-"}
                              </span>
                            </div>
                            {SpecialNIPLToggle && (
                              <div className="right">
                                <Warning color="#FF6A39" />
                                <span
                                  className="unit-title"
                                  style={{
                                    margin: "0",
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: 7,
                                  }}
                                >
                                  Untuk Menawar Membutuhkan <br />
                                  <span className="unit-bid-price nipl">
                                    {SpecialNIPLToggle} NIPL
                                  </span>
                                </span>
                              </div>
                            )}
                          </div>
                          <span className="unit-title">Highlight</span>
                          <div className="unit-highlight">
                            {unit.tags
                              ? unit.tags.map((data, i) => (
                                  <span>{data.name}</span>
                                ))
                              : ""}
                          </div>
                        </div>
                      )}
                      <div className="unit-lelang-info">
                        {loading ? (
                          <div className="d-flex justify-content-between align-items-center">
                            <SkeletonCustom
                              className="d-inline-block"
                              width="200px"
                              height="20px"
                            />
                            <SkeletonCustom
                              className="d-inline-block"
                              width="150px"
                            />
                          </div>
                        ) : (
                          <div className="lelang-title">
                            {unit?.auction?.location_name || "-"}
                            <a href="/unit-lelang">
                              <span className="link">
                                Lihat Detail &nbsp;
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.97656 6.00011L0.851562 1.87511L2.0299 0.696777L7.33323 6.00011L2.0299 11.3034L0.851562 10.1251L4.97656 6.00011Z"
                                    fill="#092540"
                                  />
                                </svg>
                              </span>
                            </a>
                          </div>
                        )}
                        {loading ? (
                          <div className="mt-4">
                            <SkeletonCustom count={4} />
                          </div>
                        ) : (
                          <>
                            <div className="lelang-date">
                              {unit?.auction?.date_start &&
                              unit?.auction?.time_start
                                ? checkDateFormat(
                                    new Date(
                                      `${unit.auction.date_start} ${unit.auction.time_start}`
                                    ),
                                    "dd MMM yyyy"
                                  )
                                : "-"}{" "}
                            </div>
                            <div className="lelang-time">
                              <Time />{" "}
                              <span>
                                {unit?.auction?.date_start &&
                                unit?.auction?.time_start
                                  ? checkDateFormat(
                                      new Date(
                                        `${unit.auction.date_start} ${unit.auction.time_start}`
                                      ),
                                      "HH:mm"
                                    )
                                  : "-"}{" "}
                                -{" "}
                                {unit?.auction?.date_start &&
                                unit?.auction?.time_finish
                                  ? checkDateFormat(
                                      new Date(
                                        `${unit.auction.date_start} ${unit.auction.time_finish}`
                                      ),
                                      "HH:mm"
                                    )
                                  : "-"}{" "}
                                WIB
                              </span>
                            </div>
                            <div className="lelang-location">
                              <Location />{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: unit?.location?.detail
                                    ? replaceEnter(unit?.location?.detail)
                                    : "-",
                                }}
                              ></span>
                            </div>
                          </>
                        )}
                      </div>
                      {loading ? (
                        <SkeletonCustom width="40%" />
                      ) : (
                        <div className="hotline">
                          <span>Butuh Bantuan?</span>
                          <a
                            className="cursor-pointer"
                            onClick={() => push("/hubungi-kami")}
                          >
                            <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.5 11.6833V14.63C15.5001 14.841 15.4202 15.0441 15.2763 15.1985C15.1325 15.3528 14.9355 15.4469 14.725 15.4617C14.3608 15.4867 14.0633 15.5 13.8333 15.5C6.46917 15.5 0.5 9.53083 0.5 2.16667C0.5 1.93667 0.5125 1.63917 0.538333 1.275C0.553102 1.06454 0.647151 0.867509 0.801503 0.723674C0.955855 0.579839 1.15902 0.499905 1.37 0.5H4.31667C4.42003 0.499896 4.51975 0.538216 4.59644 0.607517C4.67313 0.676818 4.72133 0.772152 4.73167 0.875C4.75083 1.06667 4.76833 1.21917 4.785 1.335C4.95061 2.49077 5.29 3.61486 5.79167 4.66917C5.87083 4.83583 5.81917 5.035 5.66917 5.14167L3.87083 6.42667C4.97038 8.98871 7.01212 11.0305 9.57417 12.13L10.8575 10.335C10.91 10.2617 10.9865 10.2091 11.0737 10.1864C11.161 10.1637 11.2535 10.1723 11.335 10.2108C12.3892 10.7116 13.513 11.0501 14.6683 11.215C14.7842 11.2317 14.9367 11.25 15.1267 11.2683C15.2294 11.2789 15.3245 11.3271 15.3936 11.4038C15.4628 11.4805 15.501 11.5801 15.5008 11.6833H15.5Z"
                                  fill="#CF1701"
                                />
                              </svg>
                              &nbsp;&nbsp;&nbsp;Hubungi Kami
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              className="container section"
              style={{}}
              id="informasi-unit"
              ref={informasiunitRef}
            >
              <div className="informasi-unit">
                <Row>
                  <Col md={12}>
                    {loading ? (
                      <SkeletonCustom
                        width="25%"
                        height="25px"
                        className="mb-4"
                      />
                    ) : (
                      <h3>Informasi Unit</h3>
                    )}
                  </Col>
                  {loading
                    ? new Array(8).fill(1).map((el, j) => (
                        <Col md={6} key={`ini-${j}`}>
                          <div className="info w-100 d-flex justify-content-between align-items-center">
                            <SkeletonCustom width="200px" />
                            <SkeletonCustom width="200px" />
                          </div>
                        </Col>
                      ))
                    : unit.unit_informations
                    ? unit.unit_informations.map((info, i) => (
                        <Col md={6}>
                          <div className="info w-100">
                            <span className="title-info">{info.label}</span>
                            <span className="value">{isDate(info.value) ? moment(info.value).format('DD MMM YYYY') : info.value || '-'}</span>
                          </div>
                        </Col>
                      ))
                    : ""}
                  {loading
                    ? new Array(8).fill(1).map((el, k) => (
                        <Col md={6} key={`isi-${k}`}>
                          <div className="info w-100 d-flex justify-content-between align-items-center">
                            <SkeletonCustom width="200px" />
                            <SkeletonCustom width="200px" />
                          </div>
                        </Col>
                      ))
                    : unit.unit_documents
                    ? unit.unit_documents.map((document, i) => (
                        <Col md={6}>
                          <div className="info w-100">
                            <span className="title-info">{document.label}</span>
                            <span className="value">
                              {document.value || "-"}{" "}
                              {document?.additional_value !== null
                                ? ` (${document?.additional_value})`
                                : ""}
                            </span>
                          </div>
                        </Col>
                      ))
                    : ""}
                  <Col md={12}>
                    {loading ? (
                      <>
                        <SkeletonCustom
                          width="25%"
                          height="25px"
                          className="mb-4"
                        />
                        <SkeletonCustom count={3} />
                      </>
                    ) : (
                      <>
                        <h3>Catatan</h3>
                        <p>{unit.document_note || "-"}</p>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="container section" id="inspeksi" ref={inspeksiRef}>
              <div className="inspeksi-kendaraan">
                <Row>
                  <Col md={12}>
                    {loading ? (
                      <SkeletonCustom
                        width="25%"
                        height="25px"
                        className="mb-4"
                      />
                    ) : (
                      <h3>
                        {unit?.unit_type?.name === "Motor" ||
                        unit?.unit_type?.name === "Mobil"
                          ? "Inspeksi Kendaraan"
                          : "Inspeksi Barang"}
                      </h3>
                    )}
                  </Col>
                  {loading
                    ? new Array(4).fill(1).map((el, k) => (
                        <Col md={6}>
                          <div
                            className="info w-100 d-flex justify-content-between align-items-center"
                            key={`isu-${k}`}
                          >
                            <SkeletonCustom width="200px" height="20px" />
                            <SkeletonCustom width="100px" height="20px" />
                          </div>
                        </Col>
                      ))
                    : unit?.unit_inspection?.result_inspection_categories
                    ? unit?.unit_inspection?.result_inspection_categories
                        .slice(0, 10)
                        .map((data_inspeksi, i) => (
                          <Col md={6}>
                            <div className="info w-100">
                              <span className="title-info">
                                {data_inspeksi.inspection_category_name}
                              </span>
                              {/* ini bagian kanan */}
                              <span className="minimal-value">
                                /{" "}
                                {
                                  data_inspeksi?.master_category
                                    ?.item_inspection_count
                                }
                                {/* <span className="status">Lulus</span>&nbsp;<Warning /> */}
                              </span>
                              {/* ini bagian kiri */}
                              <span className="value">
                                {data_inspeksi?.all_result_count} &nbsp;
                              </span>
                            </div>
                          </Col>
                        ))
                    : ""}
                  <Col md={12}>
                    <div className="info w-100">
                      {loading ? (
                        <>
                          <SkeletonCustom width="20%" />
                          <SkeletonCustom count={2} />
                        </>
                      ) : (
                        <>
                          <span className="title-info">Catatan</span>
                          <div className="description">
                            {unit?.unit_inspection?.note || "-"}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="info w-100">
                      <span className="title-info">Terdeteksi</span>
                      <div className="description list-image-inspeksi">
                        <div
                          className="group result-inspek"
                          style={{ overflowX: "scroll" }}
                        >
                          {loading
                            ? new Array(9).fill(1).map((el, l) => (
                                <div
                                  className="image-inspeksi"
                                  key={`imgs-${l}`}
                                >
                                  <SkeletonImage />
                                </div>
                              ))
                            : unit?.unit_inspection?.result_inspections &&
                              unit?.unit_inspection?.result_inspections.map(
                                (category, i) => {
                                  return (
                                    category.image_url !== null && (
                                      <
                                        // key={`terdeteksi ${i}`}
                                        // style={(category?.image_url) ? { background: `url("${category.image_url}")` } : { background: `url("${defaultImageCar}")` }}
                                      >
                                        <Image
                                          src={category.image_url}
                                          alt=""
                                          className="h-100 w-100"
                                          parentClassName="image-inspeksi cursor-pointer"
                                          onClick={() => showModal(i)}
                                          key={i}
                                        />
                                      </>
                                    )
                                  );
                                }
                              )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} className="center-horizontal-vertical">
                    <a
                      className="btn-pink"
                      href={`/laporan-inspeksi/${unit.id}`}
                    >
                      {unit?.unit_type?.name === "Motor" ||
                      unit?.unit_type?.name === "Mobil"
                        ? "Lihat Laporan Lengkap Inspeksi Kendaraan"
                        : "Lihat Laporan Lengkap Inspeksi Barang"}
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              className="container section"
              id="produk-terkait"
              ref={produkterkaitRef}
            >
              <div className="produk-terkait">
                <CarouselMultipleLayout slideToShow={4}>
                  {loading
                    ? new Array(4).fill(1).map((el, indx) => (
                        <div
                          className="col-md-3"
                          style={{ marginRight: "5px" }}
                          key={indx}
                        >
                          <SkeletonUnitLelang />
                        </div>
                      ))
                    : unit.relateds
                    ? unit.relateds.map((item, i) => {
                        const year = item?.unit_informations?.find(
                          (unit_information) =>
                            unit_information.label === "Tahun"
                        );

                        const odometer = item?.unit_informations?.find(
                          (unit_information) =>
                            unit_information.label === "Odometer"
                        );

                        const transmisi = item?.unit_informations?.find(
                          (unit_information) =>
                            unit_information.label === "Transmisi"
                        );

                        const fuel = item?.unit_informations?.find(
                          (unit_information) =>
                            unit_information.label === "Bahan Bakar"
                        );

                        const validityPeriod = item?.unit_documents?.find(
                          (unit_document) => unit_document.label === "STNK"
                        );

                        const unitDetail = {
                          police_number: item?.police_number,
                          year: year?.value,
                          odometer: odometer?.value,
                          transmisi: transmisi?.value,
                          fuel: fuel?.value,
                          validityPeriod: validityPeriod?.additional_value,
                        };
                        return (
                          <div
                            className="col-md-3"
                            style={{ marginRight: "5px" }}
                            key={i}
                          >
                            <CardUnit
                              link={
                                item?.auction?.is_exclusive
                                  ? "https://" +
                                    item?.auction?.exclusive_seller_slug +
                                    "." +
                                    window.location.hostname +
                                    "/unit-lelang/" +
                                    item.id
                                  : "/unit-lelang/" + item.id
                              }
                              lot_number={
                                item?.lot_number &&
                                item?.auction?.auction_lane_name
                                  ? item?.auction?.auction_lane_name +
                                    item?.lot_number
                                  : "-"
                              }
                              price={item?.base_price}
                              tags={item?.tags || []}
                              nama={
                                item?.unit_name ||
                                item?.year +
                                  " " +
                                  item?.unit_maker?.name +
                                  " " +
                                  item?.unit_model?.name
                              }
                              location={item?.auction?.location_name}
                              auction={item?.auction}
                              pictures={item?.pictures}
                              grade={item?.unit_inspection?.grade}
                              onClick={() =>
                                item?.auction?.is_exclusive
                                  ? window.open(
                                      "https://" +
                                        item?.auction?.exclusive_seller_slug +
                                        "." +
                                        window.location.hostname +
                                        "/unit-lelang/" +
                                        item.id
                                    )
                                  : push("/unit-lelang/" + item.id)
                              }
                              unitType={item?.unit_type?.name}
                              unitDetail={unitDetail}
                            />
                          </div>
                        );
                      })
                    : ""}
                </CarouselMultipleLayout>
              </div>
            </div>
          </div>
          <ModalLayout show={modalState?.show} handleClose={closeModal}>
            <div className="text-center" style={{ height: "fit-content" }}>
              <img
                alt=""
                src={modalState?.data}
                className="disable-copy h-100"
                onError={imgError}
                loading="lazy"
                style={{ width: "100%" }}
              />
            </div>
          </ModalLayout>
        </>
      ) : (
        <PageNotFound />
      )}
    </>
  );
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {Redirect, Route, useLocation} from "react-router-dom";
import withAuth from "../../services/withAuthProvider";
import toast from "react-hot-toast";

const ProtectedRoute = ({ path, layout: Layout, page: Page, isLogged }) => {
  const userLoggedIn = useSelector((state) => state.authReducer.isLogged);
  const location = useLocation();
  const getProfile = async () => {
    const response = await withAuth.getDataProfile();
    if (response?.status !== 200) {
      toast.error(response?.data?.message, {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    if (userLoggedIn){
      getProfile()
    }
    scrollToTop();
  }, [path,location.pathname]);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  if (isLogged) {
    if (userLoggedIn) {
      return (
        <Route path={path}>
          <Layout>
            <Page />
          </Layout>
        </Route>
      );
    } else {
      return <Redirect to="/masuk" />;
    }
  } else {
    if (userLoggedIn) {
      return <Redirect to="/" />;
    } else {
      return (
        <Route path={path}>
          <Layout>
            <Page />
          </Layout>
        </Route>
      );
    }
  }
  // if (userLoggedIn) {
  //     return (
  //         <Redirect to="/" />
  //     )
  // } else {
  //     return (
  //         <Route path={path} >
  //             <Layout>
  //                 <Page />
  //             </Layout>
  //         </Route>
  //     )
  // }
};

export default ProtectedRoute;
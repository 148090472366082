import React from 'react'
import { Container, Row, Col, InputGroup, Form, Button } from 'react-bootstrap'

export default function Subscribe() {
    return (
        <>
            <section className='page subscribe' style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                <Container>
                    <Row>
                        <Col md={{ span: 6 }}>
                            <span className='text'>Dapatkan info terbaru dari Caready</span>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <InputGroup className="subscribe-input">
                                <Form.Control
                                    placeholder="Masukkan E-mail"
                                    aria-label="Masukkan E-mail"
                                    aria-describedby="basic-addon2"
                                />
                                <Button variant="primary" id="button-addon2">
                                    Subscribe
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

import { Form } from "react-bootstrap"

export const SelectInput = ({
    placeholder,
    name,
    options,
    onChange,
    optionLabel,
    optionValue,
    value,
    required
}) => {
    return (
        <Form.Select
            style={{ fontSize: "14px", lineHeight: "20px", color: "#092540", width: "174px" }}
            placeholder={placeholder}
            className="w-100"
            required={required}
            name={name}
            value={value}
            onChange={(event) => onChange(event.target.value)}
        >
            <option value="">Silahkan Pilih</option>
            {
                options.map((el, idx) => (
                    <option value={el[optionValue]} key={idx}>{el[optionLabel]}</option>
                ))
            }
        </Form.Select>
    )
}
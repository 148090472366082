import API from './axiosConfig';

import axios from 'axios'

// const API = axios.create({
//     // .. where we make our configurations
//     baseURL: process.env.REACT_APP_URL,
//     timeout: 10000,
//     headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//     }
// });

function buildResponse(response) {
    return response;
}

export default {
    postDataWithoutAuth: async (url, body) => {
        return API.post(`${url}`, body)
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
    getDataWithoutAuth: async (url, parameter) => {
        return API.get(`${url}`, {
            params: parameter
        })
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },

}

import { useState } from "react";

const Image = ({ className, src, alt, parentClassName, onClick }) => {
    // const defaultImage = 'https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true';
    const [data, setData] = useState(true)

    const imgError = () => {
        setData(false)
    }

    return (
        <>
            {
                data ? (
                    <div className={parentClassName} onClick={onClick}>
                        <img src={src} alt={alt} onError={imgError} className={className} />
                    </div>
                ) : ""
            }
        </>
    )
}

export default Image;
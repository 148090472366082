const PengaturanIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99992 11.6668V18.3335H3.33325C3.33325 16.5654 4.03563 14.8697 5.28587 13.6195C6.53612 12.3692 8.23181 11.6668 9.99992 11.6668ZM9.99992 10.8335C7.23742 10.8335 4.99992 8.596 4.99992 5.8335C4.99992 3.071 7.23742 0.833496 9.99992 0.833496C12.7624 0.833496 14.9999 3.071 14.9999 5.8335C14.9999 8.596 12.7624 10.8335 9.99992 10.8335ZM12.1624 15.6768C12.0568 15.2322 12.0568 14.769 12.1624 14.3243L11.3358 13.8468L12.1691 12.4035L12.9958 12.881C13.3276 12.5667 13.7286 12.3349 14.1666 12.2043V11.2502H15.8333V12.2043C16.2766 12.336 16.6766 12.571 17.0041 12.881L17.8308 12.4035L18.6641 13.8468L17.8374 14.3243C17.9429 14.7687 17.9429 15.2316 17.8374 15.676L18.6641 16.1535L17.8308 17.5968L17.0041 17.1193C16.6723 17.4336 16.2712 17.6654 15.8333 17.796V18.7502H14.1666V17.796C13.7286 17.6654 13.3276 17.4336 12.9958 17.1193L12.1691 17.5968L11.3358 16.1535L12.1624 15.6768ZM14.9999 14.1668C14.7789 14.1668 14.5669 14.2546 14.4107 14.4109C14.2544 14.5672 14.1666 14.7791 14.1666 15.0002C14.1666 15.2212 14.2544 15.4331 14.4107 15.5894C14.5669 15.7457 14.7789 15.8335 14.9999 15.8335C15.2209 15.8335 15.4329 15.7457 15.5892 15.5894C15.7455 15.4331 15.8333 15.2212 15.8333 15.0002C15.8333 14.7791 15.7455 14.5672 15.5892 14.4109C15.4329 14.2546 15.2209 14.1668 14.9999 14.1668Z" fill="#5C5C5C" />
        </svg>

    )
}

const TransaksiIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.50008 2.5H17.5001C17.7211 2.5 17.9331 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.9331 17.4122 17.7211 17.5 17.5001 17.5H2.50008C2.27907 17.5 2.06711 17.4122 1.91083 17.2559C1.75455 17.0996 1.66675 16.8877 1.66675 16.6667V3.33333C1.66675 3.11232 1.75455 2.90036 1.91083 2.74408C2.06711 2.5878 2.27907 2.5 2.50008 2.5ZM12.5001 5.83333V7.5H9.16675V9.16667H12.5001V10.8333L15.4167 8.33333L12.5001 5.83333ZM7.50008 14.1667V12.5H10.8334V10.8333H7.50008V9.16667L4.58341 11.6667L7.50008 14.1667Z" fill="#5C5C5C" />
        </svg>
    )
}
const NotifikasiIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 14.1665H18.3334V15.8332H1.66675V14.1665H3.33341V8.33317C3.33341 6.56506 4.03579 4.86937 5.28604 3.61913C6.53628 2.36888 8.23197 1.6665 10.0001 1.6665C11.7682 1.6665 13.4639 2.36888 14.7141 3.61913C15.9644 4.86937 16.6667 6.56506 16.6667 8.33317V14.1665ZM7.50008 17.4998H12.5001V19.1665H7.50008V17.4998Z" fill="#5C5C5C" />
        </svg>
    )
}
const FavoritIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0009 3.77415C11.9584 2.01665 14.9834 2.07498 16.8693 3.96415C18.7543 5.85415 18.8193 8.86415 17.0659 10.8275L9.99925 17.9042L2.93425 10.8275C1.18092 8.86415 1.24675 5.84915 3.13092 3.96415C5.01842 2.07748 8.03759 2.01415 10.0009 3.77415Z" fill="#5C5C5C" />
        </svg>
    )
}
const NIPLIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5001 2.5C17.7211 2.5 17.9331 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V7.91667C17.7809 7.91667 17.251 8.13616 16.8603 8.52686C16.4696 8.91756 16.2501 9.44747 16.2501 10C16.2501 10.5525 16.4696 11.0824 16.8603 11.4731C17.251 11.8638 17.7809 12.0833 18.3334 12.0833V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.9331 17.4122 17.7211 17.5 17.5001 17.5H2.50008C2.27907 17.5 2.06711 17.4122 1.91083 17.2559C1.75455 17.0996 1.66675 16.8877 1.66675 16.6667V12.0833C2.21928 12.0833 2.74919 11.8638 3.13989 11.4731C3.53059 11.0824 3.75008 10.5525 3.75008 10C3.75008 9.44747 3.53059 8.91756 3.13989 8.52686C2.74919 8.13616 2.21928 7.91667 1.66675 7.91667V3.33333C1.66675 3.11232 1.75455 2.90036 1.91083 2.74408C2.06711 2.5878 2.27907 2.5 2.50008 2.5H17.5001Z" fill="#5C5C5C" />
        </svg>
    )
}
const ProxyBidIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.08329 1.75L17.3325 2.92917L18.5108 11.1792L10.8508 18.8392C10.6945 18.9954 10.4826 19.0832 10.2616 19.0832C10.0407 19.0832 9.82873 18.9954 9.67246 18.8392L1.42246 10.5892C1.26623 10.4329 1.17847 10.221 1.17847 10C1.17847 9.77903 1.26623 9.56711 1.42246 9.41083L9.08329 1.75ZM11.44 8.82167C11.5948 8.97641 11.7785 9.09914 11.9807 9.18287C12.183 9.26659 12.3997 9.30967 12.6186 9.30963C12.8375 9.30959 13.0542 9.26644 13.2564 9.18264C13.4586 9.09885 13.6423 8.97605 13.797 8.82125C13.9518 8.66646 14.0745 8.4827 14.1582 8.28047C14.242 8.07824 14.285 7.8615 14.285 7.64262C14.285 7.42375 14.2418 7.20702 14.158 7.00482C14.0742 6.80262 13.9514 6.61891 13.7966 6.46417C13.6418 6.30943 13.4581 6.18669 13.2558 6.10297C13.0536 6.01924 12.8369 5.97617 12.618 5.97621C12.176 5.97629 11.7521 6.15196 11.4395 6.46458C11.127 6.77721 10.9515 7.20117 10.9516 7.64321C10.9517 8.08525 11.1273 8.50915 11.44 8.82167Z" fill="#5C5C5C" />
        </svg>
    )
}
const LogoutIcon = ({ className }) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16659 18.3332C3.94557 18.3332 3.73361 18.2454 3.57733 18.0891C3.42105 17.9328 3.33325 17.7209 3.33325 17.4998V2.49984C3.33325 2.27882 3.42105 2.06686 3.57733 1.91058C3.73361 1.7543 3.94557 1.6665 4.16659 1.6665H15.8333C16.0543 1.6665 16.2662 1.7543 16.4225 1.91058C16.5788 2.06686 16.6666 2.27882 16.6666 2.49984V17.4998C16.6666 17.7209 16.5788 17.9328 16.4225 18.0891C16.2662 18.2454 16.0543 18.3332 15.8333 18.3332H4.16659ZM12.4999 13.3332L16.6666 9.99984L12.4999 6.6665V9.1665H7.49992V10.8332H12.4999V13.3332Z" fill="#C20102" />
        </svg>
    )
}

export {
    PengaturanIcon,
    TransaksiIcon,
    NIPLIcon,
    NotifikasiIcon,
    FavoritIcon,
    ProxyBidIcon,
    LogoutIcon
}
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionsPage } from "../../redux/page.slice";

const HeaderLabel = ({ children, path }) => {
    const { push } = useHistory();
    const dispatch = useDispatch()
    const handleClick = () => {
        push(path)
        dispatch(actionsPage.setPage({ page: 0 }));
    }
    return (
        <div
            className='hover-primary cursor-pointer px-2 px-md-3 py-2'
            onClick={() => handleClick(path)}>
            {children}
        </div>
    )
}

export default HeaderLabel;
// import ArrowRightSearch from "../../assets/icons/ArrowRightSearch";
// import { AdOne, AdThree, AdTwo } from "../Cards/CardAd";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import careadySplash1 from "../../assets/images/careadyHome1.webp";
import aboutUs from "../../assets/images/obutUs.webp";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const lorem =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod eget et viverra habitant varius pretium. Vitae ut volutpat nulla eu, vulputate lacinia sed in vel.";

export default function TentangCaready({ content }) {
  const isLogged = useSelector((state) => state.authReducer.isLogged);
  const { push } = useHistory();
  const handleClick = (path) => {
    push(path);
  };
  return (
    <section
      className="about-caready pb-0"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div className="container mt-3">
        {content && (
          <div className="row about-content">
            {/* <div className="col-md-6 d-md-none d-lg-none">
                        <img src={careadySplash1} alt="" className="w-100 h-100" />
                    </div> */}
            <div className="col-md-6 mb-3">
              <img src={content.file_url} alt="" className="w-100 h-100" />
            </div>
            <div className="col-md-6">
              <div className="pe-5">
                <div className="fw-bold title about-title">{content.title}</div>
                <div className="py-3 desc">
                  {content.description.replace(/<[^>]*>/g, "")}
                </div>
                {!isLogged && (
                  <PrimaryButton
                    className="mb-3"
                    onClick={() => handleClick("/daftar")}
                  >
                    <div className="font-sm">Daftar Sekarang</div>
                  </PrimaryButton>
                )}
              </div>
            </div>
          </div>
        )}
        {/* <div className="row about-content">
                    <div className="col-md-6">
                        <img src={careadySplash2} alt="" className="w-100 h-100" />
                    </div>
                    <div className="col-md-6">
                        <div className="pe-5">
                            <div className="fw-bold fs-3 about-title">Perjalanan Caready</div>
                            <div className="font-sm py-3">{lorem}</div>
                            <button type="button" className='btn btn-outline-primary font-sm' >
                                <div className="d-flex align-items-center">
                                    <div className="me-2">Lihat selengkapnya</div>
                                    <ArrowRightSearch />
                                </div>
                            </button>
                        </div>
                    </div>
                </div> */}

        {/* <div className="row d-flex justify-content-around mb-5">
                    <Col className="col-md-3 col-6">
                        <AdOne />
                    </Col>
                    <Col className="col-md-3 col-6">
                        <AdTwo />
                    </Col>
                    <Col className="col-md-3 col-6">
                        <AdThree />
                    </Col>
                    <Col className="col-md-3 col-6">
                        <AdOne />
                    </Col>
                </div> */}
      </div>
    </section>
  );
}

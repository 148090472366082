import React from 'react'
import { Form } from 'react-bootstrap';
import { format } from "date-fns";
import { id } from 'date-fns/esm/locale';

const FilterJadwalLelangQuartal = (props, key) => {
    const today = format(new Date(), 'eeee, dd MMM yyyy', { locale: id });
    return (
        <div className="filterJadwalLelang">

            <div className='align-items-center justify-content-between flex-column flex-md-row w-100 gap-2' style={{
                display: 'flex',
                padding: "20px",
                background: "#FFFFFF",
                boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                borderRadius: "12px",
                marginTop: "26px",
                marginBottom: "12px",
                border: "1px solid #F5F5F5"
            }}>

                <div style={{ display: "table", flex: "1", fontSize: "18px", lineHeight: "28px", fontWeight: "bold" }}>
                    <span style={{
                        display: "table - cell", verticalAlign: "middle"
                    }}>Jadwal Lelang Quartal</span>
                </div>

                <div className='d-flex w-100-md-auto flex-md-row flex-column'>
                    <div className='jadwal-lelang-option'>
                        <Form.Select
                            style={{ fontSize: "14px", lineHeight: "20px", color: "#092540" }}
                            placeholder="Tahun"
                            name="year"
                            onChange={props.handleChange}
                            value={props?.data?.year}
                            required
                        >
                            {Array(3)
                                .fill(null)
                                .map((_, index) => {
                                    return (
                                        <option key={new Date().getFullYear() + index} value={new Date().getFullYear() + index}>
                                            {new Date().getFullYear() + index}
                                        </option>
                                    );
                                })}

                        </Form.Select>
                    </div>
                    <div className='jadwal-lelang-option'>
                        <Form.Select
                            style={{ fontSize: "14px", lineHeight: "20px", color: "#092540" }}
                            placeholder="Quartal"
                            name="quartal"
                            onChange={props.handleChange}
                            value={props?.data?.quartal}
                            required
                        >
                            {
                                props.quartal.map((el, idx) => <option key="idx" value={el.id} >{el.shortlabel}</option>)
                            }
                        </Form.Select>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default FilterJadwalLelangQuartal;

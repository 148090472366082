import { useEffect } from "react";
import toast from 'react-hot-toast';
import withAuth from "../../services/withAuthProvider";
import { userSelector } from "../../redux/auth.slice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import withoutAuthProvider from "../../services/withoutAuthProvider";

const VerifikasiAkun = () => {
    const user = useSelector(userSelector);
    const { push, location } = useHistory();
    const dataPrev = location?.state?.dataParams;
    const isLogged = useSelector((state) => state.authReducer.isLogged);

    const handleClick = async () => {
        const loading = toast.loading('Mengirimkan...');

        const response = await withoutAuthProvider.postDataWithoutAuth('/api/reverify_email', { email: dataPrev?.email })
        if (response?.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
        } else {
            toast.dismiss(loading);
            if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {
                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key]
                    }
                }
                toast.error(data_message, {
                    id: 'error-message',
                    duration: 3000
                });
            } else {
                toast.error(response.data.message, {
                    id: 'error-message',
                    duration: 3000
                });
            }
        }
    }

    useEffect(() => {
        if (isLogged) {
            if (user.email_verified_at !== null) {
                push("/")
            }
        }

    }, []);

    return (
        <div className='w-100'>
            <p className="fw-bold fs-4 mb-2">Verifikasi Akun Anda</p>
            {/* <div className="d-inline-block my-3 " style={{ width: '160px', height: '160px', backgroundColor: '#F0F2F5' }}>

            </div> */}
            <div className='font-sm d-flex mb-3'>
                <div className='me-2 text-secondary'>
                    Link Aktivasi akun Anda sudah terkirim ke alamat email,
                    <br />
                    silahkan cek kotak masuk Anda</div>
            </div>
            <div className='font-sm d-flex mb-3'>
                <div className='me-2 text-secondary'>Tidak dapat email masuk?</div>
                <div className='text-primary cursor-pointer' onClick={handleClick}>Kirim ulang</div>
            </div>
        </div >
    )
}

export default VerifikasiAkun;